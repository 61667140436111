import * as THREE from "three";

import * as chip from "booyah/dist/chip";

export class AudioPlayer extends chip.ChipBase {
  constructor(private _audio: THREE.PositionalAudio) {
    super();
  }

  protected _onActivate(): void {
    this._audio.play();
    this.terminate();
  }
}
