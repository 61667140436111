#include <packing>

precision highp float;
precision highp sampler2D;

varying vec2 vUv;
uniform sampler2D tDiffuse;
uniform sampler2D tDepth;
uniform sampler2D tDiffuseWater;
uniform sampler2D tDepthWater;
uniform float cameraNear;
uniform float cameraFar;
uniform float uTime;
uniform float speedBoostFraction;

#define PI 3.1415926535897932384626433832795
#define SHARP_LINES


float random (vec2 st) {
    return fract(sin(dot(st.xy,vec2(12.9898,78.233)))*43758.5453123);
}

float getSpeedLine(vec2 uv, float time, float nbSegments)
{
    float angle = asin(normalize(uv).y) + PI*0.5; 
    float isegments = 1.f/nbSegments;
    float angleMod = angle - mod(angle, PI*isegments);
    float angleDist = abs(angle - angleMod);
    angleDist = abs(angleDist - (isegments*1.5));
    
    float randSize = random(vec2(angleMod, angleMod)) + time;
    
    // changing the modul can make a very cool deceleration effect
    //randSize = mod(randSize, 1.0 + 0.125*nbSegments/75.0);
    randSize = mod(randSize, 1.0);
    
#ifdef SHARP_LINES
    float size = randSize + angleDist*10.0;
#else
    float size = randSize;
#endif

    float dist = length(uv) * pow(75.0/nbSegments, 0.5);
    
    float line = 0.0;
    
    if(size <= dist)
    {
        line = 1.0 - abs(angleDist)*nbSegments*0.5;
        
        //if(line <= 0.8)
        //    line = 0.0;
        
        line = line*size*2.0;
    }
    
    return line;
}

float doSpeedBoostEffect(vec2 uv, float nbSegments, float time, float tunnelFactor)
{
    const float effectOpacity = 0.75;

    vec2 effectCenter = vec2(0.5, 0.75);
    /*
        uv transformation
    */
    vec2 uvT = uv - effectCenter;
    uvT *= 0.85;
    
    float tunnelExponent = 2.0*tunnelFactor;
    //tunnelExponent -= 0.1*nbSegments/75.0;
    
    float l = pow(length(uvT), tunnelExponent);
    float angle = asin(normalize(uvT).y)*sign(uvT.x) + PI*0.5; 
    //l = pow(l, 1.8);
    // l = log(1.0-l);
    vec2 uvTunnel = vec2(sin(angle), cos(angle)) * l;
    
    float speedLine = getSpeedLine(uvTunnel, time, nbSegments)*effectOpacity;

    return speedLine;
    
}


float readDepth( sampler2D depthSampler, vec2 coord ) {
  float fragCoordZ = texture2D( depthSampler, coord ).x;
  float viewZ = perspectiveDepthToViewZ( fragCoordZ, cameraNear, cameraFar );
  return viewZToOrthographicDepth( viewZ, cameraNear, cameraFar );
}

/*
    Tells if the fragment at the given coordinates is on the sky or not
*/
const float SKYDEPTH_LIMIT = 0.999; //0.999
bool inSky(vec2 uv){
    float mainDepth = readDepth( tDepth, uv );
    float waterDepth = readDepth( tDepthWater, uv );
    float depth = min(mainDepth, waterDepth);

    if(waterDepth > SKYDEPTH_LIMIT)
        return true;

    return false;
}

float getFoam(float depth, float time)
{
    float foam = mod(depth - time , 1.0);
    
    foam = step(foam + depth, 1.5 - pow(depth, 0.5));

    return foam;
}

void main() {
    vec4 mainPixel = texture2D(tDiffuse, vUv);
    vec4 waterPixel = texture2D(tDiffuseWater, vUv);

    float mainDepth = readDepth( tDepth, vUv );
    float waterDepth = readDepth( tDepthWater, vUv );

    /*
        Create the post process foam effect
    */
    if(mainDepth > waterDepth){
        float foam = mainDepth - waterDepth;

        foam *= 1000.0;

        if(getFoam(foam, uTime*0.5) > 0.0)
            waterPixel.rgb = vec3(195.0, 191.0, 175.0)/256.0;

        // if(foam > 0.0)
        // {
        //     waterPixel.rgb += vec3(getFoam(foam, uTime*0.5));
        //     //waterPixel.rgb += vec3(foam*0.75);
        // }
    }

    /*
        Compare depth & apply water transparency
    */
    if(mainDepth <= waterDepth) {
        gl_FragColor = mainPixel;
    } else {
        // Simulate transparency by mixing the water in with the main pixel color, 
        // based on the distance of the water from the camera
        float waterOpacity = mix(0.8, 1.0, clamp(waterDepth / 0.05, 0.0, 1.0));
        gl_FragColor.rgb = mix(mainPixel.rgb, waterPixel.rgb, waterOpacity);
        gl_FragColor.a = 1.0;
    }

    /*
        Three JS gamma correction
    */
    gl_FragColor = linearToOutputTexel( gl_FragColor );

    /*
        Create the water outline effect
    */
    if(inSky(vUv)){
        float borderThickness = 0.0075;
        int border = 0;
        for(int i = 0; i < 15; i++)
        {
            vec2 randomShift = vec2(
                random(vUv*float(i+1)) - 0.5,
                random(vUv.yx*float(i+1)/-2.0 +  50.0) - 0.5
            );

            if(!inSky(vUv + randomShift*borderThickness))
                border ++;
        }

        if(mainDepth > SKYDEPTH_LIMIT)
            gl_FragColor.rgb = mix(gl_FragColor.rgb, vec3(1.0, 1.25, 1.5), float(border)/5.0);
            
            // gl_FragColor.rgb += vec3(0.25)*float(border);
        
    }

    if(speedBoostFraction > 0.0)
    {
        const float largeLinesSpeed  = 0.65;
        const float mediumLinesSpeed = 1.0;
        const float smallLinesSpeed  = 1.2;
        
        const float largeLinesSize  = 75.0;
        const float mediumLinesSize = 253.0;
        const float smallLinesSize  = 396.0;
        
        const float largeLinesOpacity  = 0.65;
        const float mediumLinesOpacity = 1.0;
        const float smallLinesOpacity  = 3.0;

        float speedModif = clamp(pow(speedBoostFraction + 0.25, 0.5), 0.0, 1.0);
        float size = 6.0;
        float tunnelFactor = 1.0 + (1.0 - speedModif)*size;
        
        float effect = 0.f;
        effect += largeLinesOpacity * 
                doSpeedBoostEffect(vUv, 
                                    largeLinesSize, 
                                    uTime*largeLinesSpeed,
                                    tunnelFactor);
        
        
        effect += mediumLinesOpacity * 
                doSpeedBoostEffect(vUv, 
                                    mediumLinesSize, 
                                    uTime*mediumLinesSpeed,
                                    tunnelFactor);

        effect += smallLinesOpacity * 
                doSpeedBoostEffect(vUv, 
                                    smallLinesSize, 
                                    uTime*smallLinesSpeed,
                                    tunnelFactor);
        
        effect = clamp(effect, 0.0, 0.65);

        gl_FragColor.rgb += vec3(effect);
    }
}
