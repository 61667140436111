import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

import * as tLoaders from "../../loaders/textureLoaders";

import * as responsive from "../../responsive";
import * as flag from "../components/flag";

export type ChoiceMenuTabName = (typeof shopTabNames)[number];

export const shopTabNames = ["boats", "nets", "items", "start"] as const;
export type ShopTabName = (typeof shopTabNames)[number];

/**
 * Represent the headers of the choice menu (the tab bar).
 * Emits: selected
 */
export class ShopTabHeader extends responsive.ResponsiveChip {
  private _container!: PIXI.Container;

  private _selectedStates!: chip.StateMachine;

  private _index: number;

  constructor(
    public readonly name: ChoiceMenuTabName,
    private _selectedByDefault: boolean,
    private _asNewItem: boolean
  ) {
    super();

    this._index = shopTabNames.indexOf(name);
  }

  protected _onActivate(): void {
    this._container = new PIXI.Container();
    this._container.eventMode = "dynamic";

    this._subscribe(this._container, "pointertap", () => {
      this.emit("selected");
      this._selectedStates.changeState("selected");
    });

    this._selectedStates = new chip.StateMachine(
      {
        selected: this._selectedState.bind(this),
        unselected: this._unselectedState.bind(this),
      },
      {
        startingState: this._selectedByDefault ? "selected" : "unselected",
      }
    );

    this._activateChildChip(this._selectedStates);

    this.chipContext.container.addChild(this._container);
  }

  protected _onTerminate(): void {
    this.chipContext.container.removeChild(this._container);
  }

  private _makeState(selected: boolean) {
    const container = new PIXI.Container();

    return new chip.Functional({
      activate: (it) => {
        this._container.zIndex = selected ? 10 : this._index === 1 ? 5 : 0;

        const background = new PIXI.Sprite(
          tLoaders.UITextureAssets.getTexture(
            selected ? "Tab_Background_Selected" : "Tab_Background_Unselected"
          )
        );
        // HACK to fit in an extra tab without changing the sprite.
        // A better solution would be to use a 9-slice for the tab
        background.scale.x = 0.75;

        container.position.set(this._index * background.width, -50);

        const text = new PIXI.Text(
          this.name[0].toUpperCase() + this.name.slice(1),
          {
            fontFamily: "Hvd Comic Serif Pro",
            fontSize: 40,
            fill: 0x000000,
          }
        );

        text.anchor.set(0, 0.5);
        text.position.set(20, background.height / 2);
        text.alpha = selected ? 1 : 0.7;

        container.addChild(background, text);

        if (this._asNewItem) {
          const newTag = new flag.Flag("new", "Flag_Red");

          newTag.position.set(
            text.width + 30,
            background.height / 2 - newTag.height / 2
          );

          container.addChild(newTag);
        }

        this._container.addChild(container);
      },
      terminate: () => {
        this._container.removeChild(container);
        container.destroy();
      },
    });
  }

  private _selectedState() {
    return this._makeState(true);
  }

  private _unselectedState() {
    return this._makeState(false);
  }

  public select() {
    this._selectedStates.changeState("selected");
  }

  public unselect() {
    this._selectedStates.changeState("unselected");
  }
}
