/*
    The following elements are imported here automatically from shader/waves.glsl : 

        #define DOWAVES; 
        uniform float uTime;

        float getOceanHeight(vec3 wpos); 
        float getWave(vec3 wpos, float x_Frequency, float z_frequency, float speed, float offset);

    The following elements are imported here automaticly from THREE JS shader system :

        vec3 position := (read only) posiiton of the vertex in the model
        mat4 modelMatrix := world transformation matrix of the model
    
    The following elements are importe here automaticly from the Custom Shader Material add-on :

        csm_Position := outpout the final position of the vertex in the model
*/

uniform vec2 boatPos;
uniform float netSize;

void main(){
    vec3 vertexPositon = position;

#ifdef DOWAVES

    /*
        Get the angle from the model origin
    */
    vec2 center = vec2(0.0, 0.0);
    vec2 cPos = normalize(vertexPositon.xz - center);
    float angle = acos(cPos.x)*sign(cPos.y);
    
    /*
        Get the Hexagonal angle 
    */
    angle -= mod(angle, PI/3.0);

    /*
        Slice the net in serval part & move them away from
        the center the bigger the net is
    */
    vec2 displacement = vec2(cos(angle), sin(angle));
    vertexPositon.xz += displacement*(netSize-5.0);

    /*
        Apply the waves height to the individual vertex,
        making the model floating almoast perfectly on them
    */
    vertexPositon.y += getOceanHeight((modelMatrix * vec4(vertexPositon, 1.0)).xyz);
#endif

    csm_Position = vertexPositon;
}
