import * as UI from "@pixi/ui";
import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

import * as tLoaders from "../../loaders/textureLoaders";

import * as responsive from "../../responsive";
import * as utils from "../../utils";

type GaugeColor = "white" | "red" | "beige" | "grey" | "yellow";

export class Gauge extends responsive.ResponsiveChip {
  private _container!: PIXI.Container;
  private _bg!: PIXI.Sprite;
  private _fill!: PIXI.Sprite;
  private _bar!: UI.ProgressBar;
  private _value!: number;
  private _blinkSequence!: chip.Sequence;
  private _visible = true;

  get bar(): UI.ProgressBar {
    return this._bar;
  }

  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = value;
    this._bar.progress = value * 100;
  }

  get visible() {
    return this._visible;
  }
  set visible(value: boolean) {
    this._visible = value;
    if (this._container) this._container.visible = this._visible;
  }

  constructor(
    private _width: number,
    private _backgroundColor: GaugeColor = "white",
    private _color: GaugeColor = "red",
    private _baseValue = 1,
    private _parts = 1
  ) {
    super();

    // this._baseScreenWidth = responsive.getScreenWidth()
  }

  protected _onActivate(): void {
    this._container = new PIXI.Container();
    this._container.visible = this._visible;

    this._bg = this._generateSprite(this._backgroundColor, this._parts);
    this._fill = this._generateSprite(this._color, this._parts);

    this._bar = new UI.ProgressBar({
      bg: this._bg,
      fill: this._fill,
    });

    this._container.addChild(this._bar);

    this.chipContext.container.addChild(this._container);

    this.value = this._baseValue;

    // Prepare blink sequence
    const blinks = 3;
    const sequenceArray = [];
    for (let i = 0; i < blinks; i++) {
      sequenceArray.push(
        new chip.Lambda(() => {
          this._bg.tint = 0xff0000;
        }),
        new chip.Wait(250),
        new chip.Lambda(() => {
          this._bg.tint = 0xffffff;
        }),
        new chip.Wait(250)
      );
    }
    this._blinkSequence = new chip.Sequence(sequenceArray);
  }

  protected _generateSprite(color: GaugeColor, parts = 1) {
    const partWidth = this._width / parts;
    const partsContainer = new PIXI.Container();

    for (let i = 0; i < parts; i++) {
      const part = tLoaders.UINineSlicePlaneAssets.getNineSlice(
        `Loader_Scroll_${utils.capitalize(color)}`
      );
      part.width = partWidth;
      part.height = 15;
      part.position.x = i * partWidth;
      partsContainer.addChild(part);
    }
    const texture = this._chipContext.pRenderer.generateTexture(partsContainer);
    partsContainer.destroy(true);
    return new PIXI.Sprite(texture);
  }

  public blink() {
    if (this._blinkSequence.state !== "inactive")
      this._blinkSequence.terminate();

    this._activateChildChip(this._blinkSequence);
  }

  // protected _onResize(width: number): void {
  //   this._ui.width = this._width * (width / this._baseScreenWidth)
  // }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container);
  }
}
