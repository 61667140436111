import * as PIXI from "pixi.js";

import * as tLoaders from "../../loaders/textureLoaders";

import * as constants from "../../constants";
import * as responsive from "../../responsive";
import * as utils from "../../utils";

export class MoneyView extends responsive.ResponsiveChip {
  private _rightContainer!: PIXI.Container;
  private _moneyText!: PIXI.Text;

  protected _onActivate() {
    this._rightContainer = new PIXI.Container();

    // Money
    {
      this._moneyText = new PIXI.Text("0", {
        fontFamily: "Hvd Comic Serif Pro",
        fontSize: 45,
        fill: constants.moneyColor,
      });

      this._moneyText.anchor.set(1, 0.5);
      this._moneyText.position.set(-150, 30);

      this._rightContainer.addChild(this._moneyText);
    }

    // Money icon
    {
      const moneyIcon = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture("Money")
      );

      moneyIcon.anchor.set(1, 0.5);
      moneyIcon.position.set(-90, 30);

      this._rightContainer.addChild(moneyIcon);
    }

    this._subscribe(this.chipContext.saveManager, "change:money", () => {
      this._onUpdateMoney();
    });

    this._onUpdateMoney();

    this.chipContext.container.addChild(this._rightContainer);
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._rightContainer);
  }

  protected _onResize(width: number) {
    this._rightContainer.position.set(width, 0);

    if (responsive.isMobile()) {
      this._rightContainer.scale.set(0.5);
    } else if (responsive.isTablet()) {
      this._rightContainer.scale.set(0.75);
    } else {
      this._rightContainer.scale.set(1);
    }
  }

  private _onUpdateMoney() {
    this._activateChildChip(
      utils.textEditAnimation(
        this._moneyText,
        this.chipContext.saveManager.money.toLocaleString()
      )
    );
  }
}
