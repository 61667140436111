import TileDataJson from "./tileset.json";

import random from "random";
import seedRandom from "seedrandom";
// import * as noise from "simplex-noise";
import * as _ from "underscore";

import * as PIXI from "pixi.js";
import * as THREE from "three";

import * as chip from "booyah/dist/chip";

import * as mLoaders from "./loaders/modelLoaders";
import * as tLoaders from "./loaders/textureLoaders";

import * as collectable from "./collectable";
import * as constants from "./constants";
import * as context from "./context";
import * as obstable from "./obstacle";
import * as obstaclePrefabs from "./obstaclePrefabs";
import * as params from "./params";
import * as threeObject from "./threeObject";
import * as utils from "./utils";

// TODO: this should be put in the sea map, and called from the context
// let simplex: noise.NoiseFunction2D;

type Rarity = "Common" | "Rare" | "Very_Rare" | "Unique";

const pickedTileRarities = ["Common", "Rare", "Very_Rare"];

const baseCellRotation = Math.PI / 6;

/**
 * Hexagons are called "tiles" when written in the JSON,
 * but called "cells" when loaded into the map.
 *
 * The definitions are very similar, but the `Cell` versions are processed
 * and use non-JSON data structures.
 */

/** These definitions match the `tilset.json` file */
interface TileObstacle {
  type: obstaclePrefabs.ObstacleName;
  x: number;
  y: number;
  rotation: number;
  dynamic?: boolean;
  patrol_points?: { x: number; y: number }[];
}

interface TileCollectable {
  type: collectable.CollectableName;
  x: number;
  y: number;
}

interface Tile {
  name: string;
  obstacles: TileObstacle[];
  collectables: TileCollectable[];
  rarity: Rarity;
}

interface Tileset {
  tiles: Tile[];
}

/** These definitions are used in the program itself */
class ObstacleDef {
  public name!: obstaclePrefabs.ObstacleName;
  public positionX = 0;
  public positionY = 0;
  public rotation = 0;
  public dynamic = false;
  public patrolPoints: THREE.Vector2[] = [];
}

class CollectableDef {
  public name!: collectable.CollectableName | "RANDOM_SLOT";
  public positionX = 0;
  public positionY = 0;
}

export class CellPreset {
  public rarity!: Rarity;
  public obstacles: ObstacleDef[] = [];
  public collectables: CollectableDef[] = [];
}

export type TrashCount = Partial<Record<collectable.TrashName, number>>;

export type AnimalCountFractions = Record<number, number>;

type CellOptionsGrid = Array<CellOptions | null>[];

export const cellPresets: Record<string, CellPreset> = {};

let allTiles: Tileset;
if (params.shouldLoadTilesFromCache()) {
  const data = window.localStorage.getItem("presets")!;
  allTiles = JSON.parse(data) as Tileset;
  console.log("Loaded tiles from cache");
} else {
  allTiles = TileDataJson as Tileset;
}

export function loadTilesPresets() {
  allTiles.tiles.forEach((tile) => {
    const newPreset = new CellPreset();
    newPreset.rarity = tile.rarity;

    tile.obstacles.forEach((obstacle) => {
      const newInfo = new ObstacleDef();
      newInfo.name = obstacle.type;

      newInfo.positionX = obstacle.x;
      newInfo.positionY = obstacle.y;

      newInfo.rotation = obstacle.rotation;

      if (obstacle.dynamic != undefined) {
        newInfo.dynamic = obstacle.dynamic;
      }

      if (obstacle.patrol_points != undefined) {
        obstacle.patrol_points.forEach((point) => {
          newInfo.patrolPoints.push(new THREE.Vector2(point.x, point.y));
        });
      }

      newPreset.obstacles.push(newInfo);
    });

    tile.collectables.forEach((item) => {
      const newInfo = new CollectableDef();
      newInfo.name = item.type;
      newInfo.positionX = item.x;
      newInfo.positionY = item.y;

      newPreset.collectables.push(newInfo);
    });

    if (tile.name in cellPresets)
      throw new Error(`Duplicate tile name in set: ${tile.name}`);

    cellPresets[tile.name] = newPreset;
  });
}

class CellOptions {
  x!: number;
  y!: number;
  id?: string;
  filterCollectables = false; // If true, don't filter out collectables
  isDropZone = false;
  turns = 0; // Number of turns (PI/3) to rotate the tile
  bonusesToInclude: collectable.BonusName[] = [];
  trashCount = 0;
  minTrashCount: TrashCount = {};
  maxAnimals = -1; //  Number of animals to include. Negative numbers have all animals
  maxBonuses = -1; // Number of bonuses to include. Negative numbers have all bonuses
  includeCoins = false;
  includeKey = false;
  includeUnlock?: collectable.UnlockName;
}

export class Cell extends context.ContextualChip {
  private _options: CellOptions;

  private _position!: THREE.Vector2;
  private _rotation!: number; // in radians

  private _boatInZone = false;

  private _obstacles!: obstable.Obstacle[];
  private _collectibles!: collectable.Collectable[];

  constructor(options: Partial<CellOptions>) {
    super();

    this._options = chip.fillInOptions(options, new CellOptions());

    this._position = utils.hexToWorld(this._options.x, this._options.y);
  }

  protected _onActivate(): void {
    // const zoom = 0.3;
    // const noise = simplex(this._options.x * zoom, this._options.y * zoom);

    this._obstacles = [];
    this._collectibles = [];

    this._rotation = baseCellRotation + this._options.turns * (Math.PI / 3);

    // If a tile is specified, load it. Otherwise just empty sea (before trash)
    if (this._options.id) {
      this._loadObstacles().forEach((obstacle) =>
        this._activateChildChip(obstacle, {
          attribute: "_obstacles[]",
        })
      );

      this._loadCollectables().forEach((collectable) =>
        this._activateChildChip(collectable, {
          attribute: "_collectables[]",
        })
      );

      if (this._options.isDropZone) {
        this._generateDropZoneBuoys().map((buoy) =>
          this._activateChildChip(buoy)
        );
      }
    }

    this.generateTrash();

    /*
        (Un)comment to enable/disable map helper
    */
    // this._spawnCellNoiseHelper(0, 0);
  }

  protected _onTick() {
    if (!this._options.isDropZone) return;

    // Watch the distance between boat and this cell, when the distance is less than 1000, trigger boat.net.onDropZoneReached()
    // OPT: could we a sensor in the collision detection here?
    const dist = this.getSquaredDistFrom(
      new THREE.Vector2(
        this.chipContext.playerInfo.positionX,
        this.chipContext.playerInfo.positionY
      )
    );

    // si le bateau rentre dans la zone
    if (
      dist < constants.dropZoneScheduleMin * constants.dropZoneScheduleMin &&
      !this._boatInZone
    ) {
      this._boatInZone = true;
      this.chipContext.level.boat?.onDropZoneReached(this._options.id);
    }

    // si le bateau sort de la zone
    if (
      dist > constants.dropZoneScheduleMax * constants.dropZoneScheduleMax &&
      this._boatInZone
    ) {
      this._boatInZone = false;
    }
  }

  private _loadObstacles() {
    let obstaclesDefs = cellPresets[this._options.id!]!.obstacles;
    if (!obstaclesDefs) return [];

    // Optionally, filter out animals
    if (this._options.maxAnimals >= 0) {
      // Separate out animals from the others
      // eslint-disable-next-line prefer-const
      let [animalObstacleDefs, otherCollectableDefs] = _.partition(
        obstaclesDefs,
        (obstacleDef) => obstacleDef.dynamic && obstacleDef.name !== "Bassan"
      );

      // Only take some
      animalObstacleDefs = _.take(
        _.shuffle(animalObstacleDefs),
        this._options.maxAnimals
      );

      // Mix them back together
      obstaclesDefs = [...animalObstacleDefs, ...otherCollectableDefs];
    }

    // Transform into chips
    return obstaclesDefs.map((element) => {
      const translatedPosition = new THREE.Vector2(
        element.positionX,
        element.positionY
      ).add(this._position);
      const rotatedPosition = this._rotatePosition(translatedPosition);
      const rotatedAngle = this._rotateAngle(element.rotation);

      const rotatedPatrolPoints = element.patrolPoints.map((point) => {
        const translatedPoint = point.clone().add(this._position);
        return this._rotatePosition(translatedPoint);
      });

      const obstacle = new obstable.Obstacle(
        element.name,
        rotatedPosition,
        rotatedAngle,
        rotatedPatrolPoints
      );

      return obstacle;
    });
  }

  private _loadCollectables() {
    let collectableDefs = cellPresets[this._options.id!]!.collectables;
    if (!collectableDefs) return [];

    // Seperate bonuses and random slots from the other collectables
    const groups = _.groupBy(collectableDefs, (collectableDef) => {
      if (collectableDef.name === "RANDOM_SLOT") return "randomSlotDefs";
      if (collectableDef.name === "BONUS_COIN") return "coinDefs";
      if (collectableDef.name.startsWith("BONUS_")) return "bonusDefs";
      return "otherDefs";
    });
    const randomSlotDefs = groups.randomSlotDefs || [];
    let coinDefs = groups.coinDefs || [];
    let bonusDefs = groups.bonusDefs || [];
    const otherDefs = groups.otherDefs || [];

    // Optionally remove coins
    if (!this._options.includeCoins) {
      coinDefs = [];
    }

    if (this._options.filterCollectables) {
      // Only include bonuses that the player has unlocked
      bonusDefs = bonusDefs.filter((element) =>
        this._options.bonusesToInclude.includes(
          element.name as collectable.BonusName
        )
      );
    }

    // Deal with random slots
    let createdKey = false;
    let createdUnlock = false;

    // Instantiate random slots, putting them in either bonusDefs or otherDefs
    randomSlotDefs.forEach((collectableDef) => {
      if (this._options.includeUnlock && !createdUnlock) {
        // Create the unlock
        const unlockDef = Object.assign({}, collectableDef, {
          name: this._options.includeUnlock,
        });

        // collectableDef.name = this._options.includeUnlock;
        createdUnlock = true;
        otherDefs.push(unlockDef);

        console.log("Created unlock");
      } else if (this._options.includeKey && !createdKey) {
        // Create the key
        const keyDef = Object.assign({}, collectableDef, {
          name: "UNLOCK_KEY",
        });

        // collectableDef.name = "UNLOCK_KEY";
        createdKey = true;
        otherDefs.push(keyDef);

        console.log("Created key");
      } else if (this._options.bonusesToInclude.length > 0) {
        // Create a bonus
        // Get a weighted random int to use as the array index
        const weightedIndex = utils.weightedRandomInt(
          this._options.bonusesToInclude.length,
          1.5,
          true,
          true
        );
        const bonusDef = Object.assign({}, collectableDef, {
          name: this._options.bonusesToInclude[weightedIndex],
        });

        // collectableDef.name = this._options.bonusesToInclude[weightedIndex];
        bonusDefs.push(bonusDef);
      }
    });

    // Only take the maxiumum number of bonuses allowed
    if (this._options.filterCollectables && this._options.maxBonuses > -1) {
      bonusDefs = _.take(_.shuffle(bonusDefs), this._options.maxBonuses);
    }

    // Finally combine them back together (ignoring randomDefs)
    collectableDefs = [...coinDefs, ...bonusDefs, ...otherDefs];

    return collectableDefs.map((collectableDef) => {
      const translatedPosition = new THREE.Vector2(
        collectableDef.positionX,
        collectableDef.positionY
      ).add(this._position);
      const rotatedPosition = this._rotatePosition(translatedPosition);

      return new collectable.Collectable(
        collectableDef.name as collectable.CollectableName,
        rotatedPosition
      );
    });
  }

  // private _spawnCellNoiseHelper(x: number, y: number) {
  //   /*
  //       This function spawn an helper near the given origin.
  //       The helper is
  //           blue if the cell is empty
  //           yellow if the cell isn't empy
  //           unique/special cells are red
  //   */
  //   const squareSize = 5;
  //   const geo = new THREE.PlaneGeometry(squareSize, squareSize);
  //   const color = new THREE.Color();
  //   if (this._containsUnique) color.setRGB(0.75, 0.2, 0.2);
  //   else if (this._empty) color.setRGB(0, 0.5, 0.75);
  //   else color.setRGB(0.5, 0.5, 0.25);

  //   const mesh = new THREE.Mesh(
  //     geo,
  //     new THREE.MeshBasicMaterial({ color: color })
  //   );
  //   mesh.lookAt(0, 1, 0);
  //   mesh.position.x = x + this._position.x * 0.03;
  //   mesh.position.y = 5.0;
  //   mesh.position.z = y + this._position.y * 0.035;
  //   this.chipContext.scene.add(mesh);
  // }

  public getSquaredDistFrom(pos: THREE.Vector2) {
    const x = pos.x - this._position.x;
    const y = pos.y - this._position.y;
    return x * x + y * y;
  }

  public deactivatePlanckOjects() {
    for (const child of Object.values(this._childChips)) {
      if (
        child instanceof collectable.Collectable ||
        (child instanceof obstable.Obstacle && !child.isDynamic)
      ) {
        child.deactivatePlanckObject();
      }
    }
  }

  public activatePlanckOjects() {
    for (const child of Object.values(this._childChips)) {
      if (
        child instanceof collectable.Collectable ||
        (child instanceof obstable.Obstacle && !child.isDynamic)
      ) {
        child.activatePlanckObject();
      }
    }
  }

  public addCollectable(element: collectable.Collectable) {
    this._activateChildChip(element, {
      attribute: "_collectables[]",
    });
  }

  public addAllToScene() {
    Object.values(this._childChips).forEach((child) => {
      if (
        !(child instanceof obstable.Obstacle) &&
        !(child instanceof collectable.Collectable)
      )
        return;

      child.getModel?.updateMatrix();
      child.getModel?.updateMatrixWorld();
      child.getThreeObject()?.addToScene();
    });
  }

  public removeAllFromScene() {
    Object.values(this._childChips).forEach((child) => {
      if (
        !(child instanceof obstable.Obstacle) &&
        !(child instanceof collectable.Collectable)
      )
        return;

      child.getThreeObject()?.removeFromScene();
    });
  }

  public fixCollectablePosition() {
    this._collectibles.forEach((child) => {
      child.checkSpawnPosition();
    });
  }

  public generateTrash() {
    let generatedTrashCount = 0;

    // First use up the minimum trash counts
    for (const [trashName, amount] of Object.entries(
      this._options.minTrashCount
    )) {
      for (let i = 0; i < amount; i++) {
        this._generateCollectableAtRandomPosition(
          trashName as collectable.CollectableName
        );
        generatedTrashCount++;
      }
    }

    // Then fill the others with random trash
    for (
      ;
      generatedTrashCount < this._options.trashCount;
      generatedTrashCount++
    ) {
      const trashIndex = utils.weightedRandomInt(
        collectable.trashes.length,
        1.5,
        true,
        true
      );
      const trashName = collectable.trashes[trashIndex];

      this._generateCollectableAtRandomPosition(trashName);
    }
  }

  private _generateCollectableAtRandomPosition(
    name: collectable.CollectableName
  ) {
    // TODO: wouldn't Halton sequence or a simplex be better here?
    let seed = random.int(0, 0xfffffff);

    const newAngle = ((seed % 1024) / 512.0) * Math.PI;

    seed >>= 10;

    const newDist = ((seed % 1024) / 1024.0) * constants.hexRadius;

    const x = this._position.x + Math.cos(newAngle) * newDist;
    const y = this._position.y + Math.sin(newAngle) * newDist;

    const c = new collectable.Collectable(name, new THREE.Vector2(x, y), true);

    this._activateChildChip(c, {
      attribute: "_collectables[]",
    });
  }

  private _generateDropZoneBuoys() {
    const distance = constants.dropZoneScheduleMin;

    const modelChips: chip.Chip[] = [];
    for (let i = 0; i < constants.dropZoneBuoyCount; i++) {
      const angle = (i / constants.dropZoneBuoyCount) * (2 * Math.PI);
      const x = this._position.x + Math.cos(angle) * distance;
      const y = this._position.y + Math.sin(angle) * distance;

      const modelChip = new threeObject.ThreeObject(
        mLoaders.commonModelAssets.getModelInfo("DROP_ZONE_BUOY"),
        this.chipContext.scene
      );
      modelChip.setPosition(new THREE.Vector3(x, 0, y));

      modelChips.push(modelChip);
    }

    return modelChips;
  }

  private _rotatePosition(position: THREE.Vec2) {
    return utils.rotateVec2(
      position.x,
      position.y,
      this._position,
      this._rotation
    );
  }

  private _rotateAngle(angle: number) {
    return angle - this._rotation;
  }
}

export class SeaMapOptions {
  radius!: number;
  emptyCellFraction = 0.2;
  trashPerTile: number | (() => number) = 0;
  bonusesToInclude: collectable.BonusName[] = [];
  uniqueTilesToInclude: string[] = [];
  dropZonesToInclude: string[] = [];
  minTrashCount: TrashCount = {};
  animalCountFractions: AnimalCountFractions = {}; // Between 0 and 1
  includeBonusFraction = 1; // Between 0 and 1
  includeCoinFraction = 1; // Between 0 and 1
  includeKey = false;
  includeUnlock?: collectable.UnlockName;
}

export class SeaMap extends context.ContextualChip {
  private _options: SeaMapOptions;

  private _seed!: string;

  private _container!: PIXI.Container;
  private _cells: (Cell | null)[][] = [];
  private _occlusionTickLenght = 150;
  private _occlusionTick = 0;
  private _occludeLimSquared = Math.pow(constants.hexRadius * 7, 2.0);
  private _planckOccludeLimSquared = Math.pow(constants.hexRadius * 1.5, 2.0);

  private _uniqueTileIndicators!: Record<string, PIXI.Container>;
  private _uniqueTilePositions!: Record<string, utils.TilePosition>;

  constructor(options: Partial<SeaMapOptions>) {
    super();

    this._options = chip.fillInOptions(options, new SeaMapOptions());
  }

  protected _onActivate(): void {
    /*
      Check if the URL contains a param for a set seed.
      Example :
        http://localhost:4321/index.html?seed=f39bf6c3df0c3
    */
    this._seed = params.getSeed() ?? makeRandomSeed();

    console.time("Map Generation");
    console.log("seed : ", this._seed);

    random.use(seedRandom(this._seed) as any);
    // TODO: simplex doesn't use the seed?
    // simplex = noise.createNoise2D(() => {
    //   return random.float(-1, 1);
    // });

    /**
     * Before creating Cells, we will start by a grid of CellOptions.
     * This allows us to modify the options as a whole set, before activating them
     *
     * We use the "odd-r" layout.
     * Because the map is based on a circle, many of the cells will not be used,
     * but we put `null` in their place.
     * If r is the radius, there are 2r + 1 cells, r cells on either side of the center
     */

    const cellOptionsGrid: CellOptionsGrid = [];
    for (let i = 0; i <= this._options.radius * 2; i++) {
      const row: (CellOptions | null)[] = [];

      for (let j = 0; j <= this._options.radius * 2; j++) row.push(null);

      cellOptionsGrid.push(row);
    }

    this._uniqueTilePositions = {};

    {
      /*
        Spawns the player home at world origin
      */
      const originPosition = new THREE.Vector2();
      this._generateUniqueCellOptions(
        cellOptionsGrid,
        originPosition.x,
        originPosition.y,
        "Lighthouse"
      );

      /**
        Add unique cells to the map using Halton Sequence
      */
      const haltonIdShift = random.int(0, 0xffffff);
      let haltonId = haltonIdShift;

      for (const uniqueTileId of this._options.uniqueTilesToInclude) {
        if (uniqueTileId in this._uniqueTilePositions) continue;

        /* Generate random positions using Halton, but disregard those that
          are outside the grid (isPositionEmpty() returns false)
          or too close to the player's starting position
        */
        let newPosition: THREE.Vector2;
        do {
          newPosition = utils
            .Halton2D(haltonId, this._options.radius * 2)
            .round();
          haltonId++;
        } while (
          !this.isPositionEmpty(
            cellOptionsGrid,
            newPosition.x,
            newPosition.y
          ) ||
          newPosition.distanceTo(originPosition) < 2
        );

        const turns = random.int(0, 5);
        this._generateUniqueCellOptions(
          cellOptionsGrid,
          newPosition.x,
          newPosition.y,
          uniqueTileId,
          turns,
          this._getTrashPerTile()
        );
      }
    }

    /**
     Generate the map in a spiral, forming a big hexagon
     This way of generating the map let the seed take full effect
     */
    for (let dist = 1; dist <= this._options.radius; dist++) {
      let q = 0;
      let r = 0;
      const inBorder = dist == this._options.radius;

      for (q = 0, r = -dist; q <= dist; q++) {
        const inCorner1 = q == 0;
        const inCorner2 = q == dist;
        if (inBorder) {
          if (inCorner1) {
            this._generateUniqueCellOptions(cellOptionsGrid, q, r, "corner", 4);
          } else if (inCorner2) {
            this._generateUniqueCellOptions(cellOptionsGrid, q, r, "corner", 5);
          } else {
            this._generateUniqueCellOptions(cellOptionsGrid, q, r, "border", 2);
          }
        } else if (this.isPositionEmpty(cellOptionsGrid, q, r)) {
          this._generateCellOptions(
            cellOptionsGrid,
            q,
            r,
            this._getTrashPerTile()
          );
        }
      }

      for (q--, r++; r <= 0; r++) {
        const inCorner = r == 0;
        if (inBorder) {
          if (inCorner) {
            this._generateUniqueCellOptions(cellOptionsGrid, q, r, "corner", 0);
          } else {
            this._generateUniqueCellOptions(cellOptionsGrid, q, r, "border", 0);
          }
        } else if (this.isPositionEmpty(cellOptionsGrid, q, r)) {
          this._generateCellOptions(
            cellOptionsGrid,
            q,
            r,
            this._getTrashPerTile()
          );
        }
      }

      for (q--; r <= dist; q--, r++) {
        const inCorner = r == dist;
        if (inBorder) {
          if (inCorner) {
            this._generateUniqueCellOptions(cellOptionsGrid, q, r, "corner", 1);
          } else {
            this._generateUniqueCellOptions(cellOptionsGrid, q, r, "border", 1);
          }
        } else if (this.isPositionEmpty(cellOptionsGrid, q, r))
          this._generateCellOptions(
            cellOptionsGrid,
            q,
            r,
            this._getTrashPerTile()
          );
      }

      for (r--; q >= -dist; q--) {
        const inCorner = q == -dist;
        if (inBorder) {
          if (inCorner) {
            this._generateUniqueCellOptions(cellOptionsGrid, q, r, "corner", 2);
          } else {
            this._generateUniqueCellOptions(cellOptionsGrid, q, r, "border", 2);
          }
        } else if (this.isPositionEmpty(cellOptionsGrid, q, r)) {
          this._generateCellOptions(
            cellOptionsGrid,
            q,
            r,
            this._getTrashPerTile()
          );
        }
      }

      for (q++, r--; r >= 0; r--) {
        const inCorner = r == 0;
        if (inBorder) {
          if (inCorner) {
            this._generateUniqueCellOptions(cellOptionsGrid, q, r, "corner", 3);
          } else {
            this._generateUniqueCellOptions(cellOptionsGrid, q, r, "border", 0);
          }
        } else if (this.isPositionEmpty(cellOptionsGrid, q, r)) {
          this._generateCellOptions(
            cellOptionsGrid,
            q,
            r,
            this._getTrashPerTile()
          );
        }
      }

      for (q++; q <= -1; q++, r--) {
        if (inBorder) {
          this._generateUniqueCellOptions(cellOptionsGrid, q, r, "border", 1);
        } else if (this.isPositionEmpty(cellOptionsGrid, q, r)) {
          this._generateCellOptions(
            cellOptionsGrid,
            q,
            r,
            this._getTrashPerTile()
          );
        }
      }
    }

    // Go through any remaining unassigned cells, and fill them in with randomly chosen presets
    {
      const unassignedCells = filterCellOptionsGrid(
        cellOptionsGrid,
        (cellOptions) => !cellOptions.id
      );
      const targetAssignedCount = Math.round(
        (1 - this._options.emptyCellFraction) * unassignedCells.length
      );

      // Because we don't have a seeded `shuffle()` function, pick out cells one by one
      let assignedCount = 0;
      while (assignedCount < targetAssignedCount) {
        const index = random.int(0, unassignedCells.length - 1);
        unassignedCells[index].id = pickCellId();
        unassignedCells.splice(index, 1);
        assignedCount++;
      }
    }

    // Distribute bonuses
    {
      // Find cells that can have bonuses that can be filtered out
      const cellsWithBonusSlots = filterCellOptionsGrid(
        cellOptionsGrid,
        (cellOptions, cellPreset) =>
          cellOptions.filterCollectables &&
          !!cellPreset &&
          cellPreset.collectables.filter(
            (c) => c.name === "RANDOM_SLOT" || collectable.isBonus(c.name)
          ).length > 0
      );
      // cellOptionsGrid.forEach((row) =>
      //   row.forEach((cellOptions) => {
      //     if (!cellOptions || !cellOptions.filterCollectables) return;

      //     cellsWithBonusSlots.push(cellOptions);
      //   })
      // );
      console.log(
        "Counted",
        cellsWithBonusSlots.length,
        "cells with bonus slots"
      );

      // Determine the number that should have bonuses
      const totalBonusCount = Math.ceil(
        this._options.includeBonusFraction * cellsWithBonusSlots.length
      );

      // Pick those with bonuses and set them to 1. The others get 0
      _.shuffle(cellsWithBonusSlots).forEach((cellOptions, i) => {
        if (i < totalBonusCount) {
          cellOptions.maxBonuses = 1;
        } else {
          cellOptions.maxBonuses = 0;
        }
      });

      console.log("Set", totalBonusCount, "tiles with bonuses");
    }

    // Distribute animals
    {
      // Find cells that can have animals
      let cellsWithAnimalSlots: CellOptions[] = filterCellOptionsGrid(
        cellOptionsGrid,
        (cellOptions, cellPreset) =>
          cellOptions.filterCollectables &&
          !!cellPreset &&
          cellPreset.obstacles.filter((o) => o.dynamic).length > 0
      );
      // cellOptionsGrid.forEach((row) =>
      //   row.forEach((cellOptions) => {
      //     if (!cellOptions || cellOptions!.forceUniqueId) return;

      //     cellsWithAnimalSlots.push(cellOptions);
      //   })
      // );
      console.log(
        "Counted",
        cellsWithAnimalSlots.length,
        "cells with animal slots"
      );

      // Shuffle the cells, so we can take from them randomly
      cellsWithAnimalSlots = _.shuffle(cellsWithAnimalSlots);

      // Determine the number that should have each number of animals
      const cellsWithAnimalsCount = cellsWithAnimalSlots.length;
      for (let maxAnimals = 3; maxAnimals > 0; maxAnimals--) {
        // Look up the fraction for this number of animals, defaulting to 0
        const fraction = this._options.animalCountFractions[maxAnimals] ?? 0;

        // Calculate the number of cells with that number of animals
        const cellsToAssignCount = Math.round(cellsWithAnimalsCount * fraction);

        // Assign those
        const cellsToAssign = cellsWithAnimalSlots.splice(
          0,
          cellsToAssignCount
        );

        cellsToAssign.forEach(
          (cellOptions) => (cellOptions.maxAnimals = maxAnimals)
        );
        console.log(
          "Set",
          cellsToAssign.length,
          "tiles to",
          maxAnimals,
          "max animals"
        );
      }

      // Set the remaining cells to 0 maxAnimals
      cellsWithAnimalSlots.forEach(
        (cellOptions) => (cellOptions.maxAnimals = 0)
      );
      console.log("Set", cellsWithAnimalSlots.length, "tiles to 0 animals");
    }

    // Set trash amounts
    {
      // Find cells with trash
      const cellsWithTrash: CellOptions[] = filterCellOptionsGrid(
        cellOptionsGrid,
        (cellOptions) =>
          cellOptions.filterCollectables && cellOptions.trashCount > 0
      );
      // cellOptionsGrid.forEach((row) =>
      //   row.forEach((cellOptions) => {
      //     if (!cellOptions || cellOptions!.trashCount <= 0) return;

      //     cellsWithTrash.push(cellOptions);
      //   })
      // );
      console.log("Counted", cellOptionsGrid.length, "cells with trash");

      const minTrashCountPerCell = Object.fromEntries(
        Object.entries(this._options.minTrashCount).map(([name, amount]) => [
          name,
          Math.ceil(amount / cellOptionsGrid.length),
        ])
      ) as TrashCount;

      cellsWithTrash.forEach((cellOptions) => {
        cellOptions.minTrashCount = minTrashCountPerCell;
      });
    }

    // Distribute coins
    {
      // Find cells that can have coins
      const cellsWithCoinSlots = filterCellOptionsGrid(
        cellOptionsGrid,
        (cellOptions, cellPreset) =>
          cellOptions.filterCollectables &&
          !!cellPreset &&
          cellPreset.collectables.filter((c) => c.name === "BONUS_COIN")
            .length > 0
      );
      // cellOptionsGrid.forEach((row) =>
      //   row.forEach((cellOptions) => {
      //     if (!cellOptions || cellOptions!.forceUniqueId) return;

      //     cellsWithCoinSlots.push(cellOptions);
      //   })
      // );
      console.log(
        "Counted",
        cellsWithCoinSlots.length,
        "cells with coin slots"
      );

      // Determine the number that should have coins
      const totalCoinCount = Math.ceil(
        this._options.includeCoinFraction * cellsWithCoinSlots.length
      );

      // Pick those with coins and those without
      _.take(_.shuffle(cellsWithCoinSlots), totalCoinCount).forEach(
        (cellOptions) => {
          cellOptions.includeCoins = true;
        }
      );

      console.log("Set", totalCoinCount, "tiles with coins");
    }

    // Distribute keys and unlocks
    if (this._options.includeKey || this._options.includeUnlock) {
      let cellsWithRandomSlots = filterCellOptionsGrid(
        cellOptionsGrid,
        (cellOptions, cellPreset) =>
          cellOptions.filterCollectables &&
          !!cellPreset &&
          cellPreset.collectables.filter((c) => c.name === "RANDOM_SLOT")
            .length > 0
      );
      // cellOptionsGrid.forEach((row) =>
      //   row.forEach((cellOptions) => {
      //     if (!cellOptions || cellOptions!.forceUniqueId) return;

      //     cellsWithRandomSlots.push(cellOptions);
      //   })
      // );
      console.log(
        "Counted",
        cellsWithRandomSlots.length,
        "cells with random slots"
      );

      if (this._options.includeKey) {
        if (cellsWithRandomSlots.length > 0) {
          cellsWithRandomSlots = _.shuffle(cellsWithRandomSlots);

          const [cellOptions] = cellsWithRandomSlots.splice(0, 1);
          cellOptions.includeKey = true;

          console.log("Set a tile to include key");
        }
      }

      if (this._options.includeUnlock) {
        if (cellsWithRandomSlots.length > 0) {
          cellsWithRandomSlots = _.shuffle(cellsWithRandomSlots);

          const [cellOptions] = cellsWithRandomSlots.splice(0, 1);
          cellOptions.includeUnlock = this._options.includeUnlock;

          console.log(
            "Set a tile to include an unlock",
            this._options.includeUnlock
          );
        }
      }
    }

    // Create Cells from CellOptions
    this._cells = cellOptionsGrid.map((cellOptionsRow) =>
      cellOptionsRow.map((cellOptions) =>
        cellOptions ? new Cell(cellOptions) : null
      )
    );

    console.timeEnd("Map Generation");

    // /*
    //     For each cells, correct the position
    //     of all collectable, so they can't spawn
    //     under Islands.

    //     This method bypass all activations.
    //     We only need to temporarly add the models
    //     to the THREE JS scene.
    // */
    // console.time("Map Collectables Check");
    // for (let i = 0; i < this._cells.length; i++)
    //   for (let j = 0; j < this._cells[i].length; j++) {
    //     const cell = this._cells[i][j];
    //     if (cell) {
    //       cell.addAllToScene();

    //       cell.fixCollectablePosition();

    //       cell.removeAllFromScene();
    //     }
    //   }

    // console.timeEnd("Map Collectables Check");

    ////////////////
    this._container = new PIXI.Container();
    this.chipContext.container.addChild(this._container);

    this._uniqueTileIndicators = {};

    for (const id in this._uniqueTilePositions) {
      let indicatorTextureName: tLoaders.TextureAssetName;
      if (id === "Lighthouse") {
        indicatorTextureName = "tile-indicators/tile-indicator-lighthouse";
      } else {
        indicatorTextureName = ("tile-indicators/tile-indicator-" +
          id
            .substring("BONUS_".length)
            .toLowerCase()) as tLoaders.TextureAssetName;
      }

      const indicatorTexture = PIXI.Assets.get(indicatorTextureName);
      if (!indicatorTexture) {
        throw new Error(`No indicator texture for tile ${id}`);
      }

      const tileIndicatorContainer = new PIXI.Container();
      this._container.addChild(tileIndicatorContainer);

      const bg = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture("tile-indicators/tile-indicator-bg")
      );
      bg.name = "background";
      bg.anchor.set(0.5);
      bg.position.set(0, 0);
      tileIndicatorContainer.addChild(bg);

      const sprite = new PIXI.Sprite(indicatorTexture);
      sprite.anchor.set(0.5, 0.55);
      sprite.position.set(0, 0);
      sprite.scale.set(0.35);
      tileIndicatorContainer.addChild(sprite);

      const text = new PIXI.Text("", {
        fontFamily: "Hvd Comic Serif Pro",
        fontSize: 20,
        strokeThickness: 4,
        fill: constants.almostBlack,
        stroke: constants.moneyColor,
      });
      text.name = "distanceText";
      text.anchor.set(0.5, 0.85);
      text.position.set(0, -bg.height * 0.5);
      tileIndicatorContainer.addChild(text);

      this._uniqueTileIndicators[id] = tileIndicatorContainer;
    }
  }

  protected _onTerminate() {
    this._chipContext.container.removeChild(this._container);
  }

  protected _onTick(): void {
    this._occlusionTick += this._lastTickInfo.timeSinceLastTick;

    if (this._occlusionTick > this._occlusionTickLenght) {
      this._occlusionTick = 0;

      this.updateOcclusion();
    }

    for (const id in this._uniqueTileIndicators) {
      const tilePos = this._uniqueTilePositions[id];
      const data = tilePos.getScreenPosition(
        this.chipContext.camera.camera,
        this.chipContext.playerInfo,
        150
      );
      const screenPos = data?.clamped;
      const actualPos = data?.actual;

      const indicator = this._uniqueTileIndicators[tilePos.id];

      if (screenPos && actualPos) {
        const indicatorBG = indicator.getChildByName(
          "background"
        ) as PIXI.Sprite;
        const indicatorText = indicator.getChildByName(
          "distanceText"
        ) as PIXI.Text;
        indicator.visible = true;
        indicator.x = screenPos.x;
        indicator.y = screenPos.y;

        const diff = actualPos.sub(screenPos);
        const angle = Math.atan2(-diff.x, Math.abs(diff.y));

        indicatorBG.rotation = angle;

        const distance = data.distance;
        indicatorText.text = `${Math.floor(distance)}m`;

        // const scale = Math.max(0.4, 1 - distance / 4000);
        const scale = Math.max(0.5, 1 - distance / 2000);
        indicator.scale.set(scale);

        // const opacity = THREE.MathUtils.smoothstep(distance, 200, 1000);
        // indicator.alpha = opacity;
      } else {
        indicator.visible = false;
      }
    }

    // TODO: replace this with occlusion check
  }

  private _generateCellOptions(
    cellOptionsGrid: CellOptionsGrid,
    x: number,
    y: number,
    trashCount = 0
  ) {
    const inTabX = x + this._options.radius;
    const inTabY = y + this._options.radius;
    if (cellOptionsGrid[inTabX][inTabY])
      throw new Error("CellOptions already generated at this location");

    const turns = random.int(0, 5);
    cellOptionsGrid[inTabX][inTabY] = chip.fillInOptions(
      {
        x,
        y,
        // emptyCellFraction: this._options.emptyCellFraction,
        filterCollectables: true,
        turns,
        bonusesToInclude: this._options.bonusesToInclude,
        trashCount,
      },
      new CellOptions()
    );
  }

  private _generateUniqueCellOptions(
    cellOptionsGrid: CellOptionsGrid,
    x: number,
    y: number,
    id: string,
    turns = 0,
    trashCount = 0
    // minTrashCount: TrashCount = {}
  ) {
    // console.log("_generateUniqueCell", id);

    const inTabX = x + this._options.radius;
    const inTabY = y + this._options.radius;
    if (cellOptionsGrid[inTabX][inTabY])
      throw new Error("CellOptions already generated at this location");

    cellOptionsGrid[inTabX][inTabY] = chip.fillInOptions(
      {
        x,
        y,
        // emptyCellFraction: this._options.emptyCellFraction,
        id,
        turns,
        filterCollectibles: false,
        isDropZone: this._isDropZone(id),
        trashCount,
      },
      new CellOptions()
    );

    if (id != "border" && id != "corner") {
      this._uniqueTilePositions[id] = new utils.TilePosition(x, y, id);
    }
  }

  /**
   * Only positions with nothing in them and inside the map radius
   * are considered empty
   */
  public isPositionEmpty(
    cellOptionsGrid: CellOptionsGrid,
    x: number,
    y: number
  ) {
    if (utils.axialDistanceToOrigin(x, y) >= this._options.radius) return false;

    const inTabX = x + this._options.radius;
    const inTabY = y + this._options.radius;

    return !cellOptionsGrid[inTabX][inTabY];
  }

  private updateOcclusion() {
    mLoaders.collectablesModelAssets.resetInstances();
    mLoaders.obstaclesModelAssets.resetInstances();

    // TODO: restore occlusion

    for (let i = 0; i < this._cells.length; i++)
      for (let j = 0; j < this._cells[i].length; j++) {
        const cell = this._cells[i][j];
        if (cell) {
          const dist = cell.getSquaredDistFrom(
            new THREE.Vector2(
              this.chipContext.playerInfo.positionX,
              this.chipContext.playerInfo.positionY
            )
          );
          if (cell.state == "active") {
            if (dist > this._occludeLimSquared) {
              // cell.terminate();
            } else if (dist < this._planckOccludeLimSquared) {
              cell.activatePlanckOjects();
            }
          } else {
            if (dist < this._occludeLimSquared) {
              this._activateChildChip(cell);

              if (dist > this._planckOccludeLimSquared) {
                // cell.deactivatePlanckOjects();
              }
            }
          }
        }
      }
  }

  public at(pos: THREE.Vector2): Cell | null {
    return this._cells[pos.x + this._options.radius][
      pos.y + this._options.radius
    ];
  }

  public aquireDroppedCollectable(item: collectable.Collectable) {
    const cellPos: THREE.Vector2 = utils.worldToHex(
      item.getInitialPosition().x,
      item.getInitialPosition().y
    );
    const cell: Cell | null = this.at(cellPos);

    if (cell) {
      cell.addCollectable(item);
      // console.log(item);
    } else {
      console.error("No cell to aquire collectable");
    }
  }

  private _getTrashPerTile() {
    if (typeof this._options.trashPerTile === "number") {
      return this._options.trashPerTile;
    } else {
      // Call as a function
      return this._options.trashPerTile();
    }
  }

  private _isDropZone(tileId: string): boolean {
    if (tileId === "Lighthouse") return true;

    return this._options.dropZonesToInclude.includes(tileId);
  }
}

export function makeRandomSeed() {
  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(16);
}

function pickCellId(): string {
  // Determine the rarity of the cell
  const rarityIndex = utils.weightedRandomInt(
    pickedTileRarities.length,
    2,
    true,
    true
  );
  const rarity = pickedTileRarities[rarityIndex];

  // Pick the ID among the cells of the given rarity
  // OPT: tiles could be organized by rarity ahead of time
  const presetsOfSameRarity = Object.entries(cellPresets).filter(
    ([id, preset]) => preset.rarity === rarity
  );

  return random.choice(presetsOfSameRarity)![0];
}

/** Returns an array of CellOptions in the grid that exist for which calling `predicate` returns true */
function filterCellOptionsGrid(
  cellOptionsGrid: CellOptionsGrid,
  predicate: (cellOptions: CellOptions, cellPreset?: CellPreset) => boolean
): CellOptions[] {
  const result: CellOptions[] = [];
  cellOptionsGrid.forEach((row) =>
    row.forEach((cellOptions) => {
      if (!cellOptions) return;

      const preset =
        (cellOptions.id && cellPresets[cellOptions.id]) || undefined;

      if (predicate(cellOptions, preset)) result.push(cellOptions);
    })
  );
  return result;
}
