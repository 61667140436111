import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

import * as responsive from "../../responsive";
import * as scrollBox from "../components/scrollBox";

export class ShopTab extends responsive.ResponsiveChip {
  private _container!: PIXI.Container;
  private _scrollBox!: scrollBox.ScrollBox;

  constructor(private readonly _items: chip.Chip[]) {
    super();
  }

  protected _onActivate() {
    super._onActivate();

    this._container = new PIXI.Container();
    this.chipContext.container.addChild(this._container);

    this._scrollBox = new scrollBox.ScrollBox({
      width: 1100,
      height: 450,
      barRightMargin: 15,
      position: {
        x: 50,
        y: 50,
      },
    });

    this._activateChildChip(this._scrollBox, {
      context: { container: this._container },
    });

    this._items.forEach((item, index) => {
      // Create offset container to put item in
      const itemContainer = new PIXI.Container();
      itemContainer.position.y = index * 220;
      this._scrollBox.content.addChild(itemContainer);

      this._activateChildChip(item, {
        context: { container: itemContainer },
      });

      // HACK: not all items can be selected and deselected.
      // But here we just listen for the event if it exists, and call the function if it exists
      this._subscribe(item, "selected", () => {
        for (const otherItem of this._items) {
          if ("unselect" in otherItem && otherItem !== item) {
            // @ts-ignore
            otherItem.unselect();
          }
        }
        this.emit("selected", item);
      });
    });
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container);

    this._container.destroy();
  }

  get selected() {
    return this._container.visible;
  }

  set selected(value: boolean) {
    this._container.visible = value;
    this._scrollBox.isActive = value;
  }
}
