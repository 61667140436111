/*
    The following elements are imported here automatically from shader/waves.glsl : 

        #define DOWAVES; 
        uniform float uTime;

        float getOceanHeight(vec3 wpos); 
        float getWave(vec3 wpos, float x_Frequency, float z_frequency, float speed, float offset);

    The following elements are imported here automatically from shader/ear.vert shaders/earthCurvature.glsl : 

        float getCurvature(vec2 viewPoint, vec2 pos)

    The following elements are imported here automaticly from THREE JS shader system :

        vec3 position := (read only) posiiton of the vertex in the model
        mat4 modelMatrix := world transformation matrix of the model
    
    The following elements are importe here automaticly from the Custom Shader Material add-on :

        csm_Position := outpout the final position of the vertex in the model
*/

void main(){
    /*
        Create the reference points for the boats.
        The values are taken directly from blender when looking 
        at the model.
    */
    vec3 modelFloatPoints[3] = vec3[3](
        vec3(-5.0, 0.0, 3.0),
        vec3(5.0, 0.0, 3.0),
        vec3(0.0, 0.0, -6.0)
    );

    /*
        Get the world's coordinates of the points
    */
    vec3 floatPoints[3] = vec3[3](
        (modelMatrix * vec4(modelFloatPoints[0], 1.0)).xyz,
        (modelMatrix * vec4(modelFloatPoints[1], 1.0)).xyz,
        (modelMatrix * vec4(modelFloatPoints[2], 1.0)).xyz
    );

    vec3 vertexPositon = position;
#ifdef IS_NET_PART_2
    vertexPositon.z += 5.5;
#endif

#ifdef DOWAVES

    /*
        Get the ocean height of all points
    */
    modelFloatPoints[0].y = getOceanHeight(floatPoints[0]);
    modelFloatPoints[1].y = getOceanHeight(floatPoints[1]);
    modelFloatPoints[2].y = getOceanHeight(floatPoints[2]);

    /*
        Create a plane with the 3 points & get the angle in every axis
    */
    vec3 planeNormal = cross(modelFloatPoints[0]-modelFloatPoints[2], modelFloatPoints[1]-modelFloatPoints[2]);

    planeNormal = normalize(planeNormal);

    vec3 worldNormal = vec3(0.f, 1.f, 0.f);

    float angle = dot(planeNormal, worldNormal);

    vec3 a = normalize(cross(planeNormal, worldNormal));
    a.z *= -1.0;
    a.x *= -1.0;

    /*
        Create a rotation matrix & apply it to the model
    */
    float c = angle;
    float s = sqrt(1.f - c*c);
    float C = 1.f-c;
    mat3 roationMatrix = mat3(
        vec3(a.x*a.x*C + c,         a.x*a.y*C - a.z*s,      a.x*a.z*C + a.y*s),
        vec3(a.y*a.x*C + a.z*s,     a.y*a.y*C + c,          a.y*a.z*C - a.x*s),
        vec3(a.z*a.x*C - a.y*s,     a.z*a.y*C + a.x*s,      a.z*a.z*C + c)
        );

    vertexPositon *= roationMatrix;
    vertexPositon.y += (modelFloatPoints[0].y + modelFloatPoints[1].y + modelFloatPoints[2].y)/3.0;

#endif

    csm_Position = vertexPositon;
}
