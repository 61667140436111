import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";
import * as easing from "booyah/dist/easing";
import * as tween from "booyah/dist/tween";

import * as tLoaders from "../../loaders/textureLoaders";

import * as constants from "../../constants";
import * as context from "../../context";
import * as responsive from "../../responsive";

import * as button from "./button";

export class PopupBackgroundOptions {
  width!: number;
  height!: number;
  activateDarkLayer = false;
  horizontalMargin = 10;
  verticalMargin = 10;
}

export class PopupBackground extends responsive.ResponsiveChip {
  private _options: PopupBackgroundOptions;

  private _container!: PIXI.Container;
  private _darkLayer!: PIXI.Graphics;
  private _anchorContainer!: PIXI.Container;

  private _bg!: PIXI.NineSlicePlane;

  get content() {
    return this._bg;
  }

  constructor(options: Partial<PopupBackgroundOptions>) {
    super();

    this._options = chip.fillInOptions(options, new PopupBackgroundOptions());
  }

  protected _onActivate() {
    this._container = new PIXI.Container();
    this._container.eventMode = "dynamic";

    if (this._options.activateDarkLayer) {
      this._darkLayer = new PIXI.Graphics()
        .beginFill(constants.almostBlack, 0.5)
        .drawRect(0, 0, 2, 2)
        .endFill();

      this._container.addChild(this._darkLayer);
    }

    this._anchorContainer = new PIXI.Container();

    this._bg = tLoaders.UINineSlicePlaneAssets.getNineSlice("BG_Paper");

    this._bg.width = this._options.width;
    this._bg.height = this._options.height;
    this._bg.position.set(-this._options.width / 2, -this._options.height / 2);

    this._anchorContainer.addChild(this._bg);

    this._container.addChild(this._anchorContainer);

    this.chipContext.container.addChild(this._container);
  }

  protected _onResize(width: number, height: number) {
    // Scale the popup to keep it within the window
    const scaleX =
      width / (this._options.width + 2 * this._options.horizontalMargin);
    const scaleY =
      height / (this._options.height + 2 * this._options.verticalMargin);
    const minScale = Math.min(scaleX, scaleY, 1);
    this._anchorContainer.scale.set(minScale);

    // Center the popup
    this._anchorContainer.position.set(width / 2, height / 2);

    // Blocks all clicks behind the container
    this._container.hitArea = new PIXI.Rectangle(0, 0, width, height);

    if (this._options.activateDarkLayer) {
      this._darkLayer.width = width;
      this._darkLayer.height = height;
    }
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container);
    this._container.destroy();
  }
}

abstract class Popup extends context.ContextualChip {
  protected _container!: PIXI.Container;
  protected _bg!: PopupBackground;

  protected constructor(
    protected _width: number,
    protected _height: number,
    private _darkLayer = false
  ) {
    super();
  }

  get content() {
    return this._bg.content;
  }

  protected _onActivate() {
    this._container = new PIXI.Container();

    this._bg = new PopupBackground({
      width: this._width,
      height: this._height,
      activateDarkLayer: this._darkLayer,
    });

    this._activateChildChip(this._bg, {
      context: {
        container: this._container,
      },
    });

    this.chipContext.popupContainer.addChild(this._container);
    // this.chipContext.level.isPaused = true;
  }

  protected _onTerminate() {
    // this.chipContext.level.isPaused = false;
    this.chipContext.popupContainer.removeChild(this._container);
  }
}

interface ConfirmationPopupOptions {
  text: string;
  onConfirm?: () => void;
  onDeny?: () => void;
}

export class ConfirmationPopup extends Popup {
  constructor(private _options: ConfirmationPopupOptions) {
    super(500, 300, true);
  }

  protected _onActivate() {
    super._onActivate();

    const text = new PIXI.Text(this._options.text, {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 40,
      fill: 0x000000,
      wordWrap: true,
      breakWords: false,
      wordWrapWidth: 350,
    });

    text.anchor.set(0.5, 0);
    text.position.set(this._width / 2, 20);

    if (text.height > 200) {
      text.style.fontSize = 25;
    }

    this.content.addChild(text);

    this._activateChildChip(
      new button.Button({
        text: "No",
        width: 150,
        texture: "Button_Big_Grey",
        position: {
          x: this._width / 2 - 200,
          y: this._height - 100,
        },
        onClick: () => {
          this._options.onDeny?.();
          this.terminate();
        },
        shortcutKey: "Escape",
      }),
      {
        context: {
          container: this.content,
        },
      }
    );

    this._activateChildChip(
      new button.Button({
        text: "Yes",
        width: 150,
        texture: "Button_Big_Green",
        position: {
          x: this._width / 2 + 50,
          y: this._height - 100,
        },
        onClick: () => {
          this._options.onConfirm?.();
          this.terminate();
        },
        shortcutKey: "Enter",
      }),
      {
        context: {
          container: this.content,
        },
      }
    );
  }
}

interface AlertPopupOptions {
  text: string;
}

export class AlertPopup extends Popup {
  constructor(private _options: AlertPopupOptions) {
    super(400, 250);
  }

  protected _onActivate() {
    super._onActivate();

    const text = new PIXI.Text(this._options.text, {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 30,
      fill: 0x000000,
      wordWrap: true,
      breakWords: false,
      wordWrapWidth: 350,
    });

    text.anchor.set(0.5, 0);
    text.position.set(this._width / 2, 20);

    this.content.addChild(text);

    this._activateChildChip(
      new button.Button({
        text: "Ok",
        width: 150,
        texture: "Button_Big_Green",
        position: {
          x: this._width / 2 - 75,
          y: this._height - 100,
        },
        onClick: () => {
          this.terminate();
        },
        shortcutKey: "Enter",
      }),
      {
        context: {
          container: this.content,
        },
      }
    );
  }
}

export type HelpPopupName = string; // todo: replace by all IDs of help popups like: "foo" | "bar" | "baz"

export class HelpPopup extends Popup {
  constructor(private _name: HelpPopupName, private _text: string) {
    super(400, 250);
  }

  protected _onActivate() {
    if (!this.chipContext.saveManager.isDisplayedAgain(this._name)) {
      this.terminate();
      return;
    }

    super._onActivate();

    // text

    const text = new PIXI.Text(this._text, {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 30,
      fill: 0x000000,
      wordWrap: true,
      breakWords: false,
      wordWrapWidth: 350,
    });

    text.anchor.set(0.5, 0);
    text.position.set(this._width / 2, 20);

    this.content.addChild(text);

    // container "do not display anymore"

    const dndaContainer = new PIXI.Container();

    dndaContainer.position.set(this._width / 2, text.height + 50);
    dndaContainer.eventMode = "dynamic";

    this.content.addChild(dndaContainer);

    // text "do not display anymore"

    const dnda = new PIXI.Text("Do not display anymore", {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 20,
      fill: 0x000000,
      wordWrap: false,
    });

    dnda.anchor.set(0, 0.5);
    dnda.position.set(-dnda.width / 2, 0);

    dndaContainer.addChild(dnda);

    // checkbox "do not display anymore"

    const unchecked = new PIXI.Sprite(
      tLoaders.UITextureAssets.getTexture("Checkbox_Unchecked")
    );

    unchecked.anchor.set(1, 0.5);
    unchecked.position.set(-dnda.width / 2, 0);
    unchecked.visible = true;

    dndaContainer.addChild(unchecked);

    const checked = new PIXI.Sprite(
      tLoaders.UITextureAssets.getTexture("Checkbox_Checked")
    );

    checked.anchor.set(1, 0.5);
    checked.position.set(-dnda.width / 2, 0);
    checked.visible = false;

    dndaContainer.addChild(checked);

    // listener "do not display anymore"

    this._subscribe(dndaContainer, "pointertap", () => {
      const checkedValue = !checked.visible;

      checked.visible = checkedValue;
      unchecked.visible = !checkedValue;

      if (checkedValue) {
        this.chipContext.saveManager.doNotDisplayAnymore(this._name);
      } else {
        this.chipContext.saveManager.displayAgain(this._name);
      }
    });

    // ok button

    this._activateChildChip(
      new button.Button({
        text: "Ok",
        width: 150,
        texture: "Button_Big_Green",
        position: {
          x: this._width / 2 - 75,
          y: text.height + 100,
        },
        onClick: () => {
          this.terminate();
        },
        shortcutKey: "Enter",
      }),
      {
        context: {
          container: this.content,
        },
      }
    );
  }
}

export class DiscreteHint extends responsive.ResponsiveChip {
  private _container!: PIXI.Container;
  private _text!: PIXI.Text;

  constructor(private _hint: string) {
    super();
  }

  protected _onActivate() {
    this._container = new PIXI.Container();

    this._text = new PIXI.Text(this._hint, {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 50,
      fill: 0xffffff,
      stroke: constants.principalColor,
      strokeThickness: 10,
      wordWrap: false,
    });

    this._text.scale.set(0);
    this._text.anchor.set(0.5);

    this._container.addChild(this._text);

    this.chipContext.container.addChild(this._container);

    this._activateChildChip(
      new chip.Sequence([
        new tween.Tween({
          from: 0,
          to: 1,
          duration: 250,
          easing: easing.easeOutCubic,
          onUpdate: (value: number) => {
            this._text.alpha = value;
            this._text.scale.set(value);
          },
        }),
        new chip.Wait(3000),
        new tween.Tween({
          from: 1,
          to: 0,
          duration: 1000,
          easing: easing.easeOutExpo,
          onUpdate: (value: number) => {
            this._text.alpha = value;
            this._text.scale.set(value);
          },
        }),
        new chip.Lambda(() => {
          this.terminate();
        }),
      ])
    );
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container);
  }

  protected _onResize(width: number) {
    this._container.position.set(width / 2, 100);

    if (responsive.isMobile()) {
      this._container.scale.set(0.4);
    } else if (responsive.isTablet()) {
      this._container.scale.set(0.6);
    } else {
      this._container.scale.set(1);
    }
  }
}

export class BottomPopupOptions {
  text!: string;
  icon!: tLoaders.TextureAssetName;
  childChip!: chip.Chip;
  timeBeforeClose = 5000; // Set to <=0 to deactivate
}

export class BottomPopup extends responsive.ResponsiveChip {
  static current?: BottomPopup;

  private _options: BottomPopupOptions;

  private readonly _width = 1000;
  private readonly _height = 200;

  private _container!: PIXI.Container;
  private _background!: PIXI.NineSlicePlane;

  constructor(options: Partial<BottomPopupOptions>) {
    super();

    this._options = chip.fillInOptions(options, new BottomPopupOptions());
  }

  protected _onActivate() {
    if (BottomPopup.current) {
      BottomPopup.current.terminate();
    }

    BottomPopup.current = this;

    this._container = new PIXI.Container();

    // Background
    this._background = tLoaders.UINineSlicePlaneAssets.getNineSlice("BG_Paper");
    this._background.width = this._width;
    this._background.height = this._height;
    // No anchor on NineSlicePlane, so we have to center it manually
    this._background.position.set(-this._width / 2, -this._height / 2);
    this._container.addChild(this._background);

    // Icon
    if (this._options.icon) {
      const icon = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture(this._options.icon)
      );
      icon.anchor.set(0.5);
      icon.position.set(this._height / 2);
      this._background.addChild(icon);
    }

    // Child chip
    if (this._options.childChip) {
      // We should use a special container placed under any interactable object,
      // in case the Chip causes display objects to be added on top
      // (can happen with Sequence for example, where display objects are added at a later time)
      const childChipContainer = new PIXI.Container();
      childChipContainer.position.set(this._height / 2);
      this._background.addChild(childChipContainer);

      this._activateChildChip(this._options.childChip, {
        context: {
          container: childChipContainer,
        },
      });
    }

    // Text
    const text = new PIXI.Text(this._options.text, {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 35,
      fill: 0x000000,
      wordWrap: true,
      breakWords: false,
      wordWrapWidth: this._width - this._height,
    });

    text.anchor.set(0, 0.5);
    text.position.set(this._height, this._height / 2);

    this._background.addChild(text);

    // close button

    const closeButton = new PIXI.Sprite(
      tLoaders.UITextureAssets.getTexture("Button_Close")
    );

    closeButton.anchor.set(0.8, 0.2);
    closeButton.position.set(this._width, 0);
    closeButton.eventMode = "dynamic";

    this._subscribeOnce(closeButton, "pointertap", () => {
      this.terminate();
    });

    this._background.addChild(closeButton);

    // add to container

    this.chipContext.popupContainer.addChild(this._container);

    if (this._options.timeBeforeClose > 0) {
      this._activateChildChip(
        new chip.Sequence([
          new chip.Wait(this._options.timeBeforeClose),
          new chip.Lambda(() => this.terminate()),
        ])
      );
    }
  }

  protected _onTerminate() {
    this.chipContext.popupContainer.removeChild(this._container);

    delete BottomPopup.current;
  }

  protected _onResize(width: number, height: number) {
    this._container.position.set(width / 2, height - this._height / 2 - 25);

    if (responsive.isMobile()) {
      this._container.scale.set(0.4);
    } else if (responsive.isTablet()) {
      this._container.scale.set(0.6);
    } else {
      this._container.scale.set(1);
    }
  }
}
