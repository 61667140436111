import * as THREE from "three";

import * as chip from "booyah/dist/chip";
import * as geom from "booyah/dist/geom";

import * as mLoaders from "./loaders/modelLoaders";

import * as boat from "./boat";
import * as constants from "./constants";
import * as responsive from "./responsive";
import * as threeObject from "./threeObject";
import * as utils from "./utils";

export class BoatSpeedFX extends responsive.ResponsiveChip {
  private _fxModel1!: threeObject.ThreeObject;
  private _fxModel2!: threeObject.ThreeObject;
  private _fxModel3!: threeObject.ThreeObject;
  private _soundSequence!: chip.Sequence;
  private _lastSpeedBoostFraction!: number;

  protected _onActivate(): void {
    this._lastSpeedBoostFraction = 0;

    // FX model 1
    let model = mLoaders.commonModelAssets.getModelInfo("SPEED_FX_1");
    utils.makeAnimatedMaterialFloating(model);
    this._fxModel1 = new threeObject.ThreeObject(model, this.chipContext.scene);

    // FX model 2
    model = mLoaders.commonModelAssets.getModelInfo("SPEED_FX_2");
    utils.makeAnimatedMaterialFloating(model);
    this._fxModel2 = new threeObject.ThreeObject(model, this.chipContext.scene);

    // FX model 3
    model = mLoaders.commonModelAssets.getModelInfo("SPEED_FX_3");
    utils.makeAnimatedMaterialFloating(model);
    this._fxModel3 = new threeObject.ThreeObject(model, this.chipContext.scene);

    // Activate all chips
    this._activateChildChip(this._fxModel1);
    this._activateChildChip(this._fxModel2);
    this._activateChildChip(this._fxModel3);
    // this._activateChildChip(this._soundSequence);

    // Start animations manually
    this._fxModel1.startAnimations(THREE.LoopRepeat, Infinity, 0.2);
    this._fxModel2.startAnimations(THREE.LoopRepeat, Infinity);
    this._fxModel3.startAnimations(THREE.LoopRepeat, Infinity);

    // Scale depending on boat, if not Dinghy
    const boatName = this.chipContext.saveManager.selectedBoat;
    if (boatName != "Dinghy") {
      const referenceHitbox = boat.getHitbox("Dinghy");
      const currentHitbox = boat.getHitbox(boatName);
      const fxScale = currentHitbox.divide(referenceHitbox);
      //   console.log("FX Scale", fxScale);
      this._fxModel1.setScale(fxScale.x, 1, fxScale.y);
      this._fxModel2.setScale(fxScale.x, 1, fxScale.y);
      this._fxModel3.setScale(fxScale.x, 1, fxScale.y);
    }

    // Hide by default
    this._fxModel1.getModel.visible = false;
    this._fxModel2.getModel.visible = false;
    this._fxModel3.getModel.visible = false;

    // Listen to game over so we can stop the sequence
    this._subscribe(this.chipContext.level, "gameOver", () => {
      this.terminate();
    });
  }

  protected _onTick(): void {
    // Get boat object
    const boat = this.chipContext.level.boat as boat.Boat;
    if (!boat) return;

    const currentSpeedBoostFraction = boat.getSpeedBoostFraction();
    if (currentSpeedBoostFraction === 0) {
      this._fxModel1.getModel.visible = false;
      this._fxModel2.getModel.visible = false;
      this._fxModel3.getModel.visible = false;
      this._lastSpeedBoostFraction = 0;
      return;
    }

    // Copy position
    const boatPos = boat.getPosition();
    const pos = new THREE.Vector3(boatPos.x, 0, boatPos.y);
    this._fxModel1.setPosition(pos);
    this._fxModel2.setPosition(pos);
    this._fxModel3.setPosition(pos);

    // Copy angle + 0° CCW
    const boatAngle = boat.getAngle();
    this._fxModel1.setAngle(boatAngle);
    this._fxModel2.setAngle(boatAngle);
    this._fxModel3.setAngle(boatAngle);

    // The constant was acheived at 60 FPS, so we adjust the factor here (more for lower framerates, less for higher ones)
    const speedBoostFractionDelta =
      constants.speedBoostFractionDelta *
      (this._lastTickInfo.timeSinceLastTick * 0.06);
    const adjustedSpeedBoostFraction = geom.moveTowardsScalar(
      this._lastSpeedBoostFraction,
      currentSpeedBoostFraction,
      speedBoostFractionDelta
    );
    this._lastSpeedBoostFraction = adjustedSpeedBoostFraction;

    // Show FX depending on boat speed
    this._fxModel1.getModel.visible = adjustedSpeedBoostFraction > 0.05;
    this._fxModel2.getModel.visible = adjustedSpeedBoostFraction > 0.35;
    this._fxModel3.getModel.visible = adjustedSpeedBoostFraction > 0.6;

    // console.log(
    //   "boostedSpeedRatio",
    //   boostedSpeedRatio,
    //   this._fxModel1.getModel.visible,
    //   this._fxModel2.getModel.visible,
    //   this._fxModel3.getModel.visible
    // );
  }
}
