import * as PIXI from "pixi.js";

import * as boat from "../../boat";
import * as net from "../../net";
import * as responsive from "../../responsive";
import * as button from "../components/button";

import * as boatItem from "./boatItem";
import * as netItem from "./netItem";

/**
 * Emits: "validated"
 */
export class StartTab extends responsive.ResponsiveChip {
  private _container!: PIXI.Container;
  private _netItemContainer!: PIXI.Container;
  private _itemLayer!: PIXI.Container;
  private _buttonLayer!: PIXI.Container;
  private _boatItem!: boatItem.BoatItem;
  private _netItem!: netItem.NetItem;

  protected _onActivate() {
    super._onActivate();

    this._container = new PIXI.Container();
    this._container.position.set(50, 50);
    this.chipContext.container.addChild(this._container);

    this._itemLayer = new PIXI.Container();
    this._buttonLayer = new PIXI.Container();
    this._container.addChild(this._itemLayer, this._buttonLayer);

    // Offset vertically
    this._netItemContainer = new PIXI.Container();
    this._netItemContainer.position.set(0, 250);
    this._itemLayer.addChild(this._netItemContainer);

    this._refreshBoat();
    this._refreshNet();

    this._subscribe(
      this.chipContext.saveManager,
      "selectedBoat",
      this._refreshBoat
    );
    this._subscribe(
      this.chipContext.saveManager,
      "selectedNet",
      this._refreshNet
    );

    const validateButton = new button.Button({
      text: "Start",
      texture: "Button_Big_Green",
      position: {
        x: 400,
        y: 430,
      },
      width: 300,
      large: true,
      onClick: () => {
        this.emit("validated");
      },
      shortcutKey: "Enter",
    });

    this._activateChildChip(validateButton, {
      context: { container: this._buttonLayer },
    });
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container);

    this._container.destroy();
  }

  private _refreshBoat() {
    if (this._boatItem?.state === "active")
      this._terminateChildChip(this._boatItem);

    const currentBoatName = this.chipContext.saveManager.selectedBoat;
    const currentBoatInfo = boat.boats.find(
      (info) => info.name === currentBoatName
    )!;

    this._boatItem = new boatItem.BoatItem(
      currentBoatInfo.name,
      currentBoatInfo.price,
      currentBoatInfo.stats,
      "change"
    );
    this._subscribe(this._boatItem, "change", () => this.emit("changeBoat"));

    this._activateChildChip(this._boatItem, {
      context: { container: this._itemLayer },
    });
  }

  private _refreshNet() {
    if (this._netItem?.state === "active")
      this._terminateChildChip(this._netItem);

    const currentNetName = this.chipContext.saveManager.selectedNet;
    const currentNetInfo = net.nets.find(
      (info) => info.name === currentNetName
    )!;

    this._netItem = new netItem.NetItem(
      currentNetInfo.name,
      currentNetInfo.price,
      currentNetInfo.stats,
      "change"
    );
    this._subscribe(this._netItem, "change", () => this.emit("changeNet"));

    this._activateChildChip(this._netItem, {
      context: { container: this._netItemContainer },
    });
  }

  get selected() {
    return this._container.visible;
  }

  set selected(value: boolean) {
    this._container.visible = value;
  }
}
