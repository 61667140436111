import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

import * as constants from "../../constants";
import * as responsive from "../../responsive";
import * as moneyView from "../components/moneyView";
import * as popup from "../components/popup";
import * as adItem from "../shop/adItem";
import * as boatItem from "../shop/boatItem";
import * as collectableItem from "../shop/collectableItem";
import * as netItem from "../shop/netItem";
import * as shopTab from "../shop/shopTab";
import * as shopTabHeader from "../shop/shopTabHeader";
import * as startTab from "../shop/startTab";

export class Shop extends responsive.ResponsiveChip {
  private _container!: PIXI.Container;
  private _background!: popup.PopupBackground;

  private _tabs!: Record<
    shopTabHeader.ShopTabName,
    shopTabHeader.ShopTabHeader
  >;

  private _tabContents!: Record<
    shopTabHeader.ShopTabName,
    chip.Chip & { selected: boolean }
  >;

  private _selectTabAction?: chip.Chip;

  protected _onActivate(): void {
    this._container = new PIXI.Container();

    // Background
    this._background = new popup.PopupBackground({
      width: 1194,
      height: 550,
      verticalMargin: constants.popupInGameVerticalMargin,
    });

    this._activateChildChip(this._background, {
      context: {
        container: this._container,
      },
    });

    this._activateChildChip(new moneyView.MoneyView(), {
      context: {
        container: this._container,
      },
    });

    // Make selectable tab headers
    // Tab bar todo: position on top of background

    // @ts-expect-error
    this._tabs = {};
    for (const tabName of shopTabHeader.shopTabNames) {
      const tabChip = new shopTabHeader.ShopTabHeader(
        tabName,
        tabName === "start",
        false // todo: implement this after the alpha release
      );

      this._tabs[tabName] = tabChip;

      this._subscribe(tabChip, "selected", () => this._selectTab(tabName));

      // this._background.content.sortableChildren = true;

      this._activateChildChip(tabChip, {
        context: {
          container: this._background.content,
        },
      });
    }

    const theStartTab = new startTab.StartTab();
    this._subscribe(theStartTab, "validated", () => {
      this.terminate();
    });
    this._subscribe(theStartTab, "changeBoat", () => {
      this._selectTab("boats");
    });
    this._subscribe(theStartTab, "changeNet", () => {
      this._selectTab("nets");
    });

    const allBoatItems = [new adItem.AdItem(), ...boatItem.boatItems];
    const theBoatsTab = new shopTab.ShopTab(allBoatItems);
    this._subscribe(theBoatsTab, "selected", () => {
      this._makeSelectTabAction("start");
    });

    const allNetItems = [new adItem.AdItem(), ...netItem.netItems];
    const theNetsTab = new shopTab.ShopTab(allNetItems);
    this._subscribe(theNetsTab, "selected", () => {
      this._makeSelectTabAction("start");
    });

    const allCollectableItems = [
      new adItem.AdItem(),
      ...collectableItem.makeCollectableItems(this.chipContext.saveManager),
    ];
    const theCollectablesTab = new shopTab.ShopTab(allCollectableItems);

    // Initialize start tab to be selected at start
    this._tabContents = {
      start: theStartTab,
      boats: theBoatsTab,
      nets: theNetsTab,
      items: theCollectablesTab,
    };

    this._activateChildChip(
      new chip.Parallel(Object.values(this._tabContents)),
      {
        context: {
          container: this._background.content,
        },
      }
    );

    this._selectTab("start");

    this.resize();

    this.chipContext.popupContainer.addChild(this._container);
  }

  protected _onTerminate(): void {
    this.chipContext.container.removeChild(this._container);
  }

  private _selectTab(tabName: shopTabHeader.ShopTabName) {
    for (const tabContentsName of shopTabHeader.shopTabNames) {
      if (tabContentsName === tabName) this._tabs[tabContentsName].select();
      else this._tabs[tabContentsName].unselect();

      this._tabContents[tabContentsName].selected = tabContentsName === tabName;
    }
  }

  private _makeSelectTabAction(tabName: shopTabHeader.ShopTabName) {
    this._activateChildChip(
      new chip.Sequence([
        new chip.Wait(500),
        new chip.Lambda(() => this._selectTab(tabName)),
      ]),
      {
        attribute: "_selectTabAction",
      }
    );
  }
}
