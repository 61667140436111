// @ts-ignore
import frag from "../public/shaders/postProcess.frag";
// @ts-ignore
import vert from "../public/shaders/postProcess.vert";

import * as THREE from "three";

/*
    Create the shader material that will be used 
    on the final screen mesh.
*/
export const postProcessPass = new THREE.ShaderMaterial({
  fragmentShader: frag,
  vertexShader: vert,
  uniforms: {
    cameraNear: { value: 0 },
    cameraFar: { value: 0 },
    tDiffuse: { value: null },
    tDepth: { value: null },
    tDiffuseWater: { value: null },
    tDepthWater: { value: null },
  },
});
