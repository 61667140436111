// @ts-ignore
import obstaclesVertexShader from "../public/shaders/obstacles.vert";

import * as PLANCK from "planck-js";
import * as THREE from "three";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";

import * as chip from "booyah/dist/chip";

import * as aLoaders from "./loaders/audioLoaders";
import * as mLoaders from "./loaders/modelLoaders";

import * as audio from "./audio";
import * as context from "./context";
import * as gameObject from "./gameObject";
import * as obstaclePrefabs from "./obstaclePrefabs";
import * as planckObject from "./planckObject";
import * as utils from "./utils";

export class ObstacleModelHandler extends context.ContextualChip {
  constructor(private readonly _scene: THREE.Scene) {
    super();
  }

  protected _onActivate(): void {
    for (const element of Object.values(mLoaders.obstaclesModelAssets.buffer)) {
      /*
        Inject custom code into the model's material.

        If the model is animated, inject the animals shader 
        wih a custom injector.

        If not, inject the Island shader wit the injector 
        provided by three-custom-shader-material.

        Important note :
            this system does not support animated Islands yet.

        TODO :
            add an easy way to know if the model is for an 
            animal or an island, or separate animals & islands 
            in different classes (more time consuming)
    */

      if (element.isAnimated) utils.makeAnimatedMaterialFloating(element);
      else
        element.model?.scene.traverse((mesh) => {
          if (mesh instanceof THREE.Mesh) {
            mesh.material = new CustomShaderMaterial({
              silent: true,
              baseMaterial: mesh.material,
              vertexShader:
                utils.earthCurvatureFunctions +
                utils.shaderWavesFunctions +
                obstaclesVertexShader,
              uniforms: utils.waterMaterialUniform,
            });
          }
        });
    }
  }
}

// Translate from the keys used to define obstacle prefabs to those used for audio effects
const audioForObstacles: Partial<
  Record<obstaclePrefabs.ObstacleName, aLoaders.FxName>
> = {
  Dolphin: "dolphin",
  Turtle: "turtle",
  Whale: "whale",
  Bassan: "bassan",
};

const redMaterial = new THREE.MeshStandardMaterial({
  color: new THREE.Color(0xff0000),
  emissive: new THREE.Color(0xff0000),
});

export class Obstacle extends chip.Composite {
  private _dynamic!: boolean;
  private _currentPatrolPoint = 0;
  private _stunned = false;
  private _speed!: number;

  private _fx?: chip.Sequence;

  private _gameObject!: gameObject.GameObject;

  constructor(
    public readonly id: obstaclePrefabs.ObstacleName,
    private _initialPosition: THREE.Vector2,
    private _initialAngle: number,
    private readonly _patrolPoints: THREE.Vector2[] = []
  ) {
    super();
  }

  protected _onActivate(): void {
    const prefab = obstaclePrefabs.obstaclePrefabs[this.id];
    if (prefab.modelInfo.model)
      prefab.modelInfo.model.scene.position.y = prefab.parseValue(
        prefab.offsetY
      );

    this._gameObject = new gameObject.GameObject(
      prefab.modelInfo,
      this.chipContext.scene,
      {
        userData: this,
        fixtures: prefab.colliders.map((collider) => ({
          shape: collider,
          isSensor: false,
          friction: 0.1,
          filterCategoryBits: planckObject.GameObjectMask.Obstacle,
          filterMaskBits:
            planckObject.GameObjectMask.Player |
            planckObject.GameObjectMask.Collectable,
        })),
        bodyDef: {
          type: prefab.dynamic ? "kinematic" : "static",
          position: PLANCK.Vec2(0, 0),
          allowSleep: false, // TEST
        },
      },
      this.chipContext.world,
      prefab.dynamic ? 0xff0000 : 0x00ee00
    );

    this._gameObject.setPosition(
      this._initialPosition.x,
      this._initialPosition.y
    );
    this._gameObject.setAngle(this._initialAngle);

    this._activateChildChip(this._gameObject);

    this._dynamic = prefab.dynamic;
    this._speed = prefab.parseValue(prefab.speed);

    const scaleMultiplier = prefab.parseValue(prefab.scale);
    this.getThreeObject()!.setScale(
      scaleMultiplier,
      scaleMultiplier,
      scaleMultiplier
    );

    // Start animation with a random offset if needed
    if (obstaclePrefabs.obstaclePrefabs[this.id].randomAnimationOffset) {
      this.getThreeObject().startAnimationsWithRandomOffset();
    }

    const model = this.getModel;

    /*
        Create a PositionalAudio for the obstacle.
        
        TODO :
            => wrap this code in a separate function.
            => create a custom chip using Wait that 
               can wait a random number of ms each
               time it's activated
    */

    if (model) {
      if (this.id in audioForObstacles) {
        const fxSound = aLoaders.getFxSound(audioForObstacles[this.id]!);
        if (fxSound) {
          const fx = new THREE.PositionalAudio(aLoaders.sfxListener);
          fx.setBuffer(fxSound.buffer!);
          fx.setRefDistance(2);
          fx.setMaxDistance(70.0);
          fx.position.y = 5.0;
          model.add(fx);

          const seq = new chip.Sequence(
            [
              new chip.Wait(Math.random() * 5000 + 10000),
              new audio.AudioPlayer(fx),
            ],
            { loop: true }
          );

          this._fx = seq;
        }
      }
    }

    if (this._dynamic && this._patrolPoints.length > 0) {
      const dir = PLANCK.Vec2(
        this._patrolPoints[this._currentPatrolPoint].x -
          this._gameObject.getPosition().x,
        this._patrolPoints[this._currentPatrolPoint].y -
          this._gameObject.getPosition().y
      );
      dir.normalize();
      dir.mul(this._speed);
      this.getPlanckObject()?.body?.setLinearVelocity(dir);
      const angle = Math.atan2(dir.y, dir.x);
      this.getPlanckObject()?.setAngle(angle);

      if (this._fx) this._activateChildChip(this._fx);
    }
  }

  protected _onTick(): void {
    if (!this._dynamic || this._patrolPoints.length === 0 || this._stunned)
      return;

    // If reaching destination control point, switch to the next
    if (
      this._gameObject
        .getPosition()
        .sub(this._patrolPoints[this._currentPatrolPoint])
        .length() < 5.0
    ) {
      this._currentPatrolPoint =
        (this._currentPatrolPoint + 1) % this._patrolPoints.length;
    }

    const dir = PLANCK.Vec2(
      this._patrolPoints[this._currentPatrolPoint].x -
        this._gameObject.getPosition().x,
      this._patrolPoints[this._currentPatrolPoint].y -
        this._gameObject.getPosition().y
    );
    dir.normalize();
    let angle = new THREE.Vector2(dir.x, dir.y).angle();

    dir.mul(this._speed);
    this.getPlanckObject()?.body?.setLinearVelocity(dir);

    let currentAngle = this.getPlanckObject()?.getAngle() ?? 0;
    if (currentAngle > 0) currentAngle %= Math.PI * 2;
    else currentAngle = (currentAngle % (Math.PI * 2)) + Math.PI * 2;

    const correctAngle = angle;
    let correction = correctAngle - currentAngle;

    /*
          Invert the correction angle if the given
          correction is over 90°
      */
    if (Math.abs(correction) > Math.PI) {
      correction =
        -Math.sign(correction) *
        (Math.min(currentAngle, Math.PI * 2 - currentAngle) +
          Math.min(correctAngle, Math.PI * 2 - correctAngle));
    }

    const correctionSpeed = 2.0;
    correction *= correctionSpeed * this.chipContext.playerInfo.deltatime;

    angle = currentAngle + correction;

    this.getPlanckObject()?.setAngle(angle);
    this._gameObject.updateThreeObject(-Math.PI / 2);
  }

  public isDynamic() {
    return this._dynamic;
  }

  public stun() {
    if (this._stunned) return;

    this._stunned = true;
    this.getPlanckObject()?.body?.setLinearVelocity(PLANCK.Vec2(0, 0));

    const normalMaterials: THREE.Material[] = [];

    this._gameObject.getModel.traverse((child) => {
      if (!(child instanceof THREE.Mesh)) return;

      normalMaterials.push(child.material);
      child.material = redMaterial;
    });

    this._activateChildChip(
      new chip.Alternative([
        new chip.Functional({
          terminate: () => {
            this._stunned = false;
            this.getModel.visible = true;

            // Restore color
            this._gameObject.getModel.traverse((child) => {
              if (!(child instanceof THREE.Mesh)) return;

              child.material = normalMaterials.shift();
            });
          },
        }),
        new chip.Sequence([
          () =>
            this.getModel
              ? new chip.Sequence([
                  ...utils.times(
                    3,
                    () =>
                      new chip.Sequence([
                        new chip.Lambda(() => {
                          this.getModel.visible = false;
                        }),
                        new chip.Wait(300),
                        new chip.Lambda(() => {
                          this.getModel.visible = true;
                        }),
                        new chip.Wait(300),
                      ])
                  ),
                ])
              : new chip.Wait(5000),
          new chip.Lambda(() => {
            this._stunned = false;
          }),
        ]),
      ])
    );
  }

  public get getModel() {
    return this._gameObject.getModel;
  }

  public getPlanckObject() {
    return this._gameObject.getPlanckObject();
  }

  public getThreeObject() {
    return this._gameObject.getThreeObject();
  }

  public getInitialPosition() {
    return this._initialPosition;
  }

  public setInitialPosition(x: number, y: number) {
    return this._initialPosition.set(x, y);
  }

  public getInitialAngle() {
    return this._initialAngle;
  }

  public setInitialAngle(a: number) {
    this._initialAngle = a;
  }

  deactivatePlanckObject() {
    this._gameObject.deactivatePlanckObject();
  }

  activatePlanckObject() {
    this._gameObject.deactivatePlanckObject();
  }
}
