import * as THREE from "three";

import * as chip from "booyah/dist/chip";

import * as aLoaders from "./loaders/audioLoaders";
import * as mLoaders from "./loaders/modelLoaders";

import * as gameObject from "./gameObject";
import * as net from "./net";
import * as responsive from "./responsive";
import * as threeObject from "./threeObject";
import * as utils from "./utils";

export class NetUpgradeFX extends responsive.ResponsiveChip {
  private _hammer!: threeObject.ThreeObject;
  private _innerImpact!: threeObject.ThreeObject;
  private _outerImpact!: threeObject.ThreeObject;
  private _soundSequence!: chip.Sequence;

  protected _onActivate(): void {
    // Hammer animation
    let model = mLoaders.commonModelAssets.getModelInfo("HAMMER_ANIM");
    utils.makeAnimatedMaterialFloating(model);
    this._hammer = new threeObject.ThreeObject(model, this.chipContext.scene);
    // Terminate chip when animations are complete
    this._hammer.addListener(
      "animationComplete",
      this._modelAnimationFinished.bind(this)
    );

    // Inner impact animation
    model = mLoaders.commonModelAssets.getModelInfo("HAMMER_FX_IN");
    utils.makeAnimatedMaterialFloating(model);
    this._innerImpact = new threeObject.ThreeObject(
      model,
      this.chipContext.scene
    );
    // Terminate chip when animations are complete
    this._innerImpact.addListener(
      "animationComplete",
      this._modelAnimationFinished.bind(this)
    );

    // Outer impact animation
    model = mLoaders.commonModelAssets.getModelInfo("HAMMER_FX_OUT");
    utils.makeAnimatedMaterialFloating(model);
    this._outerImpact = new threeObject.ThreeObject(
      model,
      this.chipContext.scene
    );
    // Terminate chip when animations are complete
    this._outerImpact.addListener(
      "animationComplete",
      this._modelAnimationFinished.bind(this)
    );

    // Sound FX
    this._soundSequence = new chip.Sequence([
      new chip.Wait(500),
      new chip.Lambda(() => aLoaders.playFx("hammer")),
      new chip.Wait(600),
      new chip.Lambda(() => aLoaders.playFx("hammer")),
      new chip.Wait(600),
      new chip.Lambda(() => aLoaders.playFx("hammer")),
    ]);

    // Activate all chips
    this._activateChildChip(this._hammer);
    this._activateChildChip(this._innerImpact);
    this._activateChildChip(this._outerImpact);
    this._activateChildChip(this._soundSequence);

    // Start animations manually (no looping, custom delay)
    this._hammer.startAnimations();
    this._innerImpact.startAnimations();
    this._outerImpact.startAnimations(THREE.LoopOnce, 1, 0.75);

    // Listen to game over so we can stop the sequence
    this._subscribe(this.chipContext.level, "gameOver", () => {
      this.terminate();
    });
  }

  protected _onTick(): void {
    // Get net object
    const netObject = this.chipContext.level.boat.net
      .gameObject as gameObject.GameObject;
    // Copy position
    const netPos = netObject.getPosition();
    const pos = new THREE.Vector3(netPos.x, 0, netPos.y);
    this._hammer.setPosition(pos);
    this._innerImpact.setPosition(pos);
    this._outerImpact.setPosition(pos);
    // Copy angle + 60° CCW
    const netAngle = netObject.getAngle() + Math.PI / 3;
    this._hammer.setAngle(netAngle);
    this._innerImpact.setAngle(netAngle);
    this._outerImpact.setAngle(netAngle);
    // Copy scale
    const netScale = netObject.getThreeObject().getScale();
    this._hammer.setScale(netScale.x, netScale.y, netScale.z);
    this._innerImpact.setScale(netScale.x, netScale.y, netScale.z);
    this._outerImpact.setScale(netScale.x, netScale.y, netScale.z);
  }

  private _modelAnimationFinished() {
    const count =
      this._hammer.getRunningAnimationsCount() +
      this._innerImpact.getRunningAnimationsCount() +
      this._outerImpact.getRunningAnimationsCount();

    if (count === 0) {
      // Bubble text feedback
      const net = this.chipContext.level.boat.net as net.Net;
      net.itemsCountBubble.show(this.chipContext.extraCapacity, true);
      this.terminate();
    }
  }
}
