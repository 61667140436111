import random from "random";
import * as _ from "underscore";

import * as PIXI from "pixi.js";

import * as booyahPixi from "booyah-pixi/dist/booyahPixi";
import * as chip from "booyah/dist/chip";
import * as booyahUtil from "booyah/dist/util";

import * as tLoaders from "./loaders/textureLoaders";

import * as collectable from "./collectable";
import * as constants from "./constants";
import * as button from "./gui/components/button";
import * as popup from "./gui/components/popup";
import * as level from "./level";
import * as params from "./params";
import * as responsive from "./responsive";
import * as save from "./save";
import * as seaMap from "./seaMap";
import * as unlock from "./unlock";
import * as utils from "./utils";

const defaultTrashPerTile = () => Math.round(Math.random() * 10);

const difficultySettings = {
  easy: {
    includeBonusFraction: 1 / 5, // Chances to keep a bonus slot (fixed or random) active instead of removing it from the map
    includeCoinFraction: 1 / 7,
    animalCountFractions: {
      3: 0.025,
      2: 0.1,
      1: 0.225,
    },
  },
  medium: {
    includeBonusFraction: 1 / 9,
    includeCoinFraction: 1 / 12,
    animalCountFractions: {
      3: 0.05,
      2: 0.175,
      1: 0.375,
    },
  },
  hard: {
    includeBonusFraction: 1 / 12,
    includeCoinFraction: 1 / 15,
    animalCountFractions: {
      3: 0.2,
      2: 0.4,
      1: 0.25,
    },
  },
};

type LevelOptionsModifier = (
  levelOptions: level.LevelOptions,
  radiusIncrement: number
) => level.LevelOptions;

const levelOptionsModifiers: Record<string, LevelOptionsModifier> = {
  trashType: (levelOptions, radiusIncrement) => {
    const trashName =
      collectable.trashes[
        utils.weightedRandomInt(collectable.trashes.length, 1.5, true, true)
      ];
    const trashCount = (radiusIncrement + 1) * random.int(3, 6);
    const minTrashCount = {
      [trashName]: trashCount,
    };

    levelOptions.lootObjectives = minTrashCount;
    levelOptions.seaMapOptions.minTrashCount = minTrashCount;

    return levelOptions;
  },
  quantity: (levelOptions, radiusIncrement) => {
    const trashCount = (radiusIncrement + 1) * random.int(10, 20);
    levelOptions.lootObjectives = { TOTAL: trashCount };
    return levelOptions;
  },
};

const tutorialFactories: Partial<
  Record<collectable.BonusName, () => chip.ChipResolvable>
> = {
  BONUS_SPEED: () => {
    // // TODO: remove this animation if we don't have enough space?
    // const animOptions = {
    //   animationName: "press",
    //   fps: 30,
    //   scale: 0.7,
    //   anchor: { x: 0.65, y: 0.7 },
    // };
    // // Setup the sequence of animations to display in the popup
    // const tutoAnimSequence = new chip.Sequence(
    //   [
    //     new booyahPixi.AnimatedSpriteChip(
    //       tLoaders.UIAnimationAssets.getSpritesheet("Press_0"),
    //       animOptions
    //     ),
    //     new booyahPixi.AnimatedSpriteChip(
    //       tLoaders.UIAnimationAssets.getSpritesheet("Press_1"),
    //       animOptions
    //     ),
    //     new booyahPixi.AnimatedSpriteChip(
    //       tLoaders.UIAnimationAssets.getSpritesheet("Press_2"),
    //       animOptions
    //     ),
    //     new booyahPixi.AnimatedSpriteChip(
    //       tLoaders.UIAnimationAssets.getSpritesheet("Press_3"),
    //       animOptions
    //     ),
    //   ],
    //   { loop: true }
    // );

    return (chipContext: chip.ChipContext) =>
      new chip.Sequence([
        // Wait for them to get the bonus
        new chip.WaitForEvent(
          chipContext.powerupManager,
          "acquiredPowerup",
          (p) => p === "BONUS_SPEED"
        ),

        // Wait for them to use the bonus, then close the popup
        new chip.Alternative([
          new chip.WaitForEvent(
            chipContext.powerupManager,
            "using",
            (p) => p === "BONUS_SPEED"
          ),

          new chip.Sequence(
            [
              new popup.BottomPopup({
                text: "Use the boost by clicking the icon or pressing the 1 key",
                timeBeforeClose: -1,
                // childChip: tutoAnimSequence,
              }),
            ],
            { terminateOnCompletion: false }
          ),
        ]),

        // Wait until the boost is over
        new chip.Wait(constants.speedBoostDuration),
      ]);
  },

  BONUS_TIME: () => {
    return (chipContext: chip.ChipContext) =>
      new chip.Sequence([
        // Wait for them to get the bonus
        new chip.WaitForEvent(
          chipContext.powerupManager,
          "acquiredPowerup",
          (p) => p === "BONUS_TIME"
        ),

        new popup.BottomPopup({
          text: "This bonus gives you extra time!",
          icon: "Clock",
        }),

        // Wait a bit
        new chip.Wait(2000),
      ]);
  },

  BONUS_SHIELD: () => {
    return (chipContext: chip.ChipContext) =>
      new chip.Sequence([
        // Wait for them to get the bonus
        new chip.WaitForEvent(
          chipContext.powerupManager,
          "acquiredPowerup",
          (p) => p === "BONUS_SHIELD"
        ),

        new popup.BottomPopup({
          text: "This bonus protects you from the next hit!",
          icon: "Buoy",
        }),

        // Wait a bit
        new chip.Wait(2000),
      ]);
  },

  BONUS_CHEST: () => {
    return (chipContext: chip.ChipContext) =>
      new chip.Sequence([
        // Wait for them to get the bonus
        new chip.WaitForEvent(
          chipContext.powerupManager,
          "acquiredPowerup",
          (p) => p === "BONUS_CHEST"
        ),

        new popup.BottomPopup({
          text: "This bonus gives you a lot of money!",
          icon: "Money", //TODO New icon
        }),

        // Wait a bit
        new chip.Wait(2000),
      ]);
  },

  BONUS_REPAIR: () => {
    return (chipContext: chip.ChipContext) =>
      new chip.Sequence([
        // Wait for them to get the bonus
        new chip.WaitForEvent(
          chipContext.powerupManager,
          "acquiredPowerup",
          (p) => p === "BONUS_REPAIR"
        ),

        new popup.BottomPopup({
          text: "This bonus completely repairs your net!",
          icon: "Buoy", //TODO New icon
        }),

        // Wait a bit
        new chip.Wait(2000),
      ]);
  },
};

export class LevelRun extends chip.Composite {
  private _level!: level.Level;
  private _completedLevelCount!: number;

  protected _onActivate(): void {
    this._completedLevelCount = params.getStartingLevel() - 1;

    const stateMachine = new chip.StateMachine(
      {
        level: () => this._makeLevel(),
        gameOver: this._makeGameOver(),
      },
      {
        signals: {
          level: chip.makeSignalTable({
            success: () => {
              this._completedLevelCount++;
              return chip.makeSignal("level");
            },
            failure: "gameOver",
          }),
        },
        startingState: "level",
      }
    );
    this._activateChildChip(stateMachine);

    console.log("Starting new run");
  }

  private _makeLevel() {
    const levelOptions = this._makeLevelOptions();

    // Store current level in save
    const saveManager = this.chipContext.saveManager as save.SaveManager;
    saveManager.currentLevel = levelOptions.levelNumber;

    return new level.Level(levelOptions);
  }

  private _makeLevelOptions(): level.LevelOptions {
    const saveManager = this.chipContext.saveManager as save.SaveManager;

    // Allow the params to override the saved game
    const unlockedBonuses = params.shouldUnlockAllBonuses()
      ? collectable.bonusDiscoveryOrder
      : saveManager.unlockedBonuses;
    const unlockedDropZones = params.shouldUnlockAllBonuses()
      ? collectable.bonusDiscoveryOrder
      : saveManager.unlockedDropZones;

    // The radius starts at 3, and grows by 1 every 3 levels
    const radiusIncrement = Math.floor(this._completedLevelCount / 3);
    const radius = 3 + radiusIncrement;

    // Decide what unlocks, keys, and bonus islands should be included
    let includeKey = false;
    let includeUnlock: collectable.UnlockName | undefined;
    let includeTile: collectable.BonusName | undefined;
    let bonusToDiscover: collectable.BonusName | undefined;
    let tutorial: chip.ChipResolvable | undefined;
    if (saveManager.getBonusToDiscover()) {
      // Discover a new bonus
      bonusToDiscover =
        saveManager.getBonusToDiscover() as collectable.BonusName;
      includeTile = bonusToDiscover;

      if (tutorialFactories[bonusToDiscover]) {
        tutorial = tutorialFactories[bonusToDiscover]!();
      }
    } else {
      includeKey = !!unlock.getNextDropZoneToUnlock(
        unlockedBonuses,
        unlockedDropZones
      );

      if (unlock.getNextBonusToUnlock(unlockedBonuses)) {
        includeUnlock = collectable.makeUnlockForBonus(
          unlock.getNextBonusToUnlock(unlockedBonuses) as collectable.BonusName
        );
      }
    }

    // Pick unique islands to spawn depending on what has already been unlocked
    let uniqueTilesToInclude = [];
    // Debug param: force all unique islands
    if (params.spawnAllIslands()) {
      uniqueTilesToInclude = [...collectable.bonusDiscoveryOrder]; // Clone
    }
    // First check if we did unlock any bonuses
    else {
      if (includeTile) uniqueTilesToInclude.push(includeTile);

      if (unlockedBonuses.length > 0) {
        // Clone the array since we will be modifying it
        let availableBonuses = [...unlockedBonuses];
        // Each radius increment grants one unique island
        for (let i = 0; i < radiusIncrement; i++) {
          // Get a weighted random int to use as the array index for picking an island
          const weightedIndex = utils.weightedRandomInt(
            availableBonuses.length,
            1.5,
            true,
            true
          );
          const picked = availableBonuses[weightedIndex];
          // Push it to the list
          uniqueTilesToInclude.push(picked!);
          // Remove it from the available ones
          availableBonuses = _.without(availableBonuses, picked);
        }
      }
    }

    // Get difficulty
    // TODO: this should come from player experience or progress in the game
    const difficulty = params.getDifficulty() ?? "easy";
    console.log("Difficulty", difficulty);

    // Default map options
    const seaMapOptions: seaMap.SeaMapOptions = {
      radius,
      emptyCellFraction: 0,
      bonusesToInclude: unlockedBonuses as collectable.BonusName[],
      uniqueTilesToInclude,
      dropZonesToInclude: saveManager.unlockedDropZones,
      animalCountFractions: difficultySettings[difficulty].animalCountFractions,
      includeBonusFraction: difficultySettings[difficulty].includeBonusFraction,
      includeCoinFraction: difficultySettings[difficulty].includeCoinFraction,
      includeKey,
      includeUnlock,
      minTrashCount: {},

      // TODO: customize based on difficulty?
      trashPerTile: defaultTrashPerTile,
    };
    let levelOptions: level.LevelOptions = {
      levelNumber: this._completedLevelCount + 1,
      seaMapOptions,
      bonusToDiscover,
      tutorial,

      // Will be overriden by the chosen level objective modifier
      lootObjectives: {},

      // TODO: customize based on difficulty?
      duration: constants.defaultTimePerLevel,
    };

    // Determine allowed objectives
    let objectiveNames: string[];
    if (this._completedLevelCount === 0) {
      // Only allow quantity objective to start
      objectiveNames = ["quantity"];
    } else {
      objectiveNames = Object.keys(levelOptionsModifiers);
    }

    // Pick a random objective
    const objectiveName = booyahUtil.randomArrayElement(objectiveNames);
    levelOptions = levelOptionsModifiers[objectiveName](
      levelOptions,
      radiusIncrement
    );

    console.log("levelOptions", levelOptions);

    return levelOptions;
  }

  private _makeGameOver() {
    // TODO: have a different game over screen
    return new chip.Lambda(() => this.terminate());
  }

  get defaultChildChipContext(): chip.ChipContextResolvable {
    return {
      level: this._level,
    };
  }
}

export class LevelObjectiveStartPopupOptions {
  levelNumber!: number;
  description!: string;
  icon?: tLoaders.TextureAssetName;
  duration?: number;
}

export class LevelObjectiveStartPopup extends responsive.ResponsiveChip {
  readonly width = 700;
  readonly height = 320;

  private _options: LevelObjectiveStartPopupOptions;

  private _container?: PIXI.Container;

  constructor(options: Partial<LevelObjectiveStartPopupOptions>) {
    super();

    this._options = chip.fillInOptions(
      options,
      new LevelObjectiveStartPopupOptions()
    );
  }

  protected _onActivate(): void {
    this._container = new PIXI.Container();
    this.chipContext.container.addChild(this._container);

    const bg = new popup.PopupBackground({
      width: this.width,
      height: this.height,
      verticalMargin: constants.popupInGameVerticalMargin,
    });

    this._activateChildChip(bg, {
      context: {
        container: this._container,
      },
    });

    {
      const headerBg = tLoaders.UINineSlicePlaneAssets.getNineSlice(
        "BG_Score",
        {
          x: 300,
          y: 60,
        }
      );
      headerBg.position.set((this.width - 300) / 2, -25);
      bg.content.addChild(headerBg);

      const header = new PIXI.Text(`LEVEL ${this._options.levelNumber}`, {
        fontSize: 40,
        fill: 0xffffff,
        fontFamily: "Hvd Comic Serif Pro",
      });
      header.anchor.set(0.5, 0.45);
      header.position.set(this.width / 2, 0);
      bg.content.addChild(header);
    }

    if (this._options.icon) {
      const icon = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture(this._options.icon)
      );
      icon.anchor.set(0.5);
      utils.resizeSprite(icon, 75, 75);
      icon.position.set(this.width / 2 - 150, 100);
      bg.content.addChild(icon);
    }

    {
      let descriptionText = this._options.description;
      if (this._options.duration) {
        descriptionText += " in...";
      }

      const description = new PIXI.Text(descriptionText, {
        fontSize: 30,
        fill: 0x000000,
        fontFamily: "Hvd Comic Serif Pro",
        wordWrap: true,
        wordWrapWidth: this.width / 2,
      });

      description.anchor.set(0, 0.5);
      description.position.set(this.width / 2 - 100, 100);
      bg.content.addChild(description);
    }

    if (this._options.duration) {
      // Get just one frame of the animation
      const timerAnimation = new booyahPixi.AnimatedSpriteChip(
        PIXI.Assets.get("ui"),
        {
          animationName: "timer/timer",
          anchor: { x: 1, y: 0.5 },
          position: { x: this.width / 2 - 20, y: 190 + 5 },
          scale: { x: 0.3, y: 0.3 },
          startingFrame: 6,
          behaviorOnStart: "stop",
        }
      );
      this._activateChildChip(timerAnimation, {
        context: { container: bg.content },
      });

      const clockText = new PIXI.Text(
        utils.formatTime(this._options.duration),
        {
          fontFamily: "LuckiestGuy",
          fontSize: 50,
          strokeThickness: 10,
          fill: constants.orange,
          stroke: constants.almostBlack,
        }
      );

      clockText.anchor.set(0, 0.5);
      clockText.position.set(this.width / 2 - 10, 190);
      bg.content.addChild(clockText);
    }

    {
      const startButton = new button.Button({
        text: `GO`,
        texture: "Button_Big_Green",
        large: true,
        width: 300,
        position: {
          x: this.width / 2 - 300 / 2,
          y: this.height - 70,
        },
        onClick: () => {
          this.terminate();
        },
        shortcutKey: "Enter",
      });
      this._activateChildChip(startButton, {
        context: {
          container: bg.content,
        },
      });
    }

    this.resize();
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container!);
    delete this._container;
  }
}
