import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

const fontsBundle = [
  {
    name: "Almarai",
    data: { family: "Almarai" },
    srcs: [
      new URL("../../public/fonts/Almarai_Bold.ttf", import.meta.url).pathname,
    ],
  },

  {
    name: "Hvd Comic Serif Pro",
    data: { family: "Hvd Comic Serif Pro" },
    srcs: [
      new URL("../../public/fonts/HVD_Comic_Serif_Pro.ttf", import.meta.url)
        .pathname,
    ],
  },
  {
    name: "LuckiestGuy",
    data: { family: "LuckiestGuy" },
    srcs: [
      new URL("../../public/fonts/LuckiestGuy.ttf", import.meta.url).pathname,
    ],
  },
];

export class FontLoader extends chip.Composite {
  protected _onActivate(): void {
    PIXI.Assets.addBundle("fonts", fontsBundle);

    PIXI.Assets.loadBundle("fonts", (value) =>
      this.emit("progress", value)
    ).then(() => this.terminate());
  }
}
