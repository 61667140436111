import * as PIXI from "pixi.js";

import * as booyahPixi from "booyah-pixi/dist/booyahPixi";
import * as chip from "booyah/dist/chip";

import * as aLoaders from "../../loaders/audioLoaders";

import * as constants from "../../constants";
import * as playerCamera from "../../playerCamera";
import * as responsive from "../../responsive";
import * as utils from "../../utils";

import * as popup from "./popup";

/**
 * Represent a timer with second precision.
 * Send an "end" event when the timer reach 0.
 * Send an "updated(remainingSeconds)" event when the timer is updated.
 */
export class Timer extends chip.ChipBase {
  private _lastRemainingSeconds!: number;

  get remainingTime() {
    return this._remainingTime;
  }

  set remainingTime(time: number) {
    this._remainingTime = time;

    this.emit("updated", Math.ceil(this._remainingTime / 1000));
  }

  constructor(private _remainingTime: number) {
    super();
  }

  protected _onActivate() {
    this._lastRemainingSeconds = Math.ceil(this._remainingTime / 1000);

    this.emit("updated", this._lastRemainingSeconds);
  }

  protected _onTick() {
    if (
      this.chipContext.level.isPaused ||
      this.chipContext.level.levelState !== "playing"
    )
      return;

    this._remainingTime -= this._lastTickInfo.timeSinceLastTick;
    const remainingSeconds = Math.ceil(this._remainingTime / 1000);

    if (remainingSeconds !== this._lastRemainingSeconds) {
      this._lastRemainingSeconds = remainingSeconds;

      if (remainingSeconds <= 0) {
        this.emit("end");
        this.terminate();
      } else {
        this.emit("updated", remainingSeconds);
      }
    }
  }
}

export class GameTimer extends responsive.ResponsiveChip {
  private _container!: PIXI.Container;
  private _clockText!: PIXI.Text;
  // private _clockSprite!: PIXI.AnimatedSprite;
  private _timerAnimation!: booyahPixi.AnimatedSpriteChip;
  private _timer!: Timer;

  private _baseRemainingTime: number;

  constructor(private _remainingTime: number) {
    super();

    this._baseRemainingTime = _remainingTime;
  }

  public addTime(ms: number) {
    this._timer.remainingTime += ms;

    let addText: PIXI.Text;

    this._activateChildChip(
      new chip.Functional({
        activate: () => {
          addText = new PIXI.Text(`+${Math.round(ms / 1000)}`, {
            fontFamily: "LuckiestGuy",
            fontSize: 50,
            strokeThickness: 10,
            fill: 0x00ff00,
            stroke: constants.almostBlack,
          });

          addText.anchor.set(0, 0);
          addText.x = 5;
          addText.y = 50;

          this._container.addChild(addText);
        },
        tick: () => {
          addText.y += 0.5;
          addText.alpha = Math.max(0, addText.alpha - 0.01);
        },
        terminate: () => {
          this._container.removeChild(addText);
        },
        shouldTerminate: () => addText.alpha <= 0,
      })
    );
  }

  protected _onActivate() {
    this._container = new PIXI.Container();

    // add clock text

    this._clockText = new PIXI.Text("", {
      fontFamily: "LuckiestGuy",
      fontSize: 50,
      strokeThickness: 10,
      fill: constants.orange,
      stroke: constants.almostBlack,
    });

    this._clockText.anchor.set(0, 0);
    this._clockText.x = 5;
    this._clockText.y = 10;
    this._container.addChild(this._clockText);

    // add clock sprite

    // this._clockSprite = tLoaders.UIAnimationAssets.getAnimation("Timer", {
    //   anchor: { x: 1, y: 0 },
    //   position: { x: -5, y: 25 },
    //   scale: { x: 0.3, y: 0.3 },
    //   startFrame: 24,
    //   animationSpeed: 0,
    //   loop: false,
    // });

    // this._container.addChild(this._clockSprite);

    // We will manually control this animation
    this._timerAnimation = new booyahPixi.AnimatedSpriteChip(
      PIXI.Assets.get("ui"),
      {
        animationName: "timer/timer",
        anchor: { x: 1, y: 0 },
        position: { x: -5, y: 25 },
        scale: { x: 0.3, y: 0.3 },
        startingFrame: 24,
        behaviorOnStart: "stop",
        behaviorOnComplete: "keepLastFrame",
      }
    );
    this._activateChildChip(this._timerAnimation, {
      context: { container: this._container },
    });

    // add timer

    this._timer = new Timer(this._remainingTime);

    this._subscribe(this._timer, "updated", (remainingSeconds: number) => {
      // update clock text
      const formattedTime = `${Math.floor(remainingSeconds / 60)
        .toString()
        .padStart(2, "0")}:${(remainingSeconds % 60)
        .toString()
        .padStart(2, "0")}`;

      // Every 30s, draw attention to the timer
      if (remainingSeconds % 30 === 0) {
        this._activateChildChip(
          utils.textEditAnimation(this._clockText, formattedTime)
        );
      } else {
        this._clockText.text = formattedTime;
      }

      // update clock animation
      this._timerAnimation.pixiSprite.currentFrame = Math.floor(
        Math.min(
          24,
          Math.max(
            0,
            24 * (remainingSeconds / (this._baseRemainingTime / 1000))
          )
        )
      );

      if (remainingSeconds === 30) {
        this._activateChildChip(new popup.DiscreteHint("30 seconds left!"));
      } else if (remainingSeconds === 10) {
        this._activateChildChip(new popup.DiscreteHint("10 seconds left!"));
      } else if (remainingSeconds === 5) {
        this._clockText.style.fill = 0xff0000;

        // todo: shake the clock

        this._activateChildChip(new popup.DiscreteHint("5 seconds left!"));
      }
    });

    this._subscribeOnce(this._timer, "end", () => {
      this._activateChildChip(
        new chip.Sequence([
          new chip.Parallel([
            utils.textEditAnimation(this._clockText, "Timeout!"),
            new chip.Wait(1000),
          ]),
          new chip.Lambda(() => {
            this.emit("timeReached");
            this.terminate();
          }),
        ])
      );
    });

    this._activateChildChip(this._timer);

    this.resize();

    this.chipContext.container.addChild(this._container);
  }

  protected _onResize(width: number) {
    this._container.x = width / 2;

    if (responsive.isMobile()) {
      this._container.scale.set(0.5);
    } else if (responsive.isTablet()) {
      this._container.scale.set(0.75);
    } else {
      this._container.scale.set(1);
    }
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container);

    // this._clockSprite.destroy();
    this._container.destroy();
  }
}

export class StartCount extends responsive.ResponsiveChip {
  private _container!: PIXI.Container;
  private _clockText!: PIXI.Text;
  private _timer!: Timer;

  constructor(private _remainingTime: number) {
    super();
  }

  protected _onActivate() {
    this._container = new PIXI.Container();

    // add clock text

    this._clockText = new PIXI.Text("", {
      fontFamily: "LuckiestGuy",
      fontSize: 150,
      strokeThickness: 20,
      fill: constants.orange,
      stroke: constants.almostBlack,
    });

    this._clockText.anchor.set(0.5);

    this._container.addChild(this._clockText);

    // add timer
    this._timer = new Timer(this._remainingTime);

    this._subscribe(this._timer, "updated", (remainingSeconds: number) => {
      // Play SFX
      aLoaders.playFx("start_countdown_tick");

      this._activateChildChip(
        utils.textEditAnimation(this._clockText, remainingSeconds.toString())
      );
    });

    this._subscribeOnce(this._timer, "end", () => {
      // Play SFX
      aLoaders.playFx("start_countdown_done");

      this.terminate();
    });

    this._activateChildChip(this._timer);

    this.resize();

    this.chipContext.container.addChild(this._container);
  }

  protected _onTick(): void {
    const t = 1 - this._timer.remainingTime / this._remainingTime;
    const cam = this.chipContext.camera as playerCamera.PlayerCamera;
    cam.startingMoveProgress = t;
  }

  protected _onResize(width: number, height: number) {
    this._container.x = width / 2;
    this._container.y = height / 2;
  }

  protected _onTerminate() {
    const cam = this.chipContext.camera as playerCamera.PlayerCamera;
    cam.startingMoveProgress = 1;

    this.chipContext.container.removeChild(this._container);

    this._container.destroy();
  }
}

export class GoHeadline extends responsive.ResponsiveChip {
  private _container!: PIXI.Container;
  private _clockText!: PIXI.Text;

  protected _onActivate() {
    this._container = new PIXI.Container();

    this._clockText = new PIXI.Text("GO!", {
      fontFamily: "LuckiestGuy",
      fontSize: 150,
      strokeThickness: 20,
      fill: constants.orange,
      stroke: constants.almostBlack,
    });

    this._clockText.anchor.set(0.5);

    this._container.addChild(this._clockText);

    this.resize();

    this.chipContext.container.addChild(this._container);

    this._activateChildChip(
      new chip.Sequence([
        new chip.Parallel([
          utils.textEditAnimation(this._clockText, "Go!"),
          new chip.Wait(1000),
        ]),
        new chip.Lambda(() => {
          this.terminate();
        }),
      ])
    );
  }

  protected _onResize(width: number, height: number) {
    this._container.x = width / 2;
    this._container.y = height / 2;
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container);

    this._container.destroy();
  }
}
