import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

import * as tLoaders from "../../loaders/textureLoaders";

import * as boat from "../../boat";
import * as constants from "../../constants";
import { BoatStats } from "../../shopData";
import * as utils from "../../utils";
import * as gauge from "../components/gauge";

import * as shopItem from "./shopItem";

export class BoatItem extends shopItem.ShopItem {
  constructor(
    public readonly name: boat.BoatName,
    price: number,
    private _stats: BoatStats,
    behavior: "change" | "equip"
  ) {
    super({
      price,
      name: `${name} Boat`,
      isNew: false, // todo: implement this after the alpha release
      image: `Boat-${name}`,
      behavior,
    });
  }

  protected _isBought() {
    return this.chipContext.saveManager.isBoatBought(this.name);
  }

  protected _isSelected(): boolean {
    return this.chipContext.saveManager.selectedBoat === this.name;
  }

  protected _onBuy() {
    this.chipContext.saveManager.buyBoat(this.name);
  }

  protected _onSelect() {
    this.chipContext.saveManager.selectBoat(this.name);
  }

  protected _canSelect(): boolean {
    return true;
  }

  protected _onUpgrade() {
    // TODO: implement this after the alpha release
  }

  protected _canUpgrade(): boolean {
    return false;
  }

  protected _onActivate() {
    super._onActivate();

    const badgeContainer = new PIXI.Container();

    badgeContainer.position.set(shopItem.backgroundSize.x - 180, 70);

    this._background.addChild(badgeContainer);

    const health = new PIXI.Sprite(tLoaders.UITextureAssets.getTexture("Buoy"));

    health.anchor.set(0.5);
    health.position.set(health.width / 2, health.height / 2);

    badgeContainer.addChild(health);

    const healthText = new PIXI.Text(boat.getBoatHp(this.name), {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 28,
      fill: constants.principalColor,
    });

    healthText.anchor.set(0.5);

    health.addChild(healthText);
  }

  protected _makeState(state: shopItem.ShopItemStates) {
    const statContainer = new PIXI.Container();

    let y = 75;

    for (const stat of Object.keys(this._stats)) {
      const value = this._stats[stat]; // todo: add value of saved upgrades after the alpha

      const statText = new PIXI.Text(utils.capitalize(stat), {
        fontFamily: "Almarai",
        fontSize: 22,
        fill: state !== "toBuy" ? constants.principalColor : 0xffffff,
      });

      statText.anchor.set(0, 0.3);
      statText.position.x = shopItem.backgroundSize.y;
      statText.position.y = y;

      statContainer.addChild(statText);

      const statBar = new gauge.Gauge(
        500,
        state === "toBuy" ? "beige" : "white",
        state === "toBuy" ? "white" : "red",
        value
      );

      this._activateChildChip(statBar, {
        context: { container: statContainer },
      });

      statBar.bar.position.x = shopItem.backgroundSize.y + 150;
      statBar.bar.position.y = y;

      // if (state === "upgrade") {
      //   const upgradeButton = new PIXI.Sprite(
      //     tLoaders.UITextureAssets.getTexture("Plus")
      //   )
      //
      //   upgradeButton.anchor.set(0.5)
      //   upgradeButton.scale.set(1.5)
      //   upgradeButton.position.x = statBar.ui.width
      //   upgradeButton.position.y = statBar.ui.height / 2
      //
      //   this._subscribe(upgradeButton, "pointertap", () => {
      //     // todo: stock the upgrades in a buffer, and wait the player to confirm by clicking on the "Done" button
      //   })
      //
      //   statBar.ui.addChild(upgradeButton)
      // }

      y += 27;
    }

    return new chip.Parallel([
      super._makeState(state),
      new chip.Functional({
        activate: () => {
          this._background.addChild(statContainer);
        },
        terminate: () => {
          this._background.removeChild(statContainer);
          statContainer.destroy();
        },
      }),
    ]);
  }
}

export const boatItems = boat.boats.map(
  (options) => new BoatItem(options.name, options.price, options.stats, "equip")
);
