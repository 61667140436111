// @ts-ignore
import boatFrag from "../public/shaders/boat.frag";
// @ts-ignore
import floatingBoatVert from "../public/shaders/floatingBoat.vert";
import shopDataJSON from "./shop.json";

import * as PLANCK from "planck-js";
import * as THREE from "three";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";
import * as THREEMath from "three/src/math/MathUtils";

import * as chip from "booyah/dist/chip";
import * as geom from "booyah/dist/geom";
import * as tween from "booyah/dist/tween";
import * as booyahUtil from "booyah/dist/util";

import * as aLoaders from "./loaders/audioLoaders";
import * as mLoaders from "./loaders/modelLoaders";

import * as boatSpeedFX from "./boatSpeedFX";
import * as collectable from "./collectable";
import * as constants from "./constants";
import * as environment from "./environment";
import * as gameObject from "./gameObject";
import * as net from "./net";
import * as planckObject from "./planckObject";
import * as playerCamera from "./playerCamera";
import * as powerup from "./powerup";
import * as seaMap from "./seaMap";
import { BoatStats, ShopData } from "./shopData";
import * as threeObject from "./threeObject";
import * as unlock from "./unlock";
import * as utils from "./utils";

const shopData: ShopData = shopDataJSON as ShopData;
export const boats = shopData.boats;

export type BoatName = (typeof boats)[number]["name"];

/**
 * Gives the ratio of the stat of the boat (from 1 for low ... to 2 for high)
 * @param statName
 */
export function getBoatStat(boatName: BoatName, statName: keyof BoatStats) {
  return boats.find((boat) => boatName === boat.name)!.stats[statName];
}

export function getBoatHp(boatName: BoatName) {
  return Math.ceil(
    getBoatStat(boatName, "durability") * constants.boatMaxHealth
  );
}

export function getHitbox(boatName: BoatName) {
  const boatData = boats.find((boat) => boatName === boat.name);
  return boatData
    ? new THREE.Vector2(boatData.hitBox.width, boatData.hitBox.height)
    : new THREE.Vector2(1, 1);
}

export const boatMaterialUniforms = {
  uTime: { value: 0 },
  justTookDamage: { value: false },
  usingShieldBonus: { value: false },
  speedBoostFraction: { value: 0 },
};

function applyBoatMaterial(model: THREE.Group, flags = "") {
  model.position.y = 0;
  model.traverse((element) => {
    if (element instanceof THREE.Mesh) {
      const material = new CustomShaderMaterial({
        // silent: true,
        baseMaterial: element.material,
        fragmentShader: flags + boatFrag,
        vertexShader: flags + utils.shaderWavesFunctions + floatingBoatVert,
        uniforms: boatMaterialUniforms,
      });
      element.material = material;
    }
  });
}

export class Boat extends chip.Composite {
  private readonly _anchor1Boat: PLANCK.Vec2 = PLANCK.Vec2(0, 0);
  private readonly _anchor2Boat: PLANCK.Vec2 = PLANCK.Vec2(0, 0);

  /// private fields ///
  private _elapsedTime!: number;

  private _gameObject!: gameObject.GameObject;

  private _isMovementPrevented = false;
  private _stunned = false;
  private _canTakeDamage = true;

  private _joint1!: PLANCK.RopeJoint;
  private _joint2!: PLANCK.RopeJoint;

  private _anchor1Net!: PLANCK.Vec2;
  private _anchor2Net!: PLANCK.Vec2;

  // private _netLine1!: THREE.Line;
  // private _netLine2!: THREE.Line;

  private _net!: net.Net;
  private _netArm!: THREE.Group;
  private _netAttachement!: THREE.Group;

  private _buoys!: THREE.Group;

  private _waterFx!: THREE.PositionalAudio;

  private _boatSpeedFX?: boatSpeedFX.BoatSpeedFX;

  private _lastSpeedBoostFraction!: number;

  constructor(
    private _map: seaMap.SeaMap,
    private _boatName: BoatName,
    private _netName: net.NetName
  ) {
    super();
  }

  get defaultChildChipContext() {
    return {
      boat: this,
    };
  }

  protected _onActivate(): void {
    this._elapsedTime = 0;
    this._lastSpeedBoostFraction = 0;

    this._boatSpeedFX = new boatSpeedFX.BoatSpeedFX();
    this._activateChildChip(this._boatSpeedFX);

    // Get boat hitbox
    const hitbox = getHitbox(this._boatName);

    // The boats are centered around their back. Push the collider boxes forward to match the model
    const boatBodyCollider = new PLANCK.Box(
      hitbox.x,
      hitbox.y,
      new PLANCK.Vec2(0, 0.5 * hitbox.y)
    );
    const boatFrontCollider = new PLANCK.Circle(
      new PLANCK.Vec2(0, 2 * hitbox.y),
      hitbox.x
    );

    this._gameObject = new gameObject.GameObject(
      mLoaders.commonModelAssets.getModelInfo(
        `BOAT_${utils.uppercase(this._boatName)}`
      ),
      this.chipContext.scene,
      {
        userData: this,
        fixtures: [
          {
            shape: boatBodyCollider,
            density: 1,
            friction: constants.boatFriction,
            restitution: constants.boatRestitution,
            filterCategoryBits: planckObject.GameObjectMask.Player,
            filterMaskBits:
              planckObject.GameObjectMask.Collectable_sensor |
              // planckObject.GameObjectMask.Collectable |
              planckObject.GameObjectMask.Obstacle |
              planckObject.GameObjectMask.Player,
          },
          {
            shape: boatFrontCollider,
            density: 0.5,
            friction: constants.boatFriction,
            restitution: constants.boatRestitution,
            filterCategoryBits: planckObject.GameObjectMask.Player,
            filterMaskBits:
              planckObject.GameObjectMask.Collectable_sensor |
              // planckObject.GameObjectMask.Collectable |
              planckObject.GameObjectMask.Obstacle |
              planckObject.GameObjectMask.Player,
          },
        ],
        bodyDef: {
          type: "dynamic",
          position: PLANCK.Vec2(0, 0),
          // angle: Math.PI / 2,
          linearDamping: 1.0,
          angularDamping: constants.boatLowDamping,
          fixedRotation: false,
          allowSleep: false,
        },
      },
      this.chipContext.world,
      0x0000ff
    );
    this._activateChildChip(this._gameObject);

    // Start facing perpendicular to the island
    this._gameObject.setAngle(-Math.PI / 2);

    boatMaterialUniforms.justTookDamage.value = false;

    this._waterFx = aLoaders.getFxSound("boat_loop") as THREE.PositionalAudio;
    this._waterFx.setLoop(true);

    // Net

    const netModel = net.nets.find((n) => n.name === this._netName)!.model;

    this._netArm =
      mLoaders.commonModelAssets
        .getModelInfo(`NET_ARM_${netModel}`)
        .model?.scene.clone() ?? new THREE.Group();

    this._netAttachement =
      mLoaders.commonModelAssets
        .getModelInfo(`NET_${netModel}_PART_1`)
        .model?.scene.clone() ?? new THREE.Group();

    const model = this.getModel;
    if (model) {
      applyBoatMaterial(model as THREE.Group);

      model.add(this._waterFx);

      if (this._netArm) {
        applyBoatMaterial(this._netArm, "\n#define IS_NET_PART_1\n");
        model.add(this._netArm);
      }

      if (this._netAttachement) {
        applyBoatMaterial(this._netAttachement, "\n#define IS_NET_PART_1\n");
        model.add(this._netAttachement);
      }
    }

    this._buoys =
      mLoaders.commonModelAssets
        .getModelInfo(`BOAT_${utils.uppercase(this._boatName)}_BUOYS`)
        .model?.scene.clone() ?? new THREE.Group();
    if (this._buoys) {
      applyBoatMaterial(this._buoys as THREE.Group);
    }

    this._net = new net.Net(this._netName);

    this._activateChildChip(this._net);

    this._subscribe(this._net, "resize", this._onNetResize);

    // Setting initial net position

    const pos = this.getPosition().clone();
    const dir = new THREE.Vector2(
      Math.sin(this.getAngle()),
      Math.cos(this.getAngle())
    );
    pos.add(dir.multiplyScalar(this._net.getSize() * -3));
    this._net.gameObject.setPosition(pos.x, pos.y);
    this._net.gameObject.setAngle(geom.degreesToRadians(-90));

    const attachPos1 = new PLANCK.Vec2(
      this._net.getSize() * Math.sin(Math.PI / 4),
      this._net.getSize() * Math.cos(Math.PI / 4)
    );

    const attachPos2 = new PLANCK.Vec2(
      this._net.getSize() * Math.sin(-Math.PI / 4),
      this._net.getSize() * Math.cos(-Math.PI / 4)
    );

    this._anchor1Net = attachPos1;
    this._anchor2Net = attachPos2;

    const netBody = this._net.getBody();
    if (this.getPlanckObject().body && netBody) {
      this._joint1 = PLANCK.RopeJoint({
        maxLength: this.netDist,
        bodyA: this.getPlanckObject().body!,
        bodyB: netBody,
        localAnchorA: this._anchor1Boat,
        localAnchorB: this._anchor2Net,
        collideConnected: true,
      });

      this._joint2 = PLANCK.RopeJoint({
        maxLength: this.netDist,
        bodyA: this.getPlanckObject().body!,
        bodyB: netBody,
        localAnchorA: this._anchor2Boat,
        localAnchorB: this._anchor1Net,
        collideConnected: true,
      });
      this.chipContext.world.createJoint(this._joint1);
      this.chipContext.world.createJoint(this._joint2);
    }

    // const line_mat1 = new THREE.LineBasicMaterial({
    //   color: 0xff0000,
    //   linewidth: 2,
    // });

    // const line_mat2 = new THREE.LineBasicMaterial({
    //   color: 0x00ff00,
    //   linewidth: 2,
    // });

    // const points1 = [new THREE.Vector3(0, 1, 0), new THREE.Vector3(0, 0, 0)];

    // const points2 = [new THREE.Vector3(0, 1, 0), new THREE.Vector3(0, 0, 0)];

    // const geometry1 = new THREE.BufferGeometry().setFromPoints(points1);
    // const geometry2 = new THREE.BufferGeometry().setFromPoints(points2);

    // this._netLine1 = new THREE.Line(geometry1, line_mat1);
    // this._netLine2 = new THREE.Line(geometry2, line_mat2);

    // this._scene.add(this._netLine1);
    // this._scene.add(this._netLine2);

    this._subscribe(this._net, "dropItem", (item: collectable.Collectable) => {
      this.emit("dropItem", item);
    });

    // Setup handlers for powerup events
    {
      this._subscribe(
        this.chipContext.powerupManager,
        "using",
        (p: powerup.Powerup) => {
          switch (p) {
            case "BONUS_SPEED": {
              aLoaders.playFx("speed_trigger");
              aLoaders.playFx("speed");

              break;
            }

            case "BONUS_REPAIR": {
              this.chipContext.playerInfo.netHp = net.getNetHp(
                this.net.netName
              );
              this.net.invincibilityFrames();
              aLoaders.playFx("hammer");

              break;
            }

            case "BONUS_TIME": {
              this.chipContext.hud.gameTimer?.addTime(constants.timeBonus);
              aLoaders.playFx("time_bonus");

              break;
            }

            case "BONUS_SHIELD": {
              this.addBuoys();
              aLoaders.playFx("shield");
              break;
            }

            case "BONUS_MAGNET": {
              // TODO: play sound
              break;
            }

            case "BONUS_RECYCLE": {
              this.net.upgradeCapacityAndEmptyNet();
              break;
            }

            default: {
              console.warn("Unknown powerup", p);
            }
          }
        }
      );

      this._subscribe(
        this.chipContext.powerupManager,
        "stopped",
        (p: powerup.Powerup) => {
          switch (p) {
            case "BONUS_SHIELD": {
              this.removeBuoys();
              break;
            }
          }
        }
      );
    }

    this._isMovementPrevented = false;
  }

  protected _onTick(): void {
    this._elapsedTime += this._lastTickInfo.timeSinceLastTick;

    boatMaterialUniforms.uTime.value = environment.oceanTime.valueOf();
    boatMaterialUniforms.usingShieldBonus.value =
      this.chipContext.powerupManager.powerupIsInUse("BONUS_SHIELD");

    // boatMaterialUniforms.usingShieldBonus.value =
    //   this.chipContext.playerInfo.hasShieldPowerUp;
    // BoatMaterialUniform.hasSpeedPowerUp.value =
    //   this.chipContext.playerInfo.speedPowerUpStartTime != -1;

    {
      const currentSpeedBoostFraction = this.getSpeedBoostFraction();

      // The constant was achieved at 60 FPS, so we adjust the factor here (more for lower framerates, less for higher ones)
      const speedBoostFractionDelta =
        constants.speedBoostFractionDelta *
        (this._lastTickInfo.timeSinceLastTick * 0.06);
      const adjustedSpeedBoostFraction = geom.moveTowardsScalar(
        this._lastSpeedBoostFraction,
        currentSpeedBoostFraction,
        speedBoostFractionDelta
      );
      boatMaterialUniforms.speedBoostFraction.value =
        adjustedSpeedBoostFraction;

      this._lastSpeedBoostFraction = adjustedSpeedBoostFraction;
    }

    this._gameObject.updateThreeObject(Math.PI);

    // Update net attachment
    if (this._netAttachement) {
      const vecToNet = this._net.gameObject
        .getPosition()
        .clone()
        .sub(this._gameObject.getPosition());
      const angleToNet = Math.atan2(vecToNet.x, vecToNet.y);

      this._netAttachement.rotation.y = angleToNet - this.getAngle();
    }

    // Water SFX volume
    this._waterFx.setVolume(Math.min(1, this.chipContext.playerInfo.speed / 4));

    // Update sound volume depending on speed
    {
      const speedRatio = THREEMath.clamp(
        THREEMath.inverseLerp(0, this.getMaxSpeed(), this.getSpeed()),
        0,
        1
      );
      aLoaders.setFxVolume(speedRatio, "speed");
    }
  }

  public get getModel() {
    return this._gameObject.getModel;
  }

  public get gameObject(): gameObject.GameObject {
    return this._gameObject;
  }

  public getPlanckObject() {
    return this._gameObject.getPlanckObject();
  }

  public getPosition() {
    return this._gameObject.getPosition();
  }

  public getScreenPosition() {
    return this._gameObject.getScreenPosition();
  }

  public getAngle() {
    return this._gameObject.getAngle();
  }

  public get canTakeDamage() {
    return this._canTakeDamage;
  }

  public addBuoys() {
    this.getModel?.add(this._buoys);
  }

  public removeBuoys() {
    this.getModel?.remove(this._buoys);
  }

  /**
   * Applies the boat's forces based on the player's input and current state.
   * This includes applying torque, propeller force, and clamping the speed.
   * Updates the playerInfo object with the boat's position, direction, and speed.
   * If the boat is stunned, the speed in playerInfo is gradually reduced instead of snapping to create a smooth transition.
   */
  public applyForces() {
    // Apply torque to the boat's planck body based on the player's input and current speed
    if (!this._isMovementPrevented) {
      this._applyTorque();
    }
    this._clampAngularVelocity();

    // If the boat is not stunned,
    if (!this._stunned && !this._isMovementPrevented) {
      this._applyPropellerForce();
      this._clampSpeed();
    }
    // // If the boat is stunned, gradually reduce the speed in playerInfo instead of snapping to create a smooth transition
    // else if (this._stunned) {
    //   this.chipContext.playerInfo.speed *= 0.8;
    // }

    // Update playerInfo with the boat's position, direction, and speed
    const pos: PLANCK.Vec2 =
      this.getPlanckObject().body?.getPosition() ?? PLANCK.Vec2(0, 0);
    this.chipContext.playerInfo.positionX = pos.x;
    this.chipContext.playerInfo.positionY = pos.y;

    const angle: number = -(this.getPlanckObject().body?.getAngle() ?? 0);
    const dir: THREE.Vector2 = new THREE.Vector2(
      Math.sin(angle),
      Math.cos(angle)
    );
    dir.normalize();
    this.chipContext.playerInfo.directionX = dir.x;
    this.chipContext.playerInfo.directionY = dir.y;

    this.chipContext.playerInfo.speed = this.getSpeed();

    // console.log("speed", this.getSpeed(), "angular", this.getAngularVelocity());

    // todo:
    //  Causes the bow of the boat to pitch up when the speed increases and pitch down when the speed decreases.
    //  Apply this in the plankObject.

    // const model = this.getModel
    // if (model) {
    //   const speedRatio = this.getSpeed() / Boat.maxSpeed
    //
    //   const pitch = THREEMath.lerp(
    //     -Math.PI / 4,
    //     Math.PI / 4,
    //     Math.min(1, speedRatio)
    //   )
    //
    //   model.rotation.x = pitch
    // }
  }

  /**
   * Applies torque to the boat's planck body based on the player's input and current speed.
   * The torque is calculated differently depending on the player's joystick mode.
   * If the boat is stunned, the torque is not affected by the speed.
   */
  private _applyTorque() {
    const torque =
      constants.torque *
      this.chipContext.playerInfo.inputAxisX *
      getBoatStat(this._boatName, "agility");

    if (torque !== 0) {
      // Apply the torque to the boat's planck body
      this.getPlanckObject().body?.applyTorque(torque);
      this.getPlanckObject().body?.setAngularDamping(constants.boatLowDamping);
    } else {
      this.getPlanckObject().body?.setAngularDamping(constants.boatHighDamping);
    }
  }

  /**
   * Applies force to the boat's planck body based on the player's input and current speed.
   * If the player has a speed power-up, the force is doubled.
   *
   * @returns {void}
   */
  private _applyPropellerForce() {
    // Get the boat's angle and add PI to it to correct the direction
    const angle = this.getAngle() + Math.PI;

    const axisVec = new THREE.Vector2(
      this.chipContext.playerInfo.inputAxisY * Math.sin(angle),
      this.chipContext.playerInfo.inputAxisY * Math.cos(angle)
    );

    let force: THREE.Vector2;
    if (this.chipContext.playerInfo.inputAxisY > 0) {
      force = axisVec.multiplyScalar(constants.propellerForce);

      // If the player has a speed power-up,  increase the force
      if (this.chipContext.powerupManager.powerupIsInUse("BONUS_SPEED")) {
        // As we reach the end of the effect, give less and less force
        const timeLeft =
          this.chipContext.powerupManager.getTimeLeft("BONUS_SPEED");
        const scalar =
          timeLeft > constants.speedBoostFade
            ? 1
            : THREEMath.clamp(timeLeft / constants.speedBoostFade, 0, 1);
        force.multiplyScalar(scalar * constants.speedBoostForceMultiplier);
      }
    } else if (this.chipContext.playerInfo.inputAxisY < 0) {
      force = axisVec.multiplyScalar(constants.propellerForceReverse);
    } else {
      force = new THREE.Vector2();
    }

    // Take boat stats into account
    // Note: ACCELERATION SHOULD NOT BE < 0.5 (in shop.json)
    //       otherwise force will be decreased (multiplied by < 1)
    force.multiplyScalar(0.5 + getBoatStat(this._boatName, "acceleration"));

    // Apply the force to the boat's planck body
    this.getPlanckObject().body?.applyForceToCenter(
      new PLANCK.Vec2(force.x, force.y),
      true
    );
  }

  /**
   * Clamps the boat's speed based on its current velocity and whether the player has a speed power-up.
   * If the player has a speed power-up, the boat's speed is clamped to twice the maximum speed.
   * Otherwise, the boat's speed is clamped to the maximum speed.
   *
   * @returns {void}
   */
  private _clampSpeed() {
    const currentVelocity =
      this.getPlanckObject().body?.getLinearVelocity() ?? PLANCK.Vec2(0, 0);

    const maxSpeed = this.chipContext.powerupManager.powerupIsInUse(
      "BONUS_SPEED"
    )
      ? this.getBoostedMaxSpeed()
      : this.getMaxSpeed();

    // Clamp velocity to max speed
    const clampedVelocity = utils
      .vec2PlanckToThree(currentVelocity)
      .clampLength(-maxSpeed, maxSpeed);

    // Set new velocity
    this.getPlanckObject().body?.setLinearVelocity(
      new PLANCK.Vec2(clampedVelocity.x, clampedVelocity.y)
    );

    // console.log(
    //   "Current speed:",
    //   currentVelocity.length().toFixed(),
    //   "Clamped speed:",
    //   clampedVelocity.length().toFixed(),
    //   "Max possible speed:",
    //   maxSpeed.toFixed()
    // );
  }

  public getSpeed(): number {
    return this.getPlanckObject().body?.getLinearVelocity().length() ?? 0;
  }

  public getMaxSpeed(): number {
    const speedStat = getBoatStat(this._boatName, "speed");
    return constants.maxSpeed * speedStat;
  }

  public getBoostedMaxSpeed(): number {
    return this._getBoatShopData().boostedMaxSpeed || this.getMaxSpeed() * 2;
  }

  private getAngularVelocity(): number {
    return this.getPlanckObject().body?.getAngularVelocity() ?? 0;
  }

  private _clampAngularVelocity() {
    const maxVelocity =
      getBoatStat(this._boatName, "agility") * constants.maxAngularVelocity;
    const currentVelocity = this.getAngularVelocity();
    const clampedVelocity = geom.clamp(
      currentVelocity,
      -maxVelocity,
      maxVelocity
    );
    this.getPlanckObject().body?.setAngularVelocity(clampedVelocity);
  }

  /**
   * Handles the collision between the boat and an obstacle.
   * Stuns the boat and deals damage to the player if the boat is moving fast enough.
   * Bounces the boat away from the obstacle with a force based on the direction of collision and the boat's speed.
   *
   * @param {Vec2} forceDir - The direction of the collision force.
   * @param {boolean} doDamage - Whether to deal damage to the player.
   * @param {boolean} isAnimal - Whether the obstacle is an animal.
   * @returns {void}
   */
  public onCollideObstacle(
    forceDir: PLANCK.Vec2,
    doDamage: boolean,
    isAnimal = false
  ): void {
    // Fix bug where function is called before the boat is ready
    if (this.state !== "active") return;
    if (!this._canTakeDamage) return;

    // Damage or not, stun the player
    this.stun();

    // Default damage sound (will be overriden below if needed)
    let sfx: aLoaders.FxName = "hit_without_damage";
    // Default camera shake
    let shake: "hard" | "gentle" = "gentle";

    // Deal damage to the player if the boat is moving fast enough or we hit an animal
    if (doDamage && (isAnimal || this.getSpeed() > constants.minDamageSpeed)) {
      if (!this.chipContext.powerupManager.powerupIsInUse("BONUS_SHIELD")) {
        // If the player isn't using a shield power-up, deal damage to the player
        this.chipContext.playerInfo.hp = Math.max(
          this.chipContext.playerInfo.hp - 1,
          0
        );
        // Update boat HP gauge
        this.net.refreshBoatHPGauge();
        // Trigger invicibility frames
        this.invincibilityFrames();

        // Elastic scale tween on boat model
        this._activateChildChip(
          new chip.Sequence([
            new tween.Tween({
              from: 0,
              to: 1,
              easing: utils.makeCustomEaseOutElastic(0.6, 5),
              duration: Math.min(1000, constants.invincibilityFrameDuration),
              onUpdate: (v: number) => {
                const sx = booyahUtil.lerp(1, 1.3, 1 - v);
                const sz = booyahUtil.lerp(0.7, 1, v);
                this._gameObject.getThreeObject().setScale(sx, 1, sz);
              },
            }),
            // Just making sure the scale is reset to 1 after tweening
            new chip.Lambda(() => {
              this._gameObject.getThreeObject().setScale(1, 1, 1);
            }),
          ])
        );

        // Hard camera shake
        shake = "hard";

        // Pick the proper SFX
        sfx = isAnimal ? "hit_animal" : "hit_island";
      } else {
        // If the player has a shield bonus, remove it and don't deal damage to the player
        this.chipContext.powerupManager.stopPowerup("BONUS_SHIELD");

        // Pick the proper SFX
        sfx = "hit_shield";
      }
    }

    // Shake camera
    (this.chipContext.camera as playerCamera.PlayerCamera).shake(shake);

    // Play SFX
    aLoaders.playFx(sfx);
  }

  /**
   * Handles the collision between the boat and a collectable.
   * If the collectable is a power-up, it updates the playerInfo object with the corresponding power-up.
   * If the collectable is caught by the net, it sets the collectable's `caughtByNet` property to true.
   * Otherwise, it adds points to the collectable and pushes it to the boat's net.
   *
   * @param {Collectable} collectableObj - The collectable that collided with the boat.
   * @param {boolean} caughtByNet - Whether the collectable was caught by the net.
   *
   * @returns {void}
   */
  public onCollideCollectable(
    collectableObj: collectable.Collectable,
    initialPosition: THREE.Vector2
  ): void {
    // Fix bug where function is called before the boat is ready
    if (this.state !== "active") return;

    // console.log("onCollideCollectable:", collectableObj);

    if (collectableObj.isBonus()) {
      // If the collectable is a power-up, inform the PowerupManager
      if (
        powerup.powerups.includes(collectableObj.info.name as powerup.Powerup)
      ) {
        this.chipContext.powerupManager.acquirePowerup(
          collectableObj.info.name
        );
      } else {
        switch (collectableObj.info.name) {
          case "BONUS_COIN": {
            // Earn money
            this.chipContext.saveManager.earnMoney(constants.moneyCoinAmount);
            // Play SFX
            aLoaders.playFx("coin");
            // Show text feedback
            this._activateChildChip(
              this.chipContext.hud.makeMoneyFeedback(
                constants.moneyCoinAmount,
                utils.getScreenPosition(
                  new THREE.Vector3(initialPosition.x, 5, initialPosition.y),
                  this.chipContext.camera.camera
                )
              )
            );
            break;
          }

          case "BONUS_CHEST": {
            this.chipContext.saveManager.earnMoney(constants.moneyChestAmount);
            // Play SFX
            aLoaders.playFx("chest");
            // Show text feedback
            this._activateChildChip(
              this.chipContext.hud.makeMoneyFeedback(
                constants.moneyChestAmount,
                utils.getScreenPosition(
                  new THREE.Vector3(initialPosition.x, 5, initialPosition.y),
                  this.chipContext.camera.camera
                )
              )
            );
            break;
          }
        }
      }

      // Play generic bonus SFX on top of the dedicated one?
      aLoaders.playFx("bonus");

      if (
        collectable.hasSpiral(collectableObj.getType() as collectable.BonusName)
      ) {
        // SHOW BONUS FX
        const fxStartScale = 2;
        const fxEndScale = 10;

        // TODO: shouldn't we re-use an existing one?
        // Create object using bonus type
        const bonusFx = new threeObject.ThreeObject(
          collectable.makeSpiralEffectModel(
            collectableObj.getType() as collectable.BonusName
          ),
          this.chipContext.scene
        );
        bonusFx.setScale(fxStartScale, fxStartScale, fxStartScale);

        // Put the spiral and the update in alternative with the timing,
        // so that the timing will stop it
        const fxAnimationSequence = new chip.Alternative([
          new chip.Parallel([
            // The spiral
            bonusFx,
            // Updating the spiral position
            new chip.Functional({
              tick: () => {
                const hitbox = getHitbox(this._boatName);
                const pos = new THREE.Vector3(
                  this.getPosition().x -
                    Math.sin(this.getAngle()) * hitbox.height,
                  0,
                  this.getPosition().y -
                    Math.cos(this.getAngle()) * hitbox.height
                );
                bonusFx.setPosition(pos);
              },
            }),
          ]),
          new chip.Sequence([
            // Shows for 3s
            new chip.Wait(3000),
            // Scales up in 250ms
            new tween.Tween({
              from: fxStartScale,
              to: fxEndScale,
              duration: 250,
              easing: "easeInQuart",
              onUpdate: (v: number) => {
                bonusFx.setScale(v, v, v);
              },
            }),
          ]),
        ]);
        this._activateChildChip(fxAnimationSequence);
      }

      // Emit event
      this.emit("gotBonus", collectableObj.getType());
    } else if (collectableObj.isUnlock()) {
      // Play SFX
      aLoaders.playFx("coin");

      // Store the change
      const unlockManager = this.chipContext
        .unlockManager as unlock.UnlockManager;
      if (collectableObj.getType() === "UNLOCK_KEY") {
        unlockManager.acquireKey();
      } else {
        unlockManager.acquireUnlock();
      }
    } else {
      // It must be trash
      console.assert(collectableObj.isTrash());

      // If the collectable is caught by the net, set the collectable's `caughtByNet` property to true.
      collectableObj.caughtByNet = true;
      // Add the points of the collectable to playerInfo and push it to the boat's net.
      // collectable.addPoints();
      this._net.pushNetItem(collectableObj, initialPosition);
      // Play SFX
      aLoaders.playFx("points");
    }
  }

  public onDropZoneReached(tileId: string) {
    // Fix bug where function is called before the boat is ready
    if (this.state !== "active") return;

    this._net.upgradeCapacityAndEmptyNet();

    this.emit("inDropZone", tileId);
  }

  /**
   * Stuns the boat by setting the _stunned property to true.
   * @param duration  How long to stun for, in ms (0 = stay stunned until called again with a different value | -1 = unstun)
   */
  private stun(duration = 1500) {
    this._stunned = duration >= 0 ? true : false;

    if (duration > 0) {
      this._activateChildChip(
        new chip.Sequence([
          new chip.Wait(duration),
          new chip.Lambda(() => {
            this._stunned = false;
          }),
        ])
      );
    }
  }

  /**
   * Sets the boat's invincibility frames for a certain duration, during which the boat cannot take damage.
   */
  private invincibilityFrames() {
    this._canTakeDamage = false;
    boatMaterialUniforms.justTookDamage.value = true;

    this._activateChildChip(
      new chip.Sequence([
        new chip.Wait(constants.invincibilityFrameDuration),
        new chip.Lambda(() => {
          boatMaterialUniforms.justTookDamage.value = false;
          this._canTakeDamage = true;
        }),
      ])
    );
  }

  private _onNetResize() {
    // Set net distance
    this._joint1.setMaxLength(this.netDist);
    this._joint2.setMaxLength(this.netDist);

    // Update collision flags with collectables
    const filterData = {
      groupIndex: 0,
      categoryBits: planckObject.GameObjectMask.Player,
      maskBits:
        planckObject.GameObjectMask.Obstacle |
        planckObject.GameObjectMask.Player,
    };
    if (this._net.isFull()) {
      filterData.maskBits |= planckObject.GameObjectMask.Collectable;
    } else {
      filterData.maskBits |= planckObject.GameObjectMask.Collectable_sensor;
    }

    for (
      let fixture = this._gameObject.getPlanckObject().body!.getFixtureList();
      fixture;
      fixture = fixture.getNext()
    ) {
      fixture.setFilterData(filterData);
    }
  }

  private get netDist(): number {
    return THREEMath.lerp(
      constants.minNetDistance,
      constants.maxNetDistance,
      this._net.getItemCount() / this.chipContext.playerInfo.currentNetCapacity
    );
  }

  public get net() {
    return this._net;
  }

  get isMovementPrevented() {
    return this._isMovementPrevented;
  }

  set isMovementPrevented(value: boolean) {
    this._isMovementPrevented = value;
  }

  getSpeedBoostFraction() {
    if (!this.chipContext.powerupManager.powerupIsInUse("BONUS_SPEED"))
      return 0;

    // If the boat is moving backwards, don't use the effect
    const velocity = this.getPlanckObject().body!.getLinearVelocity();
    const angle = this.getPlanckObject().getAngle();
    // This is because the angles are 90 deg off of what you'd think
    const direction = new PLANCK.Vec2(-Math.sin(angle), Math.cos(angle));
    const dot = PLANCK.Vec2.dot(velocity, direction);
    if (dot <= 0) return 0;

    // Get boosted speed ratio: 0 = max regular speed and 1 = max boosted speed
    const boostedSpeedRatio = THREEMath.clamp(
      THREEMath.inverseLerp(
        this.getMaxSpeed(),
        this.getBoostedMaxSpeed(),
        this.getSpeed()
      ),
      0,
      1
    );
    // console.log(
    //   "boostedSpeedRatio",
    //   boostedSpeedRatio,
    //   "speed",
    //   this.getSpeed(),
    //   "maxSpeed",
    //   this.getMaxSpeed()
    // );

    return boostedSpeedRatio;
  }

  private _getBoatShopData() {
    return boats.find((boat) => this._boatName === boat.name)!;
  }
}
