import * as PIXI from "pixi.js";
import * as THREE from "three";
// TODO: use Stats as a separate module
import Stats from "three/examples/jsm/libs/stats.module.js";

import * as chip from "booyah/dist/chip";
import * as running from "booyah/dist/running";

import * as aLoaders from "./loaders/audioLoaders";
import * as mLoaders from "./loaders/modelLoaders";

import * as boat from "./boat";
import * as collectable from "./collectable";
import * as levelSystem from "./levelSystem";
import * as loader from "./loading";
import * as obstacle from "./obstacle";
import * as params from "./params";
import * as playerCamera from "./playerCamera";
import * as playerInfo from "./playerInfo";
import * as postProcess from "./postProcess";
import * as responsive from "./responsive";
import * as save from "./save";
import * as seaMap from "./seaMap";
import * as utils from "./utils";

PIXI.settings.RETINA_PREFIX = /@([0-9.]+)x/;

class GameSupport extends responsive.ResponsiveChip {
  private _camera!: playerCamera.PlayerCamera;
  private _renderer!: THREE.WebGLRenderer;
  private _scene!: THREE.Scene;
  private _waterScene!: THREE.Scene;

  private _postProcessScene!: THREE.Scene;
  private _postProcessOpaqueScreen!: THREE.Mesh;
  private _postProcessCamera!: THREE.OrthographicCamera;

  private _mainScreenTarget!: THREE.WebGLRenderTarget;
  private _waterSceneTarget!: THREE.WebGLRenderTarget;

  private _pRenderer!: PIXI.Renderer;
  private _pStage!: PIXI.Container;
  private _mainContainer!: PIXI.Container;
  private _uiContainer!: PIXI.Container;
  private _popupContainer!: PIXI.Container;
  private _overlayContainer!: PIXI.Container;

  private _stats!: Stats;
  private _collectablesModelHandler!: collectable.CollectableModelHandler;
  private _obstaclesModelHandler!: obstacle.ObstacleModelHandler;

  private _resolutionFactor = 1;

  private _playerInfo!: playerInfo.PlayerInfo;

  get defaultChildChipContext() {
    return {
      playerInfo: this._playerInfo,
      renderer: this._renderer,
      pRenderer: this._pRenderer,
      camera: this._camera,
      pStage: this._pStage,
      container: this._mainContainer,
      uiContainer: this._uiContainer,
      overlayContainer: this._overlayContainer,
      popupContainer: this._popupContainer,
      scene: this._scene,
      waterScene: this._waterScene,
      stats: this._stats,
    };
  }

  constructor() {
    super();
  }

  protected _onActivate(): void {
    // Setup playerInfo
    // TODO: Refactor this, it's a messy global object
    this._playerInfo = new playerInfo.PlayerInfo();

    /*
        Creating Three JS & Pixi scenes
    */
    {
      this._scene = new THREE.Scene();
      this._scene.background = new THREE.Color(0x2bd8ea);
      this._waterScene = new THREE.Scene();
      this._postProcessScene = new THREE.Scene();

      this._pStage = new PIXI.Container();
      this._mainContainer = new PIXI.Container();
      this._uiContainer = new PIXI.Container();
      this._popupContainer = new PIXI.Container();
      this._overlayContainer = new PIXI.Container();
      this._mainContainer.name = "mainContainer (0)";
      this._uiContainer.name = "uiContainer (1)";
      this._popupContainer.name = "popupContainer (2)";
      this._overlayContainer.name = "overlayContainer (3)";
      this._pStage.addChild(
        this._mainContainer,
        this._uiContainer,
        this._popupContainer,
        this._overlayContainer
      );
    }

    /*
        Creating the canvas & renderers
    */
    {
      this.chipContext.canvas.width =
        responsive.getScreenWidth() * this._resolutionFactor;
      this.chipContext.canvas.height =
        responsive.getScreenHeight() * this._resolutionFactor;

      this._renderer = new THREE.WebGLRenderer({
        canvas: this.chipContext.canvas,
        alpha: true,
        premultipliedAlpha: false,
      });
      this._renderer.setClearColor(0, 0);
      this._renderer.setPixelRatio(this._resolutionFactor); // negate the blurry effect on mobile
      console.log("WebGLRenderer capabilities", this._renderer.capabilities);

      this._pRenderer = new PIXI.Renderer({
        view: this.chipContext.canvas,
        width: responsive.getScreenWidth() * this._resolutionFactor,
        height: responsive.getScreenHeight() * this._resolutionFactor,
        context: this._renderer.getContext() as PIXI.IRenderingContext,
        autoDensity: true,
        resolution: this._resolutionFactor,
      });
    }

    /*
        Creating render targets
    */
    {
      this._mainScreenTarget = new THREE.WebGLRenderTarget(
        responsive.getScreenWidth() * this._resolutionFactor,
        responsive.getScreenHeight() * this._resolutionFactor
      );
      this._mainScreenTarget.texture.minFilter = THREE.NearestFilter;
      this._mainScreenTarget.texture.magFilter = THREE.NearestFilter;
      this._mainScreenTarget.stencilBuffer = false;
      this._mainScreenTarget.depthTexture = new THREE.DepthTexture(
        responsive.getScreenWidth() * this._resolutionFactor,
        responsive.getScreenHeight() * this._resolutionFactor
      );
      this._mainScreenTarget.depthTexture.format = THREE.DepthFormat;
      this._mainScreenTarget.texture.type = THREE.FloatType;
      this._mainScreenTarget.texture.format = THREE.RGBAFormat;

      this._waterSceneTarget = new THREE.WebGLRenderTarget(
        responsive.getScreenWidth() * this._resolutionFactor,
        responsive.getScreenHeight() * this._resolutionFactor,
        { format: THREE.RGBAFormat }
      );
      this._waterSceneTarget.texture.minFilter = THREE.NearestFilter;
      this._waterSceneTarget.texture.magFilter = THREE.NearestFilter;
      this._waterSceneTarget.stencilBuffer = false;
      this._waterSceneTarget.depthTexture = new THREE.DepthTexture(
        responsive.getScreenWidth() * this._resolutionFactor,
        responsive.getScreenHeight() * this._resolutionFactor
      );
      this._waterSceneTarget.depthTexture.format = THREE.DepthFormat;
      this._waterSceneTarget.texture.type = THREE.FloatType;
      this._waterSceneTarget.texture.format = THREE.RGBAFormat;
    }

    /*
        Creating cameras & post process sprite screen
    */
    {
      this._camera = new playerCamera.PlayerCamera(
        50,
        responsive.getScreenWidth() / responsive.getScreenHeight(),
        0.1,
        5000
      );
      this._camera.camera.add(aLoaders.sfxListener, aLoaders.musicListener); // for 3D Audio on the player camera
      this._postProcessCamera = new THREE.OrthographicCamera(
        -1,
        1,
        1,
        -1,
        0,
        1
      );
      this._postProcessOpaqueScreen = new THREE.Mesh(
        new THREE.PlaneGeometry(2, 2),
        postProcess.postProcessPass
      );
      this._postProcessScene.add(this._postProcessOpaqueScreen);
    }

    /*
        Creating other custom classes
    */
    {
      this._collectablesModelHandler = new collectable.CollectableModelHandler(
        this._scene
      );
      this._obstaclesModelHandler = new obstacle.ObstacleModelHandler(
        this._scene
      );
    }

    /*
        Defining "orientationchange" event
    */
    {
      this._subscribe(window, "orientationchange", this._onResize);
    }

    /*
      Creating a stats recorder
    */
    if (params.showStats()) {
      this._stats = new Stats();
    }

    /*
        Setting up some settings
    */
    postProcess.postProcessPass.uniforms.speedBoostFraction =
      boat.boatMaterialUniforms.speedBoostFraction;

    postProcess.postProcessPass.uniforms.uTime =
      utils.waterMaterialUniform.uTime;

    if (this._stats) document.body.appendChild(this._stats.dom);

    /*
        Activate essential model handler for colelctables & obstacles.
        Those chip will inject code inside the model's materials.
    */
    this._activateChildChip(this._collectablesModelHandler);
    this._activateChildChip(this._obstaclesModelHandler);
    this._activateChildChip(
      new mLoaders.FloatingMaterialModifier(
        mLoaders.commonModelAssets.getModelInfo("DROP_ZONE_BUOY")
      )
    );

    /*
        Activate all of the other game's chip.
        This order is important bacause some of them need to be
        activate before/after others.
    */
    this._activateChildChip(this._camera);

    // Start one run after anoter
    this._activateChildChip(
      new chip.Sequence([new levelSystem.LevelRun()], { loop: true })
    );
  }

  protected _onTick(): void {
    // /*
    //   THREE JS RENDER
    // */
    this._renderer.resetState();

    /*
    Main scene Render
    */
    if (params.inBenchMode()) console.time("Main scene Render");
    this._renderer.setRenderTarget(this._mainScreenTarget);
    this._renderer.render(this._scene, this._camera.camera);
    if (params.inBenchMode()) console.timeEnd("Main scene Render");

    if (params.inBenchMode())
      console.log("draw call :", this._renderer.info.render.calls);
    if (params.inBenchMode())
      console.log("polygons :", this._renderer.info.render.triangles);

    /*
      Water scene Render
      */
    if (params.inBenchMode()) console.time("Water scene Render");
    this._renderer.setRenderTarget(this._waterSceneTarget);
    this._renderer.clearColor();
    this._renderer.render(this._waterScene, this._camera.camera);
    if (params.inBenchMode()) console.timeEnd("Water scene Render");

    /*
    Post Process scene uniform update & render
    */
    if (params.inBenchMode()) console.time("Post Process scene render");
    this._renderer.setRenderTarget(null);
    postProcess.postProcessPass.uniforms.tDepth.value =
      this._mainScreenTarget.depthTexture;
    postProcess.postProcessPass.uniforms.tDepthWater.value =
      this._waterSceneTarget.depthTexture;
    postProcess.postProcessPass.uniforms.tDiffuse.value =
      this._mainScreenTarget.texture;
    postProcess.postProcessPass.uniforms.tDiffuseWater.value =
      this._waterSceneTarget.texture;
    postProcess.postProcessPass.uniforms.cameraFar.value =
      this._camera.camera.far;
    postProcess.postProcessPass.uniforms.cameraNear.value =
      this._camera.camera.near;
    this._renderer.render(this._postProcessScene, this._postProcessCamera);
    if (params.inBenchMode()) console.timeEnd("Post Process scene render");

    /*
        PIXI JS RENDER
    */
    if (params.inBenchMode()) console.time("Pixi scene render");
    // Resetting the shader system before the PIXI renderer prevents the error "INVALID_OPERATION: uniformMatrix3fv: location is not from current program pixi.js"
    this._pRenderer.shader.reset();
    this._pRenderer.reset();

    this._pRenderer.render(this._pStage, { clear: false });
    if (params.inBenchMode()) console.timeEnd("Pixi scene render");

    if (this._stats) this._stats.end();
    if (params.inBenchMode()) console.timeEnd("Total for the game");
  }

  protected _onResize(): void {
    this._camera.camera.aspect =
      responsive.getScreenWidth() / responsive.getScreenHeight();
    this._camera.camera.updateProjectionMatrix();
    this._mainScreenTarget.setSize(
      responsive.getScreenWidth() * this._resolutionFactor,
      responsive.getScreenHeight() * this._resolutionFactor
    );
    this._waterSceneTarget.setSize(
      responsive.getScreenWidth() * this._resolutionFactor,
      responsive.getScreenHeight() * this._resolutionFactor
    );
    this._renderer.setSize(
      responsive.getScreenWidth() * this._resolutionFactor,
      responsive.getScreenHeight() * this._resolutionFactor
    );
    this._pRenderer.resize(
      responsive.getScreenWidth() * this._resolutionFactor,
      responsive.getScreenHeight() * this._resolutionFactor
    );
  }
}

/*
    Initialize the runners & launch the game !
*/

const canvas: HTMLCanvasElement = document.createElement("canvas");
canvas.id = "game-canvas";
document.body.appendChild(canvas);

const rootChip = new chip.Parallel([
  {
    chip: new save.SaveManager(),
    attribute: "saveManager",
    includeInChildContext: true,
  },
  (context) => {
    const chips: chip.Chip[] = [new loader.Loading()];
    // if (!context.saveManager.hasPlayedBefore()) {
    //   chips.push(new intro.Intro());
    // }
    chips.push(new GameSupport());
    return new chip.Sequence(chips);
  },
]);

const appRunner = new running.Runner(rootChip, {
  rootContext: {
    canvas: canvas,
  },
});

seaMap.loadTilesPresets();
appRunner.start();
