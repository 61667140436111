import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";
import * as easing from "booyah/dist/easing";
import * as tween from "booyah/dist/tween";

import * as tLoaders from "../../loaders/textureLoaders";

import * as collectable from "../../collectable";
import * as constants from "../../constants";
import * as level from "../../level";
import * as playerInfo from "../../playerInfo";
import * as responsive from "../../responsive";

export class LootListOptions {
  headerText!: string;
  y = 0;
  objectives!: Partial<level.LootValues>;
}

/**
 * List of collectables with their quantity
 */
export class LootList extends responsive.ResponsiveChip {
  readonly itemHeight = 60;
  readonly moveHeight = 500;

  private _options: LootListOptions;

  private _container!: PIXI.Container;
  private _lootContainer!: PIXI.Container;
  private _mask!: PIXI.Sprite;
  private _stateMachine!: chip.StateMachine;

  private _opened!: boolean;

  get bottomY() {
    return this._container.y + this._container.height;
  }

  constructor(options: Partial<LootListOptions>) {
    super();

    this._options = chip.fillInOptions(options, new LootListOptions());
  }

  protected _onActivate(): void {
    this._opened = false;

    this._container = new PIXI.Container();
    this._lootContainer = new PIXI.Container();

    this._stateMachine = new chip.StateMachine(
      {
        closed: this._closedState.bind(this),
        opened: this._openedState.bind(this),
        waiting: new chip.Forever(),
      },
      {
        startingState: "closed",
        signals: {
          opened: "waiting",
          closed: "waiting",
        },
      }
    );

    this._mask = new PIXI.Sprite(PIXI.Texture.WHITE);
    this._mask.width = 200;
    this._mask.height = this.moveHeight;
    this._lootContainer.mask = this._mask;

    this._container.position.x = 15;
    this._container.position.y = this._options.y;
    this._lootContainer.position.set(10, -800);

    this._container.addChild(this._mask, this._lootContainer);

    {
      const header = tLoaders.UINineSlicePlaneAssets.getNineSlice("BG_Score", {
        x: 193,
        y: 48,
      });
      header.buttonMode = true;
      header.eventMode = "dynamic";

      this._subscribe(
        header,
        "pointerdown",
        (event: PIXI.FederatedPointerEvent) => {
          event.stopImmediatePropagation();
          if (this._opened) this.close();
          else this.open();
        }
      );

      this._container.addChild(header);
    }

    {
      const headerText = new PIXI.Text(this._options.headerText, {
        fontSize: 24,
        fill: 0xffffff,
        fontFamily: "Hvd Comic Serif Pro",
      });
      headerText.position.set(15, 5);
      this._container.addChild(headerText);
    }

    this.chipContext.container.addChild(this._container);

    this._activateChildChip(this._stateMachine);
  }

  protected _onTick() {
    this._container.position.y = this._options.y;
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container);
  }

  public refreshView() {
    if (!this._opened) return;

    const playerInfo = this.chipContext.playerInfo as playerInfo.PlayerInfo;

    this._lootContainer.removeChildren();

    const background = tLoaders.UINineSlicePlaneAssets.getNineSlice("BG_Paper");

    background.position.y = 20;
    background.width = 165;

    this._lootContainer.addChild(background);

    let i = 0;

    for (const key of Object.keys(this._options.objectives)) {
      const objective = this._options.objectives[key]!;
      // const value = this._values[key] ?? 0;
      const value = playerInfo.loot[key] ?? 0;
      const metObjective = value >= objective;

      background.height += this.itemHeight;

      const row = new PIXI.Container();

      row.position.set(20, 60 + i * this.itemHeight);

      const textureName: tLoaders.TextureAssetName =
        key === "TOTAL"
          ? "trash/icon-trash-all"
          : collectable.getTextureNameForCollectable(key);
      const sprite = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture(textureName)
      );

      sprite.scale.set(0.6);
      sprite.anchor.set(0.5);
      sprite.position.set(20, 30);

      const count = new PIXI.Text(`${value}/${objective}`, {
        fontFamily: "Hvd Comic Serif Pro",
        fontSize: 30,
        fill: metObjective ? constants.green : constants.almostBlack,
      });

      count.anchor.set(0.5);
      count.position.set(90, 30);

      row.addChild(sprite, count);

      this._lootContainer.addChild(row);

      i++;
    }
  }

  public open() {
    if (this._opened) return;
    this._stateMachine.changeState("opened");
  }

  public close() {
    if (!this._opened) return;
    this._stateMachine.changeState("closed");
  }

  private _openedState() {
    this._opened = true;

    this.refreshView();

    return new tween.Tween({
      from: -this._lootContainer.height * 1.2,
      to: 0,
      duration: 500,
      easing: easing.easeOutQuad,
      onUpdate: (value) => {
        this._lootContainer.position.y = value;
      },
    });
  }

  private _closedState() {
    this._opened = false;

    return new tween.Tween({
      from: 0,
      to: -this._lootContainer.height * 1.2,
      duration: 300,
      easing: easing.easeOutQuad,
      onUpdate: (value) => {
        this._lootContainer.position.y = value;
      },
      onTeardown: () => {
        this._opened = false;
      },
    });
  }
}
