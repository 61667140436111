import * as PIXI from "pixi.js";

import * as tLoaders from "../../loaders/textureLoaders";

export class Flag extends PIXI.Container {
  constructor(
    text: string,
    texture: tLoaders.TextureAssetName & `Flag_${string}`
  ) {
    super();

    const background = tLoaders.UINineSlicePlaneAssets.getNineSlice(texture, {
      x: 130,
      y: 30,
    });

    const textView = new PIXI.Text(text, {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 25,
      fill: 0xffffff,
    });

    textView.anchor.set(0.5);
    textView.position.set(background.width / 2, background.height / 2);

    background.addChild(textView);

    this.addChild(background);
  }
}
