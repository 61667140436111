import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

import * as aLoaders from "../../loaders/audioLoaders";
import * as tLoaders from "../../loaders/textureLoaders";

import * as context from "../../context";
import * as params from "../../params";
import * as button from "../components/button";
import * as flag from "../components/flag";
import * as popup from "../components/popup";

export const backgroundSize: PIXI.IPointData = {
  x: 1050,
  y: 200,
};

/**
 * `equip` is for boats and nets, that you can only have one of at a time
 * `purchase` is for bonuses and such that you can buy many of
 * `change` is for the start tab, where you want to go back to a given tab
 */
export type ShopItemBehavior = "equip" | "purchase" | "change";

export interface ShopItemOptions {
  name: string;
  isNew: boolean;
  price: number;
  image: tLoaders.TextureAssetName;
  behavior: ShopItemBehavior;
}

export type ShopItemStates = "toBuy" | "bought" | "selected" | "cannotBuy"; // | "upgrade"

export abstract class ShopItem extends context.ContextualChip {
  protected _container!: PIXI.Container;
  protected _background!: PIXI.Container;
  protected _imageBackground!: PIXI.Container;
  protected _name!: PIXI.Text;
  protected _itemState!: chip.StateMachine;
  protected _itemCurrentState!: ShopItemStates;

  protected constructor(protected _options: ShopItemOptions) {
    super();
  }

  protected _canBuy(): boolean {
    return !this._isBought();
  }

  protected abstract _onBuy(): void;

  protected _onSelect(): void {
    // no op
  }

  protected _canSelect(): boolean {
    return false;
  }

  // protected abstract _onUpgrade(): void;
  // protected abstract _canUpgrade(): boolean;

  protected _isBought(): boolean {
    return false;
  }

  protected _isSelected(): boolean {
    return false;
  }

  get defaultChildChipContext() {
    return {
      container: this._container,
    };
  }

  protected _onActivate(): void {
    switch (this._options.behavior) {
      case "change": {
        this._itemCurrentState = "selected";
        break;
      }

      case "equip": {
        this._itemCurrentState = this._isSelected()
          ? "selected"
          : this._isBought()
          ? "bought"
          : "toBuy";
        break;
      }

      case "purchase": {
        this._itemCurrentState = this._canBuy() ? "toBuy" : "cannotBuy";
        break;
      }

      default:
        throw new Error("Unknown behavior");
    }

    {
      this._container = new PIXI.Container();
      this.chipContext.container.addChild(this._container);
    }

    // Yellow background
    {
      this._background = new PIXI.Container();
      this._background.eventMode = "none";
      this._container.addChild(this._background);
    }

    // Image background
    {
      this._imageBackground = new PIXI.Container();
      this._imageBackground.position.set(backgroundSize.y / 2);
      this._background.addChild(this._imageBackground);
    }

    // Item image
    {
      const itemImage = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture(this._options.image)
      );
      itemImage.anchor.set(0.5);

      // Fit the image within the background
      const choiceBackground = tLoaders.UITextureAssets.getTexture(
        "Choice_Background_Selected"
      );
      itemImage.scale.set(
        Math.min(
          choiceBackground.width / itemImage.width,
          choiceBackground.height / itemImage.height
        )
      );

      this._imageBackground.addChild(itemImage);
    }

    // Item name
    {
      this._name = new PIXI.Text(this._options.name, {
        fontFamily: "Hvd Comic Serif Pro",
        fontSize: 30,
        fill: 0x000000,
      });

      this._name.position.x = backgroundSize.y;
      this._name.position.y = 15;
      this._background.addChild(this._name);
    }

    // New flag
    if (this._options.isNew) {
      const newFlag = new flag.Flag("new", "Flag_Red");
      newFlag.position.x = backgroundSize.y + this._name.width + 10;
      newFlag.position.y = 25;
      this._background.addChild(newFlag);
    }

    {
      this._itemState = new chip.StateMachine(
        {
          toBuy: this._makeState.bind(this, "toBuy"),
          bought: this._makeState.bind(this, "bought"),
          //upgrade: this._makeState.bind(this, "upgrade"),
          selected: this._makeState.bind(this, "selected"),
          cannotBuy: this._makeState.bind(this, "cannotBuy"),
        },
        {
          startingState: this._itemCurrentState,
        }
      );
      this._activateChildChip(this._itemState);
    }
  }

  protected _onTerminate(): void {
    this.chipContext.container.removeChild(this._container);
  }

  protected _makeState(state: ShopItemStates): chip.ChipBase {
    this._itemCurrentState = state;

    if (state === "selected") {
      if (!this._canSelect()) {
        return new chip.Lambda(() => {
          this.emit("cantSelect");
          this._itemState.changeState("bought");
        });
      }

      this.emit("selected");
      this._onSelect();
    }

    let background: PIXI.NineSlicePlane;
    if (state !== "bought") {
      // Only unselected equipable items get the yellow background
      background = tLoaders.UINineSlicePlaneAssets.getNineSlice(
        state === "toBuy" && this._options.behavior === "equip"
          ? "List_Item_Background_Yellow"
          : "List_Item_Background_Grey",
        backgroundSize
      );

      if (this._options.behavior === "change") {
        background.width += 40;
      }

      if (state === "selected") {
        background.alpha = 0.5;
      }
    }

    let imageBackground: PIXI.Sprite | undefined;
    {
      if (this._options.behavior === "equip") {
        imageBackground = new PIXI.Sprite(
          tLoaders.UITextureAssets.getTexture(
            state === "selected"
              ? "Choice_Background_Selected"
              : "Choice_Background_Unselected"
          )
        );
      } else if (this._options.behavior === "change") {
        imageBackground = new PIXI.Sprite(
          tLoaders.UITextureAssets.getTexture(
            state === "selected"
              ? "Choice_Background_Selected"
              : "Choice_Background_Unselected"
          )
        );
      }

      imageBackground?.anchor.set(0.5);
    }

    let btn: button.Button;
    if (this._options.behavior === "change") {
      btn = new button.Button({
        texture: "Button_Big_Red",
        text: "Change",
        width: 150,
        position: {
          x: backgroundSize.x - 185,
          y: 12,
        },
        onClick: () => {
          this.emit("change");
        },
      });
    } else {
      const disabled = state === "selected" || state === "cannotBuy";
      let text: string;
      if (state === "toBuy" || state === "cannotBuy") {
        text = "Buy";
      } else {
        text = "Select";
      }

      btn = new button.Button({
        texture: disabled ? "Button_Big_Grey" : "Button_Big_Red",
        disabled,
        text,
        width: 150,
        position: {
          x: backgroundSize.x - 185,
          y: 12,
        },
        onClick: () => {
          switch (state) {
            case "bought":
              this._itemState.changeState("selected");
              break;
            // case "Done":
            //   this._itemState.changeState("bought")
            //   this._onUpgrade()
            //   break
            case "toBuy":
              if (
                params.inDebugMode() ||
                this.chipContext.saveManager.money >= this._options.price
              ) {
                // show confirmation dialog
                this._activateChildChip(
                  new popup.ConfirmationPopup({
                    text: `Are you sure you want to buy ${this._options.name}?`,
                    onConfirm: () => {
                      if (!params.inDebugMode()) {
                        this.chipContext.saveManager.spendMoney(
                          this._options.price
                        );
                      }
                      aLoaders.playFx("unlock_shop_item");
                      this._onBuy();
                      this.emit("bought");

                      if (this._options.behavior === "equip") {
                        this._itemState.changeState("selected");
                      } else if (this._options.behavior === "purchase") {
                        const newState = this._canBuy() ? "toBuy" : "cannotBuy";
                        this._itemState.changeState(newState);
                      }
                    },
                  })
                );
              } else {
                // show error dialog
                this._activateChildChip(
                  new popup.AlertPopup({
                    text: "Not enough money",
                  })
                );
              }
          }
        },
      });
    }

    const priceContainer = new PIXI.Container();

    //let upgradeBtn!: button.Button

    return new chip.Functional({
      activate: () => {
        if (state !== "bought") {
          this._background.addChildAt(background, 0);
        }

        if (imageBackground)
          this._imageBackground.addChildAt(imageBackground, 0);

        if (state === "toBuy") {
          const price = new PIXI.Text(this._options.price.toLocaleString(), {
            fontFamily: "Hvd Comic Serif Pro",
            fontSize: 45,
            fill: 0xffffff,
          });

          price.anchor.set(1, 0.5);

          price.position.x = backgroundSize.x - 260;
          price.position.y = 33;

          const moneyIcon = new PIXI.Sprite(
            tLoaders.UITextureAssets.getTexture("Money")
          );

          moneyIcon.anchor.set(0, 0.5);

          moneyIcon.position.x = backgroundSize.x - 260;
          moneyIcon.position.y = 35;

          priceContainer.addChild(price, moneyIcon);

          this._background.addChild(priceContainer);
        }

        this._activateChildChip(btn, {
          context: { container: this._container },
        });
      },
      terminate: () => {
        if (state !== "bought") {
          this._background.removeChild(background);
        }

        if (imageBackground) this._imageBackground.removeChild(imageBackground);

        if (state === "toBuy") {
          this._background.removeChild(priceContainer);
        }

        if (this.state === "active") {
          if (this._options.behavior !== "change") {
            this._terminateChildChip(btn);
          }

          // if (state !== "toBuy" && state !== "upgrade") {
          //   this._terminateChildChip(upgradeBtn)
          // }
        }
      },
    });
  }

  public unselect() {
    if (this._itemCurrentState === "selected")
      this._itemState.changeState("bought");
  }
}
