import * as THREE from "three";

import * as chip from "booyah/dist/chip";
import * as easing from "booyah/dist/easing";
import * as booyahUtil from "booyah/dist/util";

import * as constants from "./constants";
import * as params from "./params";
import * as utils from "./utils";

export enum CameraPreset {
  default,
  top_close,
  overhead_far,
  overhead_close,
  fixed_rotation,
  starting_move,
}

export class PlayerCamera extends chip.ChipBase {
  private _camera: THREE.PerspectiveCamera;
  private _dist = 35.0;
  private _distFactor = 1.0;
  private _shakeStrength = 0;

  private _startingMoveProgress = 0;

  public get startingMoveProgress() {
    return this._startingMoveProgress;
  }

  public set startingMoveProgress(v: number) {
    this._startingMoveProgress = v;
    // Return to default preset if complete
    if (
      this._startingMoveProgress >= 1 &&
      this._preset === CameraPreset.starting_move
    )
      this._preset = CameraPreset.default;
    // Set preset if not already set
    else if (this._preset !== CameraPreset.starting_move)
      this._preset = CameraPreset.starting_move;
  }

  private readonly _fovMin: number = 55;
  private readonly _fovMax: number = 70;
  private readonly _fovPerSpeed: number;

  private _preset: CameraPreset = CameraPreset.default;

  constructor(FOV: number, ratio: number, near: number, far: number) {
    super();

    this._camera = new THREE.PerspectiveCamera(FOV, ratio, near, far);

    this._fovPerSpeed = (this._fovMax - this._fovMin) / constants.maxSpeed;
  }

  protected _onActivate(): void {
    if (params.inDebugMode()) {
      this._subscribe(document, "keydown", (event: KeyboardEvent) => {
        switch (event.key) {
          case "m":
            this.cyclePresets();
            break;
        }
      });

      this._subscribe(document, "touchstart", (event: TouchEvent) => {
        if (event.touches.length > 2) {
          this.cyclePresets();
        }
      });
    }
  }

  get camera() {
    return this._camera;
  }

  public _onTick() {
    // Store preset
    this.chipContext.playerInfo.cameraPreset = this._preset;

    // Camera up vector
    this._camera.up.set(0, 1, 0);

    // Default direction and target
    const direction = new THREE.Vector2(0, 0);
    const lookAt = new THREE.Vector3(
      this.chipContext.playerInfo.positionX,
      0,
      this.chipContext.playerInfo.positionY
    );

    // Apply preset
    switch (this._preset) {
      case CameraPreset.default:
        this._distFactor = 1.0;
        direction.x = this.chipContext.playerInfo.directionX;
        direction.y = this.chipContext.playerInfo.directionY;
        this._camera.position.y = 20;
        lookAt.y = 0;
        this.applyRegularCalculations(direction);
        break;

      case CameraPreset.top_close:
        this._distFactor = 1.0;
        this._camera.position.y = 30;
        direction.x = this.chipContext.playerInfo.directionX;
        direction.y = this.chipContext.playerInfo.directionY;
        lookAt.y = 0;
        this.applyRegularCalculations(direction);
        break;

      case CameraPreset.overhead_far:
        this._distFactor = 0.0;
        this._camera.position.y = 600;
        lookAt.y = 0;
        this.applyRegularCalculations(direction);
        break;

      case CameraPreset.overhead_close:
        this._distFactor = 0.0;
        this._camera.position.y = 100;
        lookAt.y = 0;
        this.applyRegularCalculations(direction);
        break;

      case CameraPreset.fixed_rotation:
        // Camera position
        this._camera.position.set(
          this.chipContext.playerInfo.positionX,
          500,
          this.chipContext.playerInfo.positionY
        );
        // Camera up vector
        this._camera.up.set(0, 0, -1);
        // Camera target
        lookAt.set(
          this.chipContext.playerInfo.positionX,
          0,
          this.chipContext.playerInfo.positionY
        );
        break;

      case CameraPreset.starting_move:
        // Camera position
        this.applyStartingMoveCalculations();
        // Camera target
        lookAt.set(
          this.chipContext.playerInfo.positionX,
          booyahUtil.lerp(
            20,
            0,
            easing.easeInOutQuart(this._startingMoveProgress)
          ),
          this.chipContext.playerInfo.positionY
        );
        break;

      default:
        this.applyRegularCalculations(direction);
        break;
    }

    // Apply shake if needed
    if (this._shakeStrength > 0.01) {
      lookAt.x += utils.randomSign(false) * this._shakeStrength;
      lookAt.y += utils.randomSign(false) * this._shakeStrength;
      lookAt.z += utils.randomSign(false) * this._shakeStrength;
      this._shakeStrength *= 0.9;
    }

    // Camera target
    this.camera.lookAt(lookAt);

    // Adapt the FOV depending on the speed of the boat
    const speed = this.chipContext.playerInfo.speed;

    const fov = THREE.MathUtils.clamp(
      this._fovMin + speed * this._fovPerSpeed,
      this._fovMin,
      this._fovMax
    );

    this._camera.fov = fov;
    this.chipContext.playerInfo.cameraFOV = fov;
    this._camera.updateProjectionMatrix();
  }

  private applyRegularCalculations(direction: THREE.Vector2) {
    // TODO: restore this, by getting speed boost info to camera

    // let speedBoostDist = THREEmath.clamp(
    //   postprocess.postProcessPass.uniforms.speedBoostTime.value /
    //     constants.speedBoostFade,
    //   0.0,
    //   1.0
    // );

    // speedBoostDist *= THREEmath.clamp(
    //   (postprocess.postProcessPass.uniforms.speedBoostDuration.value -
    //     postprocess.postProcessPass.uniforms.speedBoostTime.value) /
    //     constants.speedBoostFade,
    //   0.0,
    //   1.0
    // );

    // this._distFactor += 0.4 * speedBoostDist;

    // Make the camera move at a certain speed from the current rotation to the desired rotation
    const correctAngle = direction.angle();
    const currentAngle = new THREE.Vector2(
      -this.camera.position.x + this.chipContext.playerInfo.positionX,
      -this.camera.position.z + this.chipContext.playerInfo.positionY
    ).angle();

    let correction = correctAngle - currentAngle;

    // Invert the correction angle if the given correction is over 90°
    if (Math.abs(correction) > Math.PI) {
      correction =
        -Math.sign(correction) *
        (Math.min(currentAngle, Math.PI * 2 - currentAngle) +
          Math.min(correctAngle, Math.PI * 2 - correctAngle));
    }

    const correctionSpeed = 2.0;
    correction *= correctionSpeed * this.chipContext.playerInfo.deltatime;

    const finalDirection = new THREE.Vector2(
      Math.cos(currentAngle + correction),
      Math.sin(currentAngle + correction)
    );

    // Camera position
    this.camera.position.x =
      this.chipContext.playerInfo.positionX -
      this._dist * this._distFactor * finalDirection.x;
    this.camera.position.z =
      this.chipContext.playerInfo.positionY -
      this._dist * this._distFactor * finalDirection.y;
  }

  private applyStartingMoveCalculations() {
    // Lerp values on progress
    const smAngle = booyahUtil.lerp(
      Math.PI * 0.85,
      0,
      easing.easeInOutQuart(this._startingMoveProgress)
    );
    const smDistance = booyahUtil.lerp(
      80,
      this._dist,
      easing.easeInOutQuart(this._startingMoveProgress)
    );
    const smHeight = booyahUtil.lerp(
      40,
      20,
      easing.easeInOutQuart(this._startingMoveProgress)
    );

    // Camera position
    this._camera.position.set(
      this.chipContext.playerInfo.positionX + Math.cos(smAngle) * smDistance,
      smHeight,
      this.chipContext.playerInfo.positionY + Math.sin(smAngle) * smDistance
    );
  }

  private cyclePresets() {
    if (this._preset == Object.keys(CameraPreset).length / 2 - 1)
      this._preset = 0;
    else this._preset += 1;

    // console.log(Object.values(CameraPreset)[this._preset]);
  }

  public shake(strength: "hard" | "gentle") {
    this._shakeStrength = strength == "hard" ? 0.6 : 0.15;
  }
}
