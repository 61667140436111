import _ from "underscore";

import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";
import * as easing from "booyah/dist/easing";
import * as tween from "booyah/dist/tween";

import * as aLoaders from "../../loaders/audioLoaders";
import * as tLoaders from "../../loaders/textureLoaders";

import * as collectable from "../../collectable";
import * as constants from "../../constants";
import * as level from "../../level";
import * as responsive from "../../responsive";
import * as unlock from "../../unlock";
import * as utils from "../../utils";
import * as button from "../components/button";
import * as moneyView from "../components/moneyView";
import * as popup from "../components/popup";

export type GameOverScreenOptions = {
  success: boolean;
  lootObjectives: Partial<level.LootValues>;
  totalUnlockCount: number;
  acquiredUnlockCount: number;
  totalKeyCount: number;
  acquiredKeyCount: number;
  bonusToDiscover?: collectable.BonusName;
  discoveredBonus?: collectable.BonusName;
  nextBonusToUnlock?: collectable.BonusName;
};

export function makeGameOverScreen(options: GameOverScreenOptions) {
  const chips: chip.Chip[] = [new ObjectiveScreen(options)];

  if (options.acquiredUnlockCount)
    chips.push(new AcquiredUnlockScreen(options));

  if (options.acquiredKeyCount) chips.push(new AcquiredKeyScreen(options));

  return new chip.Sequence(chips);
}

class ObjectiveScreen extends responsive.ResponsiveChip {
  readonly width = 700;
  readonly height = 400;

  private _container!: PIXI.Container;

  constructor(private readonly _options: GameOverScreenOptions) {
    super();
  }

  protected _onActivate(): void {
    const currentLevel = this.chipContext.level as level.Level;

    // TODO: show number of keys & unlocks

    const titleText = this._options.success
      ? `Level ${currentLevel.options.levelNumber} Complete`
      : `Level ${currentLevel.options.levelNumber} Incomplete`;
    const buttonText = this._options.success ? "Next Level" : "Start Again";

    // TODO Adjust subtitle text once multiple objectives are implemented
    const subtitleText = this._options.success
      ? "Well done, you achieved the objective"
      : "You haven't completed the objective";

    this._container = new PIXI.Container();

    const bg = new popup.PopupBackground({
      width: this.width,
      height: this.height,
      verticalMargin: constants.popupInGameVerticalMargin,
    });

    const sfx = this._options.success ? "end_win" : "end_lose";
    aLoaders.playFx(sfx);

    this._activateChildChip(bg, {
      context: {
        container: this._container,
      },
    });

    this._activateChildChip(new moneyView.MoneyView(), {
      context: {
        container: this._container,
      },
    });

    const gameOverText = new PIXI.Text(titleText, {
      fontSize: 52,
      fill: this._options.success
        ? constants.green
        : constants.principalColorLight,
      fontFamily: "Hvd Comic Serif Pro",
    });
    gameOverText.anchor.set(0.5);
    gameOverText.position.set(this.width / 2, 50);

    const subText = new PIXI.Text(subtitleText, {
      fontSize: 30,
      fill: 0x000000,
      fontFamily: "Hvd Comic Serif Pro",
      wordWrap: true,
      align: "center",
    });
    subText.anchor.set(0.5);
    subText.position.set(this.width / 2, gameOverText.position.y + 60);
    subText.style.wordWrapWidth = this.width * 0.9;

    const restartButton = new button.Button({
      text: buttonText,
      texture: "Button_Big_Green",
      width: 400,
      large: true,
      position: {
        x: this.width / 2 - 400 / 2,
        y: this.height - 70,
      },
      onClick: () => {
        this.terminate();
      },
      shortcutKey: "Enter",
    });
    this._activateChildChip(restartButton, {
      context: {
        container: bg.content,
      },
    });
    bg.content.addChild(gameOverText);
    bg.content.addChild(subText);

    // Show objective reminder

    const objectiveContainer = new PIXI.Container();

    // Right now only one objective is supported in the UI
    let objectiveAmount: number;
    let objectiveIcon: tLoaders.TextureAssetName;
    if (this._options.lootObjectives.TOTAL) {
      objectiveAmount = this._options.lootObjectives.TOTAL;
      objectiveIcon = "trash/icon-trash-all";
    } else {
      console.assert(Object.keys(this._options.lootObjectives).length === 1);
      const trashName = Object.keys(this._options.lootObjectives)[0];

      objectiveAmount = this._options.lootObjectives[trashName] as number;
      objectiveIcon = collectable.getTextureNameForCollectable(
        trashName as collectable.CollectableName
      );
    }

    // Show sprite
    {
      const objectiveSprite = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture(objectiveIcon)
      );
      objectiveSprite.anchor.set(0, 0.5);
      objectiveSprite.scale.set(0.65);
      objectiveContainer.addChild(objectiveSprite);
    }

    // Show text
    {
      const objectiveText = new PIXI.Text(`x${objectiveAmount}`, {
        fontSize: 36,
        fill: this._options.success
          ? constants.green
          : constants.principalColorLight,
        fontFamily: "Hvd Comic Serif Pro",
      });
      objectiveText.anchor.set(0, 0.5);
      objectiveText.position.set(70, 0);
      objectiveContainer.addChild(objectiveText);
    }

    objectiveContainer.position.set(
      (this.width - objectiveContainer.width) / 2,
      this.height / 2
    );
    bg.content.addChild(objectiveContainer);

    // Score
    let scoreText: PIXI.Text;
    {
      const score = this.chipContext.playerInfo.score;

      scoreText = new PIXI.Text(`Score: ${score.toLocaleString()} pts`, {
        fontSize: 48,
        fill: 0x000000,
        fontFamily: "Hvd Comic Serif Pro",
      });

      scoreText.anchor.set(0.5);
      scoreText.position.set(this.width / 2, this.height - 120);

      bg.content.addChild(scoreText);
    }

    this.chipContext.container.addChild(this._container);

    this.resize();

    const baseScore = this.chipContext.playerInfo.score;
    let moneyToBeEarned = baseScore * constants.pointsToCoinsRatio;

    const scoreToMoneyTextList: PIXI.Text[] = [];
    const scoreToMoneyTweenDuration = 1000;
    const moneyChunkSize = 10;

    const scoreToMoneyText = (value: number) => {
      const moneyText = new PIXI.Text(`+${Math.round(value)}`, {
        fontSize: 50,
        fill: constants.moneyColor,
        fontFamily: "Hvd Comic Serif Pro",
      });

      moneyText.anchor.set(0.5);
      moneyText.position.set(this.width / 2, this.height / 2 + 100);

      this.chipContext.container.addChild(moneyText);

      return moneyText;
    };

    const getRandomEasing = () =>
      _.shuffle([
        easing.easeOutCubic,
        easing.easeOutCirc,
        easing.easeOutQuad,
      ])[0];

    // TODO Get actual position of money view element
    const destinationX = responsive.getScreenWidth() - 200;
    const destinationY = 50;

    this._activateChildChip(
      new chip.Sequence([
        new chip.Wait(500),
        new chip.Functional({
          activate: () => {
            // Build a list of money chunks to animate in UI
            const moneyChunks: number[] = [];
            let moneyLeft = moneyToBeEarned;
            while (moneyLeft > 0) {
              if (moneyLeft >= moneyChunkSize) moneyChunks.push(moneyChunkSize);
              else moneyChunks.push(moneyLeft);
              moneyLeft -= moneyChunks[moneyChunks.length - 1];
            }

            // For each chunk, create a text and animate it
            utils.times(moneyChunks.length, (index) => {
              this._activateChildChip(
                new chip.Sequence([
                  new chip.Wait(25 * index),
                  new chip.Lambda(() => {
                    scoreToMoneyTextList.push(
                      scoreToMoneyText(moneyChunks[index])
                    );
                  }),
                  new chip.Parallel([
                    new tween.Tween({
                      from: scoreText.getGlobalPosition().x,
                      to: destinationX,
                      duration: scoreToMoneyTweenDuration,
                      easing: getRandomEasing(),
                      onUpdate: (x) => {
                        scoreToMoneyTextList[index].x = x;
                      },
                    }),
                    new tween.Tween({
                      from: scoreText.getGlobalPosition().y,
                      to: destinationY,
                      duration: scoreToMoneyTweenDuration,
                      easing: getRandomEasing(),
                      onUpdate: (y) => {
                        scoreToMoneyTextList[index].y = y;
                      },
                    }),
                  ]),
                  new chip.Lambda(() => {
                    this.chipContext.container.removeChild(
                      scoreToMoneyTextList[index]
                    );

                    // Earn this chunk of money, substract from what is left to be earned
                    this.chipContext.saveManager.earnMoney(moneyChunks[index]);
                    moneyToBeEarned -= moneyChunks[index];
                  }),
                ])
              );
            });
          },
          terminate: () => {
            // Only earn the leftover money (if clicked before the end of all texts tweens)
            this.chipContext.saveManager.earnMoney(moneyToBeEarned);

            // Remove the texts whose tweens were still going
            scoreToMoneyTextList.forEach((text) => {
              this.chipContext.container.removeChild(text);
            });
          },
        }),
      ])
    );
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container);

    this._container.destroy();
  }
}

class AcquiredUnlockScreen extends responsive.ResponsiveChip {
  readonly width = 700;
  readonly height = 400;

  private _container!: PIXI.Container;

  constructor(private readonly _options: GameOverScreenOptions) {
    super();
  }

  protected _onActivate(): void {
    aLoaders.playFx("end_win");

    this._container = new PIXI.Container();
    this.chipContext.container.addChild(this._container);

    let titleText: string;
    let subtitleText: string;
    if (this._options.totalUnlockCount === 3) {
      titleText = `You found all 3 items`;
      subtitleText = `You unlocked a new bonus!`;
    } else {
      titleText = `You gathered ${this._options.totalUnlockCount} items`;
      subtitleText = `Find ${
        3 - this._options.totalUnlockCount
      } more to unlock a new bonus`;
    }

    const bg = new popup.PopupBackground({
      width: this.width,
      height: this.height,
      verticalMargin: constants.popupInGameVerticalMargin,
    });

    this._activateChildChip(bg, {
      context: {
        container: this._container,
      },
    });

    const titlePixiText = new PIXI.Text(titleText, {
      fontSize: 52,
      fill: constants.black,
      fontFamily: "Hvd Comic Serif Pro",
    });
    titlePixiText.anchor.set(0.5);
    titlePixiText.position.set(this.width / 2, 100);
    bg.content.addChild(titlePixiText);

    // Show items
    const itemContainer = new PIXI.Container();
    const unlockName = collectable.makeUnlockForBonus(
      this._options.nextBonusToUnlock!
    );
    const unlockTexture = tLoaders.UITextureAssets.getTexture(
      unlock.getUnlockIconAssetName(unlockName)
    );

    for (let i = 0; i < 3; i++) {
      const sprite = new PIXI.Sprite(unlockTexture);
      sprite.position.set(i * 70, 0);
      sprite.alpha = i < this._options.totalUnlockCount ? 1 : 0.5;
      sprite.anchor.set(0, 0.5);
      sprite.scale.set(0.5); // TODO: necessary?
      itemContainer.addChild(sprite);
    }

    itemContainer.position.set(
      (this.width - itemContainer.width) / 2,
      this.height / 2
    );
    bg.content.addChild(itemContainer);

    const subTitlePixiText = new PIXI.Text(subtitleText, {
      fontSize: 30,
      fill: constants.black,
      fontFamily: "Hvd Comic Serif Pro",
      wordWrap: true,
      align: "center",
    });
    subTitlePixiText.anchor.set(0.5);
    subTitlePixiText.position.set(this.width / 2, this.height - 120);
    subTitlePixiText.style.wordWrapWidth = this.width * 0.9;
    bg.content.addChild(subTitlePixiText);

    const okButton = new button.Button({
      text: "OK",
      texture: "Button_Big_Green",
      width: 400,
      large: true,
      position: {
        x: this.width / 2 - 400 / 2,
        y: this.height - 70,
      },
      onClick: () => {
        this.terminate();
      },
      shortcutKey: "Enter",
    });
    this._activateChildChip(okButton, {
      context: {
        container: bg.content,
      },
    });

    this.resize();
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container);
    this._container.destroy();
  }
}

class AcquiredKeyScreen extends responsive.ResponsiveChip {
  readonly width = 700;
  readonly height = 400;

  private _container!: PIXI.Container;

  constructor(private readonly _options: GameOverScreenOptions) {
    super();
  }

  protected _onActivate(): void {
    aLoaders.playFx("end_win");

    this._container = new PIXI.Container();
    this.chipContext.container.addChild(this._container);

    let titleText: string;
    let subtitleText: string;
    if (this._options.totalKeyCount === 3) {
      titleText = `You found all 3 keys`;
      subtitleText = `You unlocked a new drop zone!`;
    } else {
      titleText = `You gathered ${this._options.totalKeyCount} keys`;
      subtitleText = `Find ${
        3 - this._options.totalKeyCount
      } more to unlock a new drop zone`;
    }

    const bg = new popup.PopupBackground({
      width: this.width,
      height: this.height,
      verticalMargin: constants.popupInGameVerticalMargin,
    });

    this._activateChildChip(bg, {
      context: {
        container: this._container,
      },
    });

    const titlePixiText = new PIXI.Text(titleText, {
      fontSize: 52,
      fill: constants.black,
      fontFamily: "Hvd Comic Serif Pro",
    });
    titlePixiText.anchor.set(0.5);
    titlePixiText.position.set(this.width / 2, 100);
    bg.content.addChild(titlePixiText);

    // Show items
    const itemContainer = new PIXI.Container();
    const keyTexture = tLoaders.UITextureAssets.getTexture("icon-unlock-key");

    for (let i = 0; i < 3; i++) {
      const sprite = new PIXI.Sprite(keyTexture);
      sprite.position.set(i * 70, 0);
      sprite.alpha = i < this._options.totalKeyCount ? 1 : 0.5;
      sprite.anchor.set(0, 0.5);
      sprite.scale.set(0.5); // TODO: necessary?
      itemContainer.addChild(sprite);
    }

    itemContainer.position.set(
      (this.width - itemContainer.width) / 2,
      this.height / 2
    );
    bg.content.addChild(itemContainer);

    const subTitlePixiText = new PIXI.Text(subtitleText, {
      fontSize: 30,
      fill: constants.black,
      fontFamily: "Hvd Comic Serif Pro",
      wordWrap: true,
      align: "center",
    });
    subTitlePixiText.anchor.set(0.5);
    subTitlePixiText.position.set(this.width / 2, this.height - 120);
    subTitlePixiText.style.wordWrapWidth = this.width * 0.9;
    bg.content.addChild(subTitlePixiText);

    const okButton = new button.Button({
      text: "OK",
      texture: "Button_Big_Green",
      width: 400,
      large: true,
      position: {
        x: this.width / 2 - 400 / 2,
        y: this.height - 70,
      },
      onClick: () => {
        this.terminate();
      },
      shortcutKey: "Enter",
    });
    this._activateChildChip(okButton, {
      context: {
        container: bg.content,
      },
    });

    this.resize();
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container);
    this._container.destroy();
  }
}
