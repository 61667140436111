import * as chip from "booyah/dist/chip";

import * as constants from "./constants";
import * as net from "./net";

/** A subset of bonuses that are powerups */
export const powerups = [
  "BONUS_SPEED",
  "BONUS_TIME",
  "BONUS_SHIELD",
  "BONUS_REPAIR",
  "BONUS_MAGNET",
  "BONUS_RECYCLE",
] as const;

export type Powerup = (typeof powerups)[number];

export type PowerupMeta = (
  | {
      type: "instant";
    }
  | {
      type: "continuous";
      duration: number;
    }
) & {
  // If present, this function will be called in the context of the PowerupManager.
  // If it returns false, the powerup cannot be used
  condition?: (context: chip.ChipContext) => boolean;
};

export const powerupMetas: Record<Powerup, PowerupMeta> = {
  BONUS_SPEED: {
    type: "continuous",
    duration: constants.speedBoostDuration,
  },
  BONUS_SHIELD: {
    type: "continuous",
    duration: constants.shieldDuration,
  },
  BONUS_TIME: {
    type: "instant",
  },
  BONUS_REPAIR: {
    type: "instant",
    condition: (context) =>
      context.playerInfo.netHp < net.getNetHp(context.level.boat.net.netName),
  },
  BONUS_MAGNET: {
    type: "continuous",
    duration: constants.magnetDuration,
  },
  BONUS_RECYCLE: {
    type: "instant",
    condition: (context) => context.level.boat.net.getItemCount() > 0,
  },
};

export class AcquiredPowerupInfo {
  inUse = false;
  startTime = 0;

  constructor(public count = 0) {}
}

export type AcquirePowerupInfos = Record<Powerup, AcquiredPowerupInfo>;

export class PowerupManager extends chip.Composite {
  private _acquiredPowerupInfos!: AcquirePowerupInfos;
  private _elapsedTime!: number;

  constructor(
    private readonly _initialCounts: Partial<Record<Powerup, number>> = {}
  ) {
    super();
  }

  protected _onActivate(): void {
    this._elapsedTime = 0;

    this._acquiredPowerupInfos = Object.fromEntries(
      powerups.map((powerup) => [
        powerup,
        new AcquiredPowerupInfo(this._initialCounts[powerup] || 0),
      ])
    ) as AcquirePowerupInfos;
  }

  protected _onTick(): void {
    this._elapsedTime += this._lastTickInfo.timeSinceLastTick;

    powerups.forEach((powerup) => {
      if (this.powerupIsInUse(powerup) && this.powerupHasExpired(powerup)) {
        this.stopPowerup(powerup);
      }
    });
  }

  getAcquiredPowerupInfo(powerup: Powerup): Readonly<AcquiredPowerupInfo> {
    return this._acquiredPowerupInfos[powerup];
  }

  acquirePowerup(powerup: Powerup): void {
    this.setCount(powerup, this.getCount(powerup) + 1);
    this.emit("acquiredPowerup", powerup);
  }

  powerupIsInUse(powerup: Powerup): boolean {
    return this._acquiredPowerupInfos[powerup].inUse;
  }

  getCount(powerup: Powerup): number {
    return this._acquiredPowerupInfos[powerup].count;
  }

  setCount(powerup: Powerup, count: number): void {
    this._acquiredPowerupInfos[powerup].count = count;

    this.emit("changedCount", powerup, count);
  }

  canUsePowerup(powerup: Powerup): boolean {
    if (this.powerupIsInUse(powerup) || this.getCount(powerup) <= 0)
      return false;

    const meta = powerupMetas[powerup];
    if (meta.condition && !meta.condition(this.chipContext)) return false;

    return true;
  }

  getTimeLeft(powerup: Powerup): number {
    const info = this._acquiredPowerupInfos[powerup];
    if (!info.inUse) throw new Error(`Powerup ${powerup} not in use`);

    const meta = powerupMetas[powerup];
    if (meta.type !== "continuous")
      throw new Error(`Powerup ${powerup} is not continous`);

    return meta.duration - (this._elapsedTime - info.startTime);
  }

  powerupHasExpired(powerup: Powerup): boolean {
    const info = this._acquiredPowerupInfos[powerup];
    if (!info.inUse) throw new Error(`Powerup ${powerup} not in use`);
    return info.inUse && this.getTimeLeft(powerup) <= 0;
  }

  usePowerup(powerup: Powerup): void {
    if (this.chipContext.level.levelState !== "playing") return;

    const info = this._acquiredPowerupInfos[powerup];
    if (info.inUse) throw new Error(`Powerup ${powerup} already in use`);
    if (info.count <= 0) throw new Error(`Powerup ${powerup} not available`);

    this.setCount(powerup, info.count - 1);

    const meta = powerupMetas[powerup];
    if (meta.type === "continuous") {
      info.inUse = true;
      info.startTime = this._elapsedTime;
    }

    this.emit("using", powerup);
  }

  stopPowerup(powerup: Powerup): void {
    if (this.chipContext.level.levelState !== "playing") return;

    const info = this._acquiredPowerupInfos[powerup];
    if (!info.inUse) throw new Error(`Powerup ${powerup} not in use`);

    info.startTime = 0;
    info.inUse = false;

    this.emit("stopped", powerup);
  }
}
