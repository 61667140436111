/*
    The following elements are imported here automatically from the Custom Shader Material add-on :

        csm_DiffuseColor := outpout of the final diffuse color of the material
*/

uniform float uTime;
uniform bool justTookDamage;
uniform bool usingShieldBonus;

vec3 rgb2hsv(vec3 c){
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

vec3 hsv2rgb(vec3 c)
{
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

void main()
{
    /*
        Triggers when the boat has taken damage recently
    */
    if(justTookDamage){
        // Blink red/white without disappering
        if(mod(uTime, 0.5) > 0.25) csm_Emissive.r +=  1.0;
        else {
            csm_Emissive.r +=  .5;
            csm_Emissive.g +=  .5;
            csm_Emissive.b +=  .5;
        }
    }

    /*
        Change the color of the boat depending on he power up
    */
    if (usingShieldBonus) {
        float glowFactor = exp(cos(uTime*4.0)*0.5 + 0.5);
        glowFactor *= 0.5;
        glowFactor = 1.0-glowFactor;
        glowFactor *= step(0.f, glowFactor)*2.0;
        vec3 color = 2.0*glowFactor*vec3(50.f, 218.f, 100.f)/256.f;
        csm_Emissive += color*0.25;
        csm_Metalness += 0.1;
        csm_Roughness += 0.1;
    }
}
