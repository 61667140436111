import * as UI from "@pixi/ui";
import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

import * as aLoaders from "./loaders/audioLoaders";
import * as fontLoader from "./loaders/fontLoader";
import * as mLoaders from "./loaders/modelLoaders";
import * as tLoaders from "./loaders/textureLoaders";

import * as constants from "./constants";
import * as button from "./gui/components/button";
import * as responsive from "./responsive";

/**
 * Loading screen class, creates a loading screen with a loading bar and loads all the assets
 */
export class Loading extends responsive.ResponsiveChip {
  waitForUpdate!: boolean;

  // loaders
  private _fontLoader = new fontLoader.FontLoader();
  private _musicLoader = new aLoaders.MusicLoader();
  private _fxLoader = new aLoaders.FxLoader();
  private _textureLoader = tLoaders.UITextureAssets;
  // private _animationLoader = tLoaders.UIAnimationAssets;
  private _commonModelLoader = mLoaders.commonModelAssets;
  private _obstacleModelLoader = mLoaders.obstaclesModelAssets;
  private _collectableModelLoader = mLoaders.collectablesModelAssets;

  private _allLoaders!: chip.Chip[];
  private _progressPerLoader!: number[];

  // UI
  private _progressBar!: UI.ProgressBar;
  private _progressText!: PIXI.Text;
  private _infoText!: PIXI.Text;
  private _windowBG!: PIXI.NineSlicePlane;
  private _playButton!: button.Button;
  private _background!: PIXI.Sprite;

  private _renderer!: PIXI.Renderer;
  private _stage!: PIXI.Container;

  // text
  private readonly _loadText = [
    " the Fonts",
    " the Textures",
    " the Animations",
    " the Models",
    " the Music",
    " the Sounds",
    " the Islands",
    " the Trash",
  ];

  protected _onActivate(): void {
    this._renderer = new PIXI.Renderer({
      view: this.chipContext.canvas,
      width: responsive.getScreenWidth(),
      height: responsive.getScreenHeight(),
      backgroundColor: 0x000000,
      resolution: 1.0,
      autoDensity: true,
    });

    this._stage = new PIXI.Container();

    this._allLoaders = [
      this._fontLoader,
      this._textureLoader,
      // this._animationLoader,
      this._commonModelLoader,
      this._musicLoader,
      this._fxLoader,
      this._obstacleModelLoader,
      this._collectableModelLoader,
    ];

    // Start all progress values at 0
    this._progressPerLoader = new Array(this._allLoaders.length).fill(0);
    for (const loader of this._allLoaders) {
      this._subscribeToUpdates(loader);
    }

    this.waitForUpdate = true;

    this._activateChildChip(
      new chip.Sequence([
        // new tLoaders.TextureAssetManager("Preload...", {
        //   Loader_Scroll_White: new URL(
        //     "../public/images/preload/Loader_Scroll_White.png",
        //     import.meta.url
        //   ),
        //   Loader_Scroll_Red: new URL(
        //     "../public/images/preload/Loader_Scroll_Red.png",
        //     import.meta.url
        //   ),
        //   BG_Paper: new URL(
        //     "../public/images/preload/BG_Paper.png",
        //     import.meta.url
        //   ),
        //   Button_Big_Green: new URL(
        //     "../public/images/Button_Big_Green.png",
        //     import.meta.url
        //   ),
        //   BG_Blur: new URL(
        //     "../public/images/preload/BG_Blur.png",
        //     import.meta.url
        //   ),
        // }),
        new tLoaders.TextureAssetManager(
          "preload",
          new URL("../public/images/preload-0.json", import.meta.url).pathname
        ),
        new chip.Lambda(() => {
          // create the UI assets
          this._progressBar = new UI.ProgressBar({
            bg: tLoaders.UINineSlicePlaneAssets.getNineSlice(
              "Loader_Scroll_White"
            ),
            fill: tLoaders.UINineSlicePlaneAssets.getNineSlice(
              "Loader_Scroll_Red"
            ),
          });

          this._progressText = new PIXI.Text("0%", {
            fontFamily: "Hvd Comic Serif Pro",
            fontSize: 48,
            fill: constants.principalColorLight,
            align: "center",
          });

          this._infoText = new PIXI.Text(`Loading ${this._loadText[0]}...`, {
            fontFamily: "Hvd Comic Serif Pro",
            fontSize: 32,
            fill: 0x000000,
            align: "center",
          });

          this._background = new PIXI.Sprite(
            tLoaders.UITextureAssets.getTexture("BG_Blur")
          );

          this._windowBG =
            tLoaders.UINineSlicePlaneAssets.getNineSlice("BG_Paper");

          this.updateUI();

          // add the UI elements to the stage
          this._stage.addChild(this._background);
          this._stage.addChild(this._windowBG);
          this._stage.addChild(this._progressBar);
          this._stage.addChild(this._progressText);
          this._stage.addChild(this._infoText);

          // render the stage
          this._renderer.render(this._stage);
        }),
        () => new chip.Parallel(this._allLoaders),
        () => new chip.Lambda(() => this._startGame()),
      ])
    );
  }

  // subscribe to the loader's onProgress and terminated events
  // onProgress is called every time a loader loads a new asset
  private _subscribeToUpdates(loader: chip.Chip) {
    this._subscribe(loader, "progress", (progress: number) =>
      this._onProgress(loader, progress)
    );
    this._subscribe(loader, "terminated", () => this._onProgress(loader, 1));
  }

  protected _onResize(width: number, height: number): void {
    this._renderer.resize(width, height);
    if (!this.waitForUpdate) this.updateUI();
    this._renderer.render(this._stage);
  }

  /**
   * Called every time a loader loads a new asset,
   * updates the progress bar and text
   * @param progress the progress of the current loader (0-1)
   */
  private _onProgress(loader: chip.Chip, progress: number) {
    const loaderIndex = this._allLoaders.indexOf(loader);
    this._progressPerLoader[loaderIndex] = progress;

    const progressSum = this._progressPerLoader.reduce(
      (sum, value) => sum + value,
      0
    );
    const progressFraction = progressSum / this._allLoaders.length;
    this._progressBar.progress = 100 * progressFraction;

    const text =
      this._loadText[Math.floor(progressFraction * this._loadText.length - 1)];
    this._infoText.text = `Loading${text ?? ""}...`;

    this._progressText.text = `${Math.floor(this._progressBar.progress)}%`;

    this._renderer.render(this._stage);
  }

  private updateUI() {
    this.waitForUpdate = false;

    // set the position and size of the UI elements
    this._progressText.anchor.set(0.5, 0.5);
    this._progressText.position.set(
      this._renderer.width / 2,
      this._renderer.height / 2 - 75
    );

    this._infoText.anchor.set(0.5, 0.5);
    this._infoText.position.set(
      this._renderer.width / 2,
      this._renderer.height / 2 + 100
    );
    (
      (this._progressBar.children[0] as PIXI.Container)
        .children[0] as PIXI.NineSlicePlane
    ).width = this._renderer.width * 0.55;
    (
      (this._progressBar.children[0] as PIXI.Container)
        .children[1] as PIXI.NineSlicePlane
    ).width = this._renderer.width * 0.55;

    this._progressBar.position.set(
      this._renderer.width / 2 - this._progressBar.width / 2,
      this._renderer.height / 2
    );

    this._background.width = this._renderer.width;
    this._background.height = this._renderer.height;

    this._windowBG.width = this._renderer.width * 0.7;
    this._windowBG.width = Math.max(this._windowBG.width, 600);
    this._windowBG.height = this._renderer.height * 0.4;
    this._windowBG.height = Math.max(this._windowBG.height, 300);

    this._windowBG.position.set(
      this._renderer.width / 2 - this._windowBG.width / 2,
      this._renderer.height / 2 - this._windowBG.height / 2
    );

    if (this._playButton) {
      this._playButton;
      this._playButton.container.position.copyFrom({
        x: (this._renderer.width - this._playButton.size.width) / 2,
        y:
          this._windowBG.position.y +
          this._windowBG.height -
          this._playButton.size.height * 0.5,
      });
    }
  }

  /**
   * Called when the user presses the button to start the game and all the assets have been loaded.
   * Terminates the loading screen and starts the game.
   */
  private _startGame() {
    aLoaders.sfxListener.context.resume();
    aLoaders.musicListener.context.resume();

    this.terminate({
      name: "terminated",
      params: {},
    });
  }

  protected _onTerminate(): void {
    this._stage.destroy();
  }

  // // all loaders have finished
  // private _onLoadingDone() {
  //   {
  //     this._playButton = new button.Button({
  //       text: "PLAY",
  //       texture: "Button_Big_Green",
  //       width: 310,
  //       large: true,
  //       position: {
  //         x: (this._renderer.width - 310) / 2,
  //         y: this._windowBG.position.y + this._windowBG.height - 70,
  //       },
  //       onClick: (mode) => {
  //         // COMMENTED BECAUSE OF IPHONE BUG - Fullscreen if touch
  //         const canvas = this._renderer.view as HTMLCanvasElement;
  //         if (mode == "Touch" && canvas.requestFullscreen) {
  //           canvas.requestFullscreen().catch((error) => {
  //             // console.warn("Fullscreen request denied", error);
  //           });
  //         }

  //         this._startGame();
  //       },
  //       shortcutKey: "Enter",
  //     });
  //     this._activateChildChip(this._playButton, {
  //       context: {
  //         container: this._stage,
  //       },
  //     });
  //   }

  //   this._progressBar.progress = 100;
  //   this._progressText.text = "Ready?";

  //   this._stage.removeChild(this._infoText);
  //   this.updateUI();

  //   this._renderer.render(this._stage);
  // }
}
