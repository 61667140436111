import shopDataJSON from "../../shop.json";

import * as PIXI from "pixi.js";

import * as collectable from "../../collectable";
import * as save from "../../save";
import * as shopData from "../../shopData";
import * as unlock from "../../unlock";

import * as shopItem from "./shopItem";

const allShopData = shopDataJSON as shopData.ShopData;

export class CollectableItem extends shopItem.ShopItem {
  constructor(
    public readonly options: shopData.ShopCollectable,
    index: number
  ) {
    super({
      price: options.price,
      name: options.displayName,
      image: collectable.getTextureNameForCollectable(options.name),
      isNew: false,
      behavior: "purchase",
    });
  }

  protected _canBuy(): boolean {
    const saveManager = this.chipContext.saveManager as save.SaveManager;

    // The player can't buy the third key or third unlock, or either one when looking for a bonus
    if (collectable.isKey(this.options.name)) {
      return !saveManager.getBonusToDiscover() && saveManager.keyCount < 2;
    } else if (collectable.isUnlock(this.options.name)) {
      return !saveManager.getBonusToDiscover() && saveManager.unlockCount < 2;
    } else {
      return true;
    }
  }

  protected _onBuy() {
    const saveManager = this.chipContext.saveManager as save.SaveManager;
    if (collectable.isBonus(this.options.name)) {
      saveManager.setCollectableCount(
        this.options.name,
        saveManager.getCollectableCount(this.options.name) + 1
      );
    } else if (collectable.isKey(this.options.name)) {
      saveManager.keyCount = saveManager.keyCount + 1;
    } else if (collectable.isUnlock(this.options.name)) {
      saveManager.unlockCount = saveManager.unlockCount + 1;
    } else {
      throw new Error("Unknown collectable type");
    }
  }

  protected _onActivate(): void {
    super._onActivate();

    const descriptionText = new PIXI.Text(this.options.description, {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 22,
      fill: 0x000000,
      wordWrap: true,
      wordWrapWidth: 650,
    });
    descriptionText.position.set(shopItem.backgroundSize.y, 70);
    this._background.addChild(descriptionText);
  }
}

export function makeCollectableItems(saveManager: save.SaveManager) {
  return allShopData.collectables
    .filter((options) => {
      if (collectable.isBonus(options.name)) {
        return saveManager.unlockedBonuses.includes(
          options.name as collectable.BonusName
        );
      } else if (collectable.isKey(options.name)) {
        return (
          saveManager.unlockedBonuses.length >
          saveManager.unlockedDropZones.length
        );
      } else if (collectable.isUnlock(options.name)) {
        const nextUnlock = collectable.makeUnlockForBonus(
          unlock.getNextBonusToUnlock(
            saveManager.unlockedBonuses
          ) as collectable.BonusName
        );
        return nextUnlock === options.name;
      } else {
        throw new Error("Unknown collectable " + options.name);
      }
    })
    .map((options, index) => new CollectableItem(options, index));
}
