import * as chip from "booyah/dist/chip";

import * as tLoaders from "./loaders/textureLoaders";

import * as collectable from "./collectable";

export function getNextBonusToUnlock(
  unlockedBonuses: collectable.BonusName[]
): collectable.BonusName | undefined {
  if (unlockedBonuses.length >= collectable.bonusDiscoveryOrder.length) return;

  return collectable.bonusDiscoveryOrder[unlockedBonuses.length];
}

export function getNextDropZoneToUnlock(
  unlockedBonuses: collectable.BonusName[],
  unlockedDropZones: collectable.BonusName[]
): collectable.BonusName | undefined {
  if (unlockedDropZones.length >= unlockedBonuses.length) return;

  return collectable.bonusDiscoveryOrder[unlockedDropZones.length];
}

export function getUnlockIconAssetName(unlockName: collectable.UnlockName) {
  return ("icon-" +
    unlockName.toLowerCase().replaceAll("_", "-")) as tLoaders.TextureAssetName;
}

export class UnlockManager extends chip.Composite {
  private _keyCount?: number;
  private _unlockCount?: number;

  get keyCount(): number {
    return this._keyCount || 0;
  }

  set keyCount(value: number) {
    if (value > 3) {
      console.error("Can't get more than 3 keys");
      value = 3;
    }

    this._keyCount = value;
    this.emit("changedKeyCount", value);
  }

  acquireKey() {
    this.keyCount = this.keyCount + 1;
    this.emit("acquiredKey");
  }

  get unlockCount(): number {
    return this._unlockCount || 0;
  }

  set unlockCount(value: number) {
    if (value > 3) {
      console.error("Can't get more than 3 unlocks");
      value = 3;
    }

    this._unlockCount = value;
    this.emit("changedUnlockCount", value);
  }

  acquireUnlock() {
    this.unlockCount = this.unlockCount + 1;
    this.emit("acquiredUnlock");
  }
}
