import * as THREE from "three";

import * as mLoaders from "./loaders/modelLoaders";

import * as gameObject from "./gameObject";
import * as responsive from "./responsive";
import * as threeObject from "./threeObject";
import * as utils from "./utils";

export class NetFullFX extends responsive.ResponsiveChip {
  private _model!: threeObject.ThreeObject;

  constructor(private _repeat = 1) {
    super();
  }

  protected _onActivate(): void {
    // Full net animation
    const model = mLoaders.commonModelAssets.getModelInfo("NET_FULL_FX");
    utils.makeAnimatedMaterialFloating(model);
    this._model = new threeObject.ThreeObject(model, this.chipContext.scene);

    // Terminate chip when animation is complete
    this._model.addListener("animationComplete", this.terminate.bind(this));

    // Activate all chips
    this._activateChildChip(this._model);

    // Start animation manually
    if (this._repeat > 1) this._model.startAnimations(THREE.LoopRepeat, 3);
    else this._model.startAnimations(THREE.LoopOnce, 1);
  }

  protected _onTick(): void {
    // Get net object
    const netObject = this.chipContext.level.boat.net
      .gameObject as gameObject.GameObject;
    // Copy position
    const netPos = netObject.getPosition();
    const pos = new THREE.Vector3(netPos.x, 0, netPos.y);
    this._model.setPosition(pos);
    // Copy angle + 60° CCW
    const netAngle = netObject.getAngle() + Math.PI / 3;
    this._model.setAngle(netAngle);
    // Copy scale
    const netScale = netObject.getThreeObject().getScale();
    this._model.setScale(netScale.x, netScale.y, netScale.z);
  }
}
