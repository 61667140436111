import * as PIXI from "pixi.js";

import * as booyahPixi from "booyah-pixi/dist/booyahPixi";

import * as constants from "../../constants";
import * as responsive from "../../responsive";

export class Bubble extends responsive.ResponsiveChip {
  private _container!: PIXI.Container;

  public get container(): PIXI.Container {
    return this._container;
  }

  private _bubbleLayer!: PIXI.Container;
  private _itemsCountBubble!: booyahPixi.AnimatedSpriteChip;
  private _itemsCountText!: PIXI.Text;
  private _itemsMaxText!: PIXI.Text;

  protected _onActivate(): void {
    this._container = new PIXI.Container();

    // this._itemsCountBubble = tLoaders.UIAnimationAssets.getAnimation(
    //   "Net_Items",
    //   {
    //     play: false,
    //     loop: false,
    //     animationSpeed: 0.5,
    //   }
    // );
    // this._itemsCountBubble.anchor.set(0.5);

    this._bubbleLayer = new PIXI.Container();
    this._container.addChild(this._bubbleLayer);

    this._itemsCountText = new PIXI.Text("00", {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 60,
      fill: constants.almostBlack,
      align: "right",
    });
    this._itemsCountText.anchor.set(1, 0.7);

    this._itemsMaxText = new PIXI.Text("/00", {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 24,
      fill: constants.almostBlack,
      align: "left",
    });
    this._itemsMaxText.anchor.set(0, 0.6);

    // this._container.addChild(this._itemsCountBubble);
    this._container.addChild(this._itemsCountText);
    this._container.addChild(this._itemsMaxText);

    this._container.visible = false;

    this.chipContext.container.addChild(this._container);
  }

  protected _onTick(): void {
    // Show texts only during "full bubble" frames
    this._itemsCountText.visible = this._itemsMaxText.visible =
      this._itemsCountBubble &&
      this._itemsCountBubble.pixiSprite.currentFrame > 10 &&
      this._itemsCountBubble.pixiSprite.currentFrame < 78;
  }

  public show(currentItems: number, isUpgrade = false) {
    // Update texts
    if (isUpgrade) {
      this._itemsCountText.anchor.set(0.75, 0.7);
      this._itemsCountText.text = `+${currentItems}`;
      this._itemsMaxText.text = "";
    } else {
      this._itemsCountText.anchor.set(1, 0.7);
      this._itemsCountText.text = currentItems.toString().padStart(2, "0");
      this._itemsMaxText.text = `/${this.chipContext.playerInfo.currentNetCapacity
        .toString()
        .padStart(2, "0")}`;
    }

    // Force no looping (for some reason it gets reset)
    // this._itemsCountBubble.loop = false;

    // // Play from the first "full bubble" frame if it was already playing
    // if (this._itemsCountBubble.pixiSprite.playing)
    //   this._itemsCountBubble.pixiSprite.gotoAndPlay(20);
    // // Play from the start if not
    // else this._itemsCountBubble.pixiSprite.gotoAndPlay(0);

    // // Play from the first "full bubble" frame if it was already playing
    const startingFrame = this._itemsCountBubble ? 20 : 0;

    this._activateChildChip(
      new booyahPixi.AnimatedSpriteChip(PIXI.Assets.get("ui"), {
        animationName: "net-capacity/net-capacity",
        animationSpeed: 0.05,
        anchor: 0.5,
        startingFrame,
      }),
      {
        attribute: "_itemsCountBubble",
        context: {
          container: this._bubbleLayer,
        },
      }
    );

    // Show container
    this._container.visible = true;
  }
}
