import * as event from "@pixi/interaction";
import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

import * as tLoaders from "../../loaders/textureLoaders";

import * as utils from "../../utils";

import * as button from "./button";

export interface InputRangeOptions {
  icon: tLoaders.TextureAssetName;
  icoff: tLoaders.TextureAssetName;
  /**
   * 0-1
   */
  initialValue: number;
  position: PIXI.IPointData;
  width: number;
  /**
   * @param value 0-1
   */
  onUpdate: (value: number) => unknown;
}

export class InputRange extends chip.Composite {
  readonly iconSpace = 100;

  public container!: PIXI.Container;

  private _barContainer!: PIXI.Container;
  private _dotContainer!: PIXI.Container;

  private _icon!: PIXI.Sprite;
  private _icoff!: PIXI.Sprite;
  private _bar!: PIXI.NineSlicePlane;
  private _fill!: PIXI.NineSlicePlane;
  private _dot!: PIXI.Sprite;
  private _selectedDot!: PIXI.Sprite;
  private _isSelected!: boolean;
  private _barWidth!: number;

  private get _globalBarWidth() {
    return this._bar.getBounds().width;
  }

  constructor(private _options: InputRangeOptions) {
    super();
  }

  get isSelected() {
    return this._isSelected;
  }

  set isSelected(value) {
    this._isSelected = value;

    this._selectedDot.visible = value;
  }

  private _initContainers() {
    this.container = new PIXI.Container();

    this.container.position.copyFrom(this._options.position);

    this._barContainer = new PIXI.Container();
    this._dotContainer = new PIXI.Container();

    this._barContainer.position.x = this.iconSpace;
    this._dotContainer.position.x = this.iconSpace;

    this.container.addChild(this._barContainer, this._dotContainer);

    this.chipContext.container.addChild(this.container);
  }

  private _initSprites() {
    const x = this._valueToX(this._options.initialValue);

    {
      this._icon = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture(this._options.icon)
      );

      this._icon.anchor.set(0, 0.5);
      this._icon.visible = this._options.initialValue > 0;

      utils.hoverEffect.bind(this)(this._icon);

      this.container.addChild(this._icon);
    }

    {
      this._icoff = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture(this._options.icoff)
      );

      this._icoff.anchor.set(0, 0.5);
      this._icoff.visible = this._options.initialValue === 0;

      utils.hoverEffect.bind(this)(this._icoff);

      this.container.addChild(this._icoff);
    }

    {
      this._bar = tLoaders.UINineSlicePlaneAssets.getNineSlice(
        "Loader_Scroll_White",
        {
          x: this._barWidth,
          y: 10,
        }
      );

      this._barContainer.addChild(this._bar);
    }

    {
      this._fill = tLoaders.UINineSlicePlaneAssets.getNineSlice(
        "Loader_Scroll_Red",
        {
          x,
          y: 10,
        }
      );

      this._barContainer.addChild(this._fill);
    }

    {
      this._dot = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture("Hexagon_Red")
      );

      this._dot.anchor.set(0.5);
      this._dot.scale.set(0.8);
      this._dot.position.x = x;

      utils.hoverEffect.bind(this)(this._dot);

      this._dotContainer.addChild(this._dot);
    }

    {
      this._selectedDot = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture("Hexagon_Red")
      );

      this._selectedDot.anchor.set(0.5);
      this._selectedDot.visible = false;
      this._selectedDot.alpha = 0.5;
      this._selectedDot.scale.set(1.5);

      this._dot.addChild(this._selectedDot);
    }
  }

  private _initListeners() {
    this.container.eventMode = "dynamic";
    this.container.buttonMode = true;

    this.container.hitArea = new PIXI.Rectangle(
      0,
      -40,
      this._options.width + 20,
      80
    );

    this._subscribe(
      this.container,
      "pointerdown",
      (e: event.InteractionEvent) => {
        this.isSelected = true;
        this._onPointerMove();
      }
    );
    this._subscribe(
      window.document,
      "pointerup",
      () => (this.isSelected = false)
    );
    this._subscribe(
      window.document,
      "pointerout",
      () => (this.isSelected = false)
    );
    this._subscribe(window.document, "pointermove", () =>
      this._onPointerMove()
    );
  }

  protected _onActivate() {
    this._isSelected = false;
    this._barWidth = this._options.width - this.iconSpace;

    this._initContainers();
    this._initSprites();
    this._initListeners();

    this.moveDot(this._valueToX(this._options.initialValue));
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this.container);

    this.container.destroy();
  }

  /**
   * Move the dot on X axe
   */
  public moveDot(x: number) {
    this._dot.position.x = x;
    this._fill.width = x;

    this._onUpdate(this._xToValue(x));
  }

  private _onUpdate(value: number) {
    this._options.onUpdate(value);
    this._icoff.visible = value === 0;
    this._icon.visible = value > 0;
  }

  private _onPointerMove() {
    const dotX =
      (utils.getPointerPosition().x -
        this._dotContainer.getGlobalPosition().x) *
      (this._barWidth / this._globalBarWidth);

    if (!this.isSelected) return;

    this.moveDot(Math.max(0, Math.min(this._barWidth, dotX)));
  }

  private _xToValue(x: number): number {
    return x / this._barWidth;
  }

  private _valueToX(value: number): number {
    return value * this._barWidth;
  }
}

export interface InputSwitchOptions {
  icon: tLoaders.TextureAssetName;
  icoff: tLoaders.TextureAssetName;
  initialValue: boolean;
  position: PIXI.IPointData;
  width: number;
  onUpdate: (value: boolean) => unknown;
}

export class InputSwitch extends chip.Composite {
  readonly iconSpace = 100;

  public container!: PIXI.Container;

  private _icon!: PIXI.Sprite;
  private _icoff!: PIXI.Sprite;

  private _yesButton!: button.Button;
  private _noButton!: button.Button;

  constructor(private _options: InputSwitchOptions) {
    super();
  }

  protected _onActivate() {
    this.container = new PIXI.Container();

    this.container.position.copyFrom(this._options.position);

    this.container.addChild(
      (this._icon = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture(this._options.icon)
      ))
    );

    this.container.addChild(
      (this._icoff = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture(this._options.icoff)
      ))
    );

    const buttonWidth = (this._options.width - this.iconSpace) / 2;

    this._activateChildChip(
      (this._noButton = new button.Button({
        text: "No",
        texture: "Button_Big_Red",
        width: buttonWidth,
        position: { x: this.iconSpace, y: 0 },
        onClick: () => this._onChange(false),
      })),
      {
        context: {
          container: this.container,
        },
      }
    );

    this._activateChildChip(
      (this._yesButton = new button.Button({
        text: "Yes",
        texture: "Button_Big_Green",
        width: buttonWidth,
        position: { x: this.iconSpace + buttonWidth + 10, y: 0 },
        onClick: () => this._onChange(true),
      })),
      {
        context: {
          container: this.container,
        },
      }
    );

    this._onChange(this._options.initialValue, false);

    this.chipContext.container.addChild(this.container);
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this.container);
  }

  private _onChange(value: boolean, updateData = true) {
    this._icon.visible = value;
    this._icoff.visible = !value;

    this._yesButton.container.alpha = value ? 1 : 0.8;
    this._noButton.container.alpha = !value ? 1 : 0.8;

    if (updateData) this._options.onUpdate(value);
  }
}
