import * as PIXI from "pixi.js";

import * as booyahPixi from "booyah-pixi/dist/booyahPixi";
import * as chip from "booyah/dist/chip";
import * as tween from "booyah/dist/tween";

import * as aLoaders from "../loaders/audioLoaders";
import * as tLoaders from "../loaders/textureLoaders";

import * as collectable from "../collectable";
import * as constants from "../constants";
import * as level from "../level";
import * as params from "../params";
import * as powerup from "../powerup";
import * as responsive from "../responsive";
import * as save from "../save";
import * as unlock from "../unlock";
import * as utils from "../utils";

import * as lootList from "./components/lootList";
import * as moneyView from "./components/moneyView";
import * as popup from "./components/popup";
import * as timer from "./components/timer";
import * as menuPopup from "./hud/menu";

export class HUD extends responsive.ResponsiveChip {
  private _container!: PIXI.Container;

  // responsive containers
  private _leftContainer!: PIXI.Container;
  private _rightContainer!: PIXI.Container;
  private _bottomContainer!: PIXI.Container;
  private _rightBottomContainer!: PIXI.Container;
  private _leftBottomContainer!: PIXI.Container;

  private _leftRowsY!: number[];

  // game info
  private _levelText!: PIXI.Text;
  private _scoreText!: PIXI.Text;
  private _scoreBackground!: PIXI.Sprite;
  private _powerUpContainer!: PIXI.Container;
  private _powerUpCounts!: Record<powerup.Powerup, PIXI.Text>;
  private _unlockContainer!: PIXI.Container;
  private _menu?: menuPopup.Menu;
  private _objectiveLootList?: lootList.LootList;
  private _gameTimer?: timer.GameTimer;

  protected _onActivate(): void {
    this._container = new PIXI.Container();
    this._leftContainer = new PIXI.Container();
    this._rightContainer = new PIXI.Container();
    this._bottomContainer = new PIXI.Container();
    this._rightBottomContainer = new PIXI.Container();
    this._leftBottomContainer = new PIXI.Container();

    this._container.addChild(
      this._leftContainer,
      this._bottomContainer,
      this._rightBottomContainer,
      this._leftBottomContainer
    );

    this._leftRowsY = [10, 50];

    // The menu button is in the overlay container
    this.chipContext.overlayContainer.addChild(this._rightContainer);

    // Score background
    {
      this._scoreBackground = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture("BG_Score")
      );

      this._scoreBackground.position.set(-15, this._leftRowsY[0]);

      // Adjust row 2 position
      this._leftRowsY[1] =
        this._scoreBackground.y + this._scoreBackground.height + 15;

      this._leftContainer.addChild(this._scoreBackground);
    }

    // Score
    {
      this._scoreText = new PIXI.Text("0", {
        fontFamily: "Hvd Comic Serif Pro",
        fontSize: 60,
        fill: 0xffffff,
        align: "right",
      });

      this._scoreText.anchor.set(1, 0.55);
      this._scoreText.position.set(
        this._scoreBackground.width - 15,
        this._scoreBackground.height / 2
      );

      this._scoreBackground.addChild(this._scoreText);
    }

    // Level
    {
      this._levelText = new PIXI.Text(
        `LEVEL ${
          (this.chipContext.saveManager as save.SaveManager).currentLevel
        }`,
        {
          fontFamily: "Hvd Comic Serif Pro",
          fontSize: 32,
          fill: 0xffffff,
        }
      );
      this._levelText.anchor.set(0, 0.5);
      this._levelText.position.set(
        this._scoreBackground.width + 15,
        this._leftRowsY[0] + this._scoreBackground.height / 2
      );

      this._leftContainer.addChild(this._levelText);
    }

    // Money
    this._activateChildChip(new moneyView.MoneyView(), {
      context: {
        container: this._container,
      },
    });

    // Settings
    {
      const settingsIcon = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture("Button_Settings")
      );

      settingsIcon.anchor.set(1, 0.5);
      settingsIcon.position.set(-10, 30);
      settingsIcon.eventMode = "dynamic";

      this._subscribe(settingsIcon, "pointertap", this._onOpenMenu);

      this._subscribe(document, "keydown", (event) => {
        if (event.key === "Escape") this._onOpenMenu();
      });

      utils.hoverEffect.bind(this)(settingsIcon);

      this._rightContainer.addChild(settingsIcon);
    }

    // Unlocks
    {
      this._unlockContainer = new PIXI.Container();
      this._unlockContainer.position.set(-275, 30);

      this._rightContainer.addChild(this._unlockContainer);

      // TODO: show appear animations

      this._updateUnlockContainer();
      this._subscribe(
        this.chipContext.unlockManager,
        "changedKeyCount",
        this._updateUnlockContainer
      );
      this._subscribe(
        this.chipContext.unlockManager,
        "changedUnlockCount",
        this._updateUnlockContainer
      );
      this._subscribe(
        this.chipContext.unlockManager,
        "acquiredKey",
        this._onAcquiredKey
      );
      this._subscribe(
        this.chipContext.unlockManager,
        "acquiredUnlock",
        this._onAcquiredUnlock
      );
    }

    // Powerup buttons
    {
      // Setup container
      this._powerUpContainer = new PIXI.Container();
      this._rightBottomContainer.addChild(this._powerUpContainer);

      const iconWidth =
        tLoaders.UITextureAssets.getTexture("icon-button-speed").width;
      const iconHeight =
        tLoaders.UITextureAssets.getTexture("icon-button-speed").height;
      const textWidth = 80;

      // @ts-ignore
      this._powerUpCounts = {};
      const unlockedPowerups = powerup.powerups
        .filter(
          (p) =>
            params.shouldUnlockAllBonuses() ||
            this.chipContext.saveManager.unlockedBonuses.includes(p) ||
            (this.chipContext.level.options.bonusToDiscover &&
              p === this.chipContext.level.options.bonusToDiscover)
        )
        .toReversed();

      unlockedPowerups.forEach((p, i) => {
        // The container is verticalled centered, horizontally to the right
        const iconContainer = new PIXI.Container();
        iconContainer.position.set(
          -constants.powerupButtonMargin +
            -i * (iconWidth + textWidth + constants.powerupButtonGap),
          -constants.powerupButtonMargin - iconHeight / 2
        );
        this._powerUpContainer.addChild(iconContainer);

        // Add icon to the left
        const textureName = `icon-button-${p
          .substring("bonus_".length)
          .toLowerCase()}` as tLoaders.TextureAssetName;
        const powerUpIcon = new PIXI.Sprite(
          tLoaders.UITextureAssets.getTexture(textureName)
        );
        powerUpIcon.position.set(-textWidth, 0);
        powerUpIcon.anchor.set(1, 0.5);
        powerUpIcon.eventMode = "static";
        iconContainer.addChild(powerUpIcon);

        // Setup pointer event
        const usePowerup = () => {
          if (!this.chipContext.powerupManager.canUsePowerup(p)) return;

          this.chipContext.powerupManager.usePowerup(p);
        };
        this._subscribe(powerUpIcon, "pointertap", usePowerup);

        // Setup keyboard shortcut
        const keyboardAction = `powerup${powerup.powerups.indexOf(p) + 1}`;
        this._subscribe(
          this.chipContext.controls.keyboard,
          keyboardAction,
          usePowerup
        );

        // Add text to the right
        const powerUpCount = new PIXI.Text("", {
          fontFamily: "Hvd Comic Serif Pro",
          fontSize: 40,
          fill: constants.black,
          stroke: constants.principalColorDark,
        });
        powerUpCount.anchor.set(1, 0.5);
        this._powerUpCounts[p] = powerUpCount;
        iconContainer.addChild(powerUpCount);
      });
    }

    // Set initial counts and listen for updates
    this._updatePowerupDisplay();
    this._subscribe(
      this.chipContext.powerupManager,
      "changedCount",
      this._updatePowerupDisplay
    );

    // Add main container
    this.chipContext.container.addChild(this._container);

    this._subscribe(
      this.chipContext.playerInfo,
      "scoreUpdate",
      (score: number) => {
        this.updateScore(score);
      }
    );

    this._subscribe(
      this.chipContext.playerInfo,
      "netHpUpdate",
      (hp: number) => {
        this.updateNetLives(hp);
      }
    );
    this.updateNetLives(this.chipContext.playerInfo.netHp);

    this._subscribe(
      this.chipContext.playerInfo,
      "lootUpdated",
      (trash: collectable.TrashName) => {
        if (this._objectiveLootList) this._objectiveLootList.refreshView();
      }
    );

    this._subscribe(this.chipContext.playerInfo, "netFull", () => {
      this._activateChildChip(
        new popup.BottomPopup({
          text: "Your net is full, go back home to empty it!",
          icon: "objective-icons/objective-icon-lighthouse",
        })
      );
    });

    this._subscribe(this.chipContext.playerInfo, "netSizeUpgraded", () => {
      this._activateChildChip(
        new popup.BottomPopup({
          text: "Your net has been upgraded, you can catch more waste!",
          icon: "icon-upgrade-net",
        })
      );
    });
  }

  protected _onResize(width: number, height: number) {
    this._rightContainer.position.set(width, 0);
    this._bottomContainer.position.set(0, height);
    this._rightBottomContainer.position.set(width, height);
    this._leftBottomContainer.position.set(0, height);

    if (responsive.isMobile()) {
      this._leftContainer.scale.set(0.5);
      this._rightContainer.scale.set(0.5);
      this._rightBottomContainer.scale.set(0.4);
    } else if (responsive.isTablet()) {
      this._leftContainer.scale.set(0.7);
      this._rightContainer.scale.set(0.75);
      this._rightBottomContainer.scale.set(0.75);
    } else {
      this._leftContainer.scale.set(1);
      this._rightContainer.scale.set(1);
      this._rightBottomContainer.scale.set(1);
    }
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this._container);
    this._container.destroy();

    // The menu button is in the overlay container
    this.chipContext.overlayContainer.removeChild(this._rightContainer);
    this._rightContainer.destroy();
  }

  public updateNetLives(hp: number) {
    // todo: implement net hp interface
  }

  public updateScore(score: number) {
    // const diff = score - this._scoreOld;

    this._activateChildChip(
      utils.textEditAnimation(this._scoreText, score.toLocaleString())
    );

    // const text = new PIXI.Text(
    //   `${diff > 0 ? "+" : ""}${diff.toLocaleString()}`,
    //   {
    //     fontFamily: "Hvd Comic Serif Pro",
    //     fontSize: 40,
    //     fill: diff > 0 ? 0x4bdb71 : 0xe80e36,
    //     stroke: 0x000000,
    //     strokeThickness: 3,
    //   }
    // );

    // text.position.set(
    //   this._scoreText.position.x + 10,
    //   this._scoreText.position.y
    // );

    // this._container.addChild(text);

    // this._activateChildChip(
    //   new utils.RepeatChip((count) => {
    //     if (count > 20) {
    //       this._container.removeChild(text);
    //       text.destroy();
    //       return true;
    //     }
    //     text.position.x += 2;
    //     text.position.y++;
    //     text.alpha = 1 - count / 20;
    //     return false;
    //   })
    // );

    // this._scoreOld = score;
  }

  makePointsFeedback(
    amount: number,
    screenPos: PIXI.IPointData = new PIXI.Point()
  ) {
    return this.makeTextFeedback(
      amount > 0 ? `+${amount}` : amount.toString(),
      screenPos,
      amount > 0 ? constants.green : constants.principalColorLight,
      amount > 0 ? constants.greenDark : constants.principalColorDark
    );
  }

  makeMoneyFeedback(
    amount: number,
    screenPos: PIXI.IPointData = new PIXI.Point()
  ) {
    return this.makeTextFeedback(
      `+${amount}`,
      screenPos,
      constants.moneyColor,
      constants.moneyColorDark
    );
  }

  makeTextFeedback(
    text: string,
    screenPos: PIXI.IPointData = new PIXI.Point(),
    fill: number = constants.white,
    stroke: number = constants.almostBlack
  ) {
    const textObject = new PIXI.Text(text, {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 60,
      fill,
      stroke,
      strokeThickness: 20,
    });
    textObject.anchor.set(0.5);
    textObject.position.copyFrom(screenPos);

    // Clone in case screenPos changes over time
    const from = new PIXI.Point(screenPos.x, screenPos.y);
    const to = new PIXI.Point(screenPos.x, screenPos.y - 200);

    return new chip.Alternative([
      {
        chip: new booyahPixi.DisplayObjectChip(textObject),
        context: {
          container: this._container,
        },
      },
      new tween.Tween({
        obj: textObject,
        property: "position",
        from,
        to,
        duration: 1000,
        interpolate: booyahPixi.lerpPoint,
        easing: "easeInSine",
      }),
    ]);
  }

  private _onOpenMenu() {
    if (this._menu) return;

    aLoaders.playFx("button");

    this._activateChildChip(new menuPopup.Menu(), {
      attribute: "_menu",
    });
  }

  makeObjectiveLootList(objectives: Partial<level.LootValues>) {
    this._objectiveLootList = new lootList.LootList({
      headerText: "Objective",
      y: this._leftRowsY[1],
      objectives,
    });

    this._activateChildChip(this._objectiveLootList, {
      context: {
        container: this._leftContainer,
      },
    });
  }

  get objectiveLootList() {
    return this._objectiveLootList;
  }

  makeGameTimer(duration: number) {
    const gameTimer = new timer.GameTimer(duration);
    return this._activateChildChip(gameTimer, {
      context: {
        container: this._container,
      },
      attribute: "_gameTimer",
    });
  }

  get gameTimer() {
    return this._gameTimer;
  }

  showBottomPopup(options: Partial<popup.BottomPopupOptions>) {
    this._activateChildChip(new popup.BottomPopup(options));
  }

  private _updateUnlockContainer() {
    const iconSpacing = 60;

    this._unlockContainer.removeChildren();

    const unlockManager = this.chipContext
      .unlockManager as unlock.UnlockManager;
    let iconIndex = 0;

    if (unlockManager.unlockCount > 0) {
      const nextBonusToUnlock = unlock.getNextBonusToUnlock(
        this.chipContext.saveManager.unlockedBonuses
      );
      if (!nextBonusToUnlock) {
        throw new Error("No bonus to unlock");
      }

      const unlockName = collectable.makeUnlockForBonus(nextBonusToUnlock);
      const iconName = unlock.getUnlockIconAssetName(unlockName);

      for (let i = 0; i < unlockManager.unlockCount; i++) {
        const sprite = new PIXI.Sprite(
          tLoaders.UITextureAssets.getTexture(
            iconName as tLoaders.TextureAssetName
          )
        );
        sprite.scale.set(0.5); // TODO: Maybe just lower the size of the exported asset?
        sprite.anchor.set(0.5);
        sprite.position.set(-iconSpacing * iconIndex, 0);
        this._unlockContainer.addChild(sprite);

        iconIndex++;
      }
    }

    for (let i = 0; i < unlockManager.keyCount; i++) {
      const sprite = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture("icon-unlock-key")
      );
      sprite.scale.set(0.5); // TODO: Maybe just lower the size of the exported asset?
      sprite.anchor.set(0.5);
      sprite.position.set(-iconSpacing * iconIndex, 0);
      this._unlockContainer.addChild(sprite);

      iconIndex++;
    }
  }

  private _onAcquiredKey() {
    this.showBottomPopup({
      text: "You picked up a key to unlock a new drop zone",
      icon: "icon-unlock-key",
    });
  }

  private _onAcquiredUnlock() {
    const icon = unlock.getUnlockIconAssetName(
      this.chipContext.level.options.seaMapOptions.includeUnlock
    );

    this.showBottomPopup({
      text: "You picked up a item to unlock a bonus",
      icon,
    });
  }

  private _updatePowerupDisplay() {
    Object.keys(this._powerUpCounts).forEach((p) => {
      this._powerUpCounts[
        p
      ].text = `x${this.chipContext.powerupManager.getCount(p)}`;
    });
  }
}
