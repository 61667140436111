import * as PLANCK from "planck-js";
import * as THREE from "three";
// TODO: use Stats as a separate module
import Stats from "three/examples/jsm/libs/stats.module.js";

import * as chip from "booyah/dist/chip";

import * as aLoaders from "./loaders/audioLoaders";
import * as tLoaders from "./loaders/textureLoaders";

import * as boat from "./boat";
import * as collectable from "./collectable";
import * as constants from "./constants";
import * as environment from "./environment";
import * as timer from "./gui/components/timer";
import * as controls from "./gui/controls";
import * as hud from "./gui/hud";
import * as gameOver from "./gui/hud/gameOver";
import * as shop from "./gui/hud/shop";
import * as itemAnimation from "./itemAnimation";
import * as levelSystem from "./levelSystem";
import * as net from "./net";
import * as obstacle from "./obstacle";
import * as obstaclePrefabs from "./obstaclePrefabs";
import * as params from "./params";
import * as playerCamera from "./playerCamera";
import * as playerInfo from "./playerInfo";
import * as powerup from "./powerup";
import * as responsive from "./responsive";
import * as save from "./save";
import * as seaMap from "./seaMap";
import * as unlock from "./unlock";
import * as utils from "./utils";

export type LevelState = "beforePlay" | "playing" | "afterPlay";
export type LevelResult = "success" | "failure";

export type LootValues = Record<collectable.TrashName | "TOTAL", number>;

export class LevelOptions {
  levelNumber!: number;
  seaMapOptions!: seaMap.SeaMapOptions;
  lootObjectives!: Partial<LootValues>;
  duration?: number;
  bonusToDiscover?: collectable.BonusName;
  tutorial?: chip.ChipResolvable;
}

export class Level extends responsive.ResponsiveChip {
  private _options: LevelOptions;

  private _levelState!: LevelState;
  private _isPaused!: boolean;
  private _levelResult?: LevelResult;
  private _discoveredBonus!: boolean;

  private _ocean!: environment.Environment;
  private _boat?: boat.Boat;
  private _world!: PLANCK.World;
  private _map!: seaMap.SeaMap;
  private _controls!: controls.Controls;
  private _powerupManager!: powerup.PowerupManager;
  private _unlockManager!: unlock.UnlockManager;
  private _hud!: hud.HUD;

  constructor(options: Partial<LevelOptions>) {
    super();

    this._options = chip.fillInOptions(options, new LevelOptions());
  }

  get options() {
    return this._options;
  }

  get boat() {
    return this._boat;
  }

  get defaultChildChipContext() {
    return {
      controls: this._controls,
      powerupManager: this._powerupManager,
      unlockManager: this._unlockManager,
      hud: this._hud,
      level: this,
      world: this._world,
    };
  }

  private _setupBoat(boatName: boat.BoatName, netName: net.NetName) {
    this._boat = new boat.Boat(this._map, boatName, netName);
    this._activateChildChip(this._boat);

    // Keep the boat from moving at first
    this._boat.isMovementPrevented = true;

    /*
        Defining event when an item is deposed
    */
    {
      this._subscribe(
        this._boat.net,
        "deposedItem",
        (
          collectable: collectable.TrashName,
          id: number,
          offset: THREE.Vector2
        ) => {
          this.deposeAnimation(collectable, id, this._boat!.net, offset);
          this.chipContext.playerInfo.incrementRecycledTrashCounter(
            collectable
          );
        }
      );
    }

    this._subscribe(this._controls.keyboard, "dropItems", () => {
      if (this._levelState !== "playing") return;

      this._dropItems();
    });

    this._subscribe(this._boat, "gotBonus", (bonus: collectable.BonusName) => {
      if (
        this._options.bonusToDiscover &&
        this._options.bonusToDiscover === bonus
      ) {
        this._discoveredBonus = true;
      }
    });
  }

  protected _onActivate(): void {
    const saveManager = this.chipContext.saveManager as save.SaveManager;

    this._isPaused = false;
    this._levelState = "beforePlay";
    this._discoveredBonus = false;

    this.chipContext.playerInfo.reset();
    this._subscribe(
      this.chipContext.playerInfo,
      "lootUpdated",
      this._onLootUpdated
    );

    if (!params.hasInfiniteLives()) {
      this._subscribe(this.chipContext.playerInfo, "hpUpdate", (hp: number) => {
        if (hp <= 0) this._onGameOver("failure");
      });
    }

    this._controls = new controls.Controls();

    /*
        Creating Planck world
    */
    this._world = PLANCK.World({
      gravity: PLANCK.Vec2(0, 0),
      continuousPhysics: false,
    });

    /*
        Creating other custom classes
    */
    {
      this._hud = new hud.HUD();

      this._ocean = new environment.Environment(
        this.chipContext.scene,
        this.chipContext.waterScene
      );
    }

    // TODO: not sure this is the right place to play
    // Also need to stop and restore music when needed
    aLoaders.playMusic("ocean");

    // Setup PowerupManager
    {
      const initialCounts = Object.fromEntries(
        powerup.powerups.map((p) => [
          p,
          params.hasFullInventory() ? 99 : saveManager.getCollectableCount(p),
        ])
      );
      this._powerupManager = new powerup.PowerupManager(initialCounts);
      this._activateChildChip(this._powerupManager);

      // Listen to changes in the save to know when more items are bought in the shop
      this._subscribe(saveManager, "changedCollectableCount", (name, value) =>
        this._powerupManager.setCount(name, value)
      );
    }

    // Setup UnlockManager
    {
      this._unlockManager = new unlock.UnlockManager();
      this._unlockManager.unlockCount = saveManager.unlockCount;
      this._unlockManager.keyCount = saveManager.keyCount;
      this._activateChildChip(this._unlockManager);

      // Listen to changes in the save to know when more items are bought in the shop
      this._subscribe(
        saveManager,
        "changedUnlockCount",
        (value) => (this._unlockManager.unlockCount = value)
      );
      this._subscribe(
        saveManager,
        "changedKeyCount",
        (value) => (this._unlockManager.keyCount = value)
      );
    }

    /*
        Activate all of the other game's chip.
        This order is important bacause some of them need to be
        activate before/after others.
    */
    this._activateChildChip(this._hud, {
      context: {
        container: this.chipContext.uiContainer,
      },
    });

    this._activateChildChip(this._ocean);

    this._map = new seaMap.SeaMap(this._options.seaMapOptions);
    this._activateChildChip(this._map);

    // Reset camera for starting move
    const cam = this.chipContext.camera as playerCamera.PlayerCamera;
    cam.startingMoveProgress = 0;

    this._setupPhysicsEventHandlers();

    // TODO: temporary testing. Use control sequence instead of makeGameOverScreen
    // this._activateChildChip(
    //   gameOver.makeGameOverScreen({
    //     success: false,
    //     lootObjectives: { TOTAL: 99 },
    //     totalKeyCount: 2,
    //     totalUnlockCount: 2,
    //     acquiredKeyCount: 0,
    //     acquiredUnlockCount: 0,
    //     nextBonusToUnlock: "BONUS_REPAIR",
    //   })
    // );

    this._activateChildChip(this._makeControlSequence());
  }

  protected _onTerminate() {
    // todo: unload shaders end all game stuff, unset all playerInfo properties
    // TODO: make sure physics is unloaded as well

    this._chipContext.playerInfo.reset();
    this._chipContext.scene.clear();
    this._chipContext.waterScene.clear();
  }

  protected _onTick(): void {
    const stats = this._chipContext.stats as Stats | undefined;

    if (params.inBenchMode())
      console.log(
        "Actual Frametime : ",
        this.chipContext.playerInfo.deltatime * 1000.0
      );
    if (params.inBenchMode()) console.log("=================");
    if (params.inBenchMode()) console.time("Total for the game");
    if (stats) stats.begin();

    if (this.isPaused) return;

    this.chipContext.playerInfo.deltatime =
      this._lastTickInfo.timeSinceLastTick * 0.001;

    if (params.inBenchMode()) console.time("Physics");

    this.chipContext.playerInfo.unposedTime +=
      this.chipContext.playerInfo.deltatime;

    this._world.step(this.chipContext.playerInfo.deltatime);
    this._boat?.applyForces();

    if (params.inBenchMode()) console.timeEnd("Physics");
  }

  private _makeControlSequence() {
    // In the demo, or the first time playing, remove the shop
    const saveManager: save.SaveManager = this.chipContext.saveManager;
    const boatSelectionSequence: chip.Chip[] = [];
    let boatName: boat.BoatName;
    let netName: net.NetName;

    if (params.isDemo()) {
      saveManager.selectBoat("Fish");
      saveManager.selectNet("Small");
      boatName = saveManager.selectedBoat;
      netName = saveManager.selectedNet;
    } else if (!saveManager.hasPlayedBefore()) {
      saveManager.selectBoat("Dinghy");
      saveManager.selectNet("Small");
      boatName = saveManager.selectedBoat;
      netName = saveManager.selectedNet;
    } else {
      boatSelectionSequence.push(
        new shop.Shop(),
        new chip.Lambda(() => {
          boatName = saveManager.selectedBoat;
          netName = saveManager.selectedNet;
        })
      );
    }

    boatSelectionSequence.push(
      new chip.Lambda(() => {
        this.chipContext.playerInfo.hp = boat.getBoatHp(boatName);
        this.chipContext.playerInfo.netHp = net.getNetHp(netName);
        this.chipContext.playerInfo.currentNetCapacity =
          net.getStartingCapacity(netName);
      })
    );

    // Right now only one objective is supported in the UI
    let objectiveDescription: string;
    let objectiveIcon: tLoaders.TextureAssetName;
    if (this.options.lootObjectives.TOTAL) {
      objectiveDescription = `Pick up ${this.options.lootObjectives.TOTAL} pieces of trash`;
      objectiveIcon = "trash/icon-trash-all";
    } else {
      console.assert(Object.keys(this.options.lootObjectives).length === 1);
      const trashName = Object.keys(this.options.lootObjectives)[0];
      const trashLabel =
        collectable.TrashLabels[trashName as collectable.TrashName];

      objectiveDescription = `Pick up ${this.options.lootObjectives[trashName]} pieces of ${trashLabel}`;
      objectiveIcon = collectable.getTextureNameForCollectable(
        trashName as collectable.CollectableName
      );
    }

    const startPopupOptions: levelSystem.LevelObjectiveStartPopupOptions = {
      levelNumber: this._options.levelNumber,
      description: objectiveDescription,
      icon: objectiveIcon,
      ...(this._options.duration && { duration: this._options.duration }),
    };

    return new chip.Sequence([
      new levelSystem.LevelObjectiveStartPopup(startPopupOptions),
      ...boatSelectionSequence,
      new chip.Lambda(() => {
        aLoaders.playFx("new_game");

        // Activate the tutorial, if present
        if (this._options.tutorial) {
          this._activateChildChip(this._options.tutorial);
        }

        this._setupBoat(boatName, netName);
        this._activateChildChip(this._controls);

        this._levelState = "playing";
      }),
      new chip.Wait(1000),
      new timer.StartCount(3000),
      new chip.Lambda(() => {
        this.emit("startCountdownOver");

        this._boat!.isMovementPrevented = false;

        if ("duration" in this._options) {
          const gameTimer = this._hud.makeGameTimer(
            this._options.duration as number
          );
          this._subscribeOnce(gameTimer, "timeReached", () => {
            this._onGameOver("failure");
          });
        }

        this._hud.makeObjectiveLootList(this._options.lootObjectives);
        this._hud.objectiveLootList!.open();

        this.emit("controlsActive");

        // Says "GO!"
        this._activateChildChip(new timer.GoHeadline());
      }),
      new chip.WaitForEvent(this, "gameOver"),
      new chip.Lambda(() => {
        this._levelState = "afterPlay";
        this._controls.terminate();
        saveManager.markHasPlayedBefore();
      }),
      new chip.Wait(1000),
      () => this._makeGameOverScreen(),
      new chip.Lambda(() => this.terminate(chip.makeSignal(this._levelResult))),
    ]);
  }

  /**
   * Fonction qui gère les collisions entre le bateau et un obstacle
   */
  private handleCollisionBoatObstacle(
    contact: PLANCK.Contact, // L'objet de contact de la collision
    data: obstacle.Obstacle // L'objet obstacle impliqué dans la collision
  ) {
    let contactVector; // Vecteur de contact entre le bateau et l'obstacle

    const plankObject = this._boat?.getPlanckObject();
    // Vérifie si le bateau existe et récupère son objet Planck associé
    if (plankObject && contact.getFixtureA().getBody() === plankObject.body) {
      // Calcule le vecteur de contact basé sur le corps FixtureA de la collision
      contactVector = PLANCK.Vec2(
        contact.getFixtureA().getBody().getPosition().x -
          (contact.getWorldManifold(null)?.points[0].x ?? 0),
        contact.getFixtureA().getBody().getPosition().y -
          (contact.getWorldManifold(null)?.points[0].y ?? 0)
      );
    } else {
      // Calcule le vecteur de contact basé sur le corps FixtureB de la collision
      contactVector = PLANCK.Vec2(
        contact.getFixtureB().getBody().getPosition().x -
          (contact.getWorldManifold(null)?.points[0].x ?? 0),
        contact.getFixtureB().getBody().getPosition().y -
          (contact.getWorldManifold(null)?.points[0].y ?? 0)
      );
    }

    // Vérifie si l'obstacle inflige des dégâts au joueur
    const doesDamage: boolean =
      obstaclePrefabs.obstaclePrefabs[data.id].harmsPlayer;

    // Vérifie si l'obstacle est dynamique
    if (obstaclePrefabs.obstaclePrefabs[data.id].dynamic) {
      // Appelle la méthode onCollideObstacle du bateau avec le vecteur de contact,
      // indique que des dégâts doivent être infligés et que l'obstacle est dynamique
      this._boat?.onCollideObstacle(contactVector, doesDamage, true);
      data.stun(); // Applique un effet d'étourdissement à l'obstacle
    } else {
      // Appelle la méthode onCollideObstacle du bateau avec le vecteur de contact,
      // indique si des dégâts doivent être infligés, que l'obstacle n'est pas dynamique
      // et spécifie la valeur de rebond maximale pour l'obstacle
      this._boat?.onCollideObstacle(contactVector, doesDamage, false);
    }
  }

  private handleCollisionBoatCollectable(
    contact: PLANCK.Contact,
    data: collectable.Collectable
  ) {
    if (data.collected || data.dropTime > -1) return;

    if (data.isTrash()) {
      if (
        net.canSelectedNetCollect(data.getType() as collectable.TrashName) &&
        !this._boat?.net.isFull() &&
        this._boat?.net.canTakeDamage
      ) {
        data.collected = true;
      } else {
        // The trash will rebound off the boat
        aLoaders.playFxOnlyIfAlone("hit_without_damage");
        // Play full net FX animation
        this._boat?.net.playFullNetFX();
      }
    } else {
      // Is bonus or coin
      data.collected = true;
    }

    if (data.collected) {
      const itemclone = new collectable.Collectable(
        data.getType(),
        this.chipContext.scene,
        this.chipContext.world
      );

      this._boat!.onCollideCollectable(itemclone, data.getPosition());

      data.terminate();
    }
  }

  private handleCollisionNetCollectable(
    contact: PLANCK.Contact,
    data: collectable.Collectable
  ) {
    if (data.collected || data.dropTime > -1) return;

    if (data.isTrash()) {
      if (
        net.canSelectedNetCollect(data.getType() as collectable.TrashName) &&
        !this._boat?.net.isFull() &&
        this._boat?.net.canTakeDamage
      ) {
        data.collected = true;
        aLoaders.playFx("points");
      } else {
        // The trash will rebound off the boat
        aLoaders.playFxOnlyIfAlone("hit_without_damage");
        // Play full net FX animation
        this._boat?.net.playFullNetFX();
      }
    } else {
      // Is bonus
      data.collected = true;

      // Sound will be played in Boat.onCollideCollectable()
    }

    if (data.collected) {
      const itemclone = new collectable.Collectable(
        data.getType(),
        this.chipContext.scene,
        this.chipContext.world
      );

      this._boat!.onCollideCollectable(itemclone, data.getPosition());

      data.terminate();
    }
  }

  private handleCollisionNetObstacle(
    contact: PLANCK.Contact,
    data: obstacle.Obstacle
  ) {
    // Basic ignore conditions
    if (!this._boat) return;
    if (!this._boat.canTakeDamage) return;
    if (!this._boat.net.canTakeDamage) return;
    // Ignore if obstacle is Buoy
    if (data.id === "Buoy") return;
    // Ignore if boat is going too slow
    if (this._boat.net.getVelocity() < 15) return;

    // Remove 1 HP & play invincibility frames
    this.chipContext.playerInfo.netHp -= 1;
    this._boat.net.invincibilityFrames();

    // Drop items if net is dead
    if (this.chipContext.playerInfo.netHp <= 0) this._dropItems(contact);

    // Play the hit obstacle sound effect
    if (obstaclePrefabs.obstaclePrefabs[data.id].dynamic)
      aLoaders.playFxOnlyIfAlone("hit_animal");
    else aLoaders.playFxOnlyIfAlone("hit_island");
  }

  private _dropItems(contact?: PLANCK.Contact) {
    if (!this._boat) return;

    const droppedItemsInfo = this._boat.net.dropItems(
      this._boat.net.getItemCount()
    );

    let contactVector: PLANCK.Vec2;
    if (contact) {
      const plankObject = this._boat.net.gameObject.getPlanckObject();
      if (plankObject && contact.getFixtureA().getBody() === plankObject.body) {
        contactVector = PLANCK.Vec2(
          contact.getFixtureA().getBody().getPosition().x -
            (contact.getWorldManifold(null)?.points[0].x ?? 0),
          contact.getFixtureA().getBody().getPosition().y -
            (contact.getWorldManifold(null)?.points[0].y ?? 0)
        );
      } else {
        contactVector = PLANCK.Vec2(
          contact.getFixtureB().getBody().getPosition().x -
            (contact.getWorldManifold(null)?.points[0].x ?? 0),
          contact.getFixtureB().getBody().getPosition().y -
            (contact.getWorldManifold(null)?.points[0].y ?? 0)
        );
      }
    } else {
      contactVector = new PLANCK.Vec2();
    }

    // console.log("contact vector: ", contactVector);

    const animations = droppedItemsInfo.map((droppedItem) => {
      const newCollectable = new collectable.Collectable(
        droppedItem.name,
        droppedItem.worldPosition
      );

      const points = -collectable.collectablesInfo[droppedItem.name].score;

      return new chip.Sequence([
        // Wait until we leave the collision handling routine to put the collectible back
        new chip.Wait(1),

        // Put the trash back
        new chip.Lambda(() => {
          this._map!.aquireDroppedCollectable(newCollectable);

          newCollectable.dropTime = newCollectable.elapsedTime;
          newCollectable
            .getPlanckObject()
            .body!.applyForceToCenter(
              PLANCK.Vec2(
                contactVector.x * constants.droppedItemForceMultiplier,
                contactVector.y * constants.droppedItemForceMultiplier
              )
            );
        }),

        // Show a points animation
        this._hud.makePointsFeedback(
          points,
          utils.getScreenPosition(
            new THREE.Vector3(
              droppedItem.worldPosition.x,
              0,
              droppedItem.worldPosition.y
            ),
            this.chipContext.camera.camera
          )
        ),

        // Reduce score, update trash counter, restore net health
        new chip.Lambda(() => {
          this.chipContext.playerInfo.score += points;
          this.chipContext.playerInfo.decrementTrashCounter(droppedItem.name);
          this.chipContext.playerInfo.netHp = net.getNetHp(
            this._boat!.net.netName
          );
        }),
      ]);
    });

    this._activateChildChip(new chip.Parallel(animations));

    aLoaders.playFx("net_dropped");
  }

  private deposeAnimation(
    collectableName: collectable.CollectableName,
    id: number,
    net: net.Net,
    initialPosition: THREE.Vector2
  ) {
    this._activateChildChip(
      new itemAnimation.DeposedItemAnimation(
        collectableName,
        id,
        net,
        new THREE.Vector3(initialPosition.x, 0, initialPosition.y)
      )
    );
  }

  private _setupPhysicsEventHandlers() {
    /*
        Defining "begin-contact" event
    */
    this._subscribe(this._world, "begin-contact", (contact) => {
      if (this._levelState !== "playing") return;

      const dataA = contact.getFixtureA().getBody().getUserData();
      const dataB = contact.getFixtureB().getBody().getUserData();
      if (dataA instanceof boat.Boat && dataB instanceof obstacle.Obstacle)
        this.handleCollisionBoatObstacle(contact, dataB);
      if (dataB instanceof boat.Boat && dataA instanceof obstacle.Obstacle)
        this.handleCollisionBoatObstacle(contact, dataA);
      if (
        dataA instanceof boat.Boat &&
        dataB instanceof collectable.Collectable
      )
        this.handleCollisionBoatCollectable(contact, dataB);
      if (
        dataB instanceof boat.Boat &&
        dataA instanceof collectable.Collectable
      )
        this.handleCollisionBoatCollectable(contact, dataA);
      if (dataB instanceof net.Net && dataA instanceof obstacle.Obstacle)
        this.handleCollisionNetObstacle(contact, dataA);
      if (dataA instanceof net.Net && dataB instanceof obstacle.Obstacle)
        this.handleCollisionNetObstacle(contact, dataB);
      if (dataB instanceof net.Net && dataA instanceof collectable.Collectable)
        this.handleCollisionNetCollectable(contact, dataA);
      if (dataA instanceof net.Net && dataB instanceof collectable.Collectable)
        this.handleCollisionNetCollectable(contact, dataB);
    });
  }

  private _onGameOver(result: "success" | "failure") {
    this._levelResult = result;

    // Stop playing "long" sfx
    aLoaders.stopFx("speed");

    this.emit("gameOver", result);
  }

  private _makeGameOverScreen(): chip.Chip {
    const saveManager = this.chipContext.saveManager as save.SaveManager;

    const success = this._levelResult === "success";
    const acquiredUnlockCount =
      this._unlockManager.unlockCount - saveManager.unlockCount;
    const acquiredKeyCount =
      this._unlockManager.keyCount - saveManager.keyCount;

    const screenOptions: gameOver.GameOverScreenOptions = {
      success,
      lootObjectives: this._options.lootObjectives,
      totalUnlockCount: this._unlockManager.unlockCount,
      acquiredUnlockCount,
      totalKeyCount: this._unlockManager.keyCount,
      acquiredKeyCount,
    };

    // Unlock new bonus?
    if (this._discoveredBonus) {
      screenOptions.discoveredBonus = saveManager.getBonusToDiscover();
      saveManager.unlockBonus(
        saveManager.getBonusToDiscover() as collectable.BonusName
      );
      saveManager.clearBonusToDiscover();
    }

    if (this._unlockManager.unlockCount > 0) {
      screenOptions.nextBonusToUnlock = unlock.getNextBonusToUnlock(
        saveManager.unlockedBonuses
      ) as collectable.BonusName;
    }

    // Set a bonus for discovery?
    if (this._unlockManager.unlockCount === 3) {
      saveManager.unlockCount = 0;
      const bonusToDiscover = unlock.getNextBonusToUnlock(
        saveManager.unlockedBonuses
      ) as collectable.BonusName;
      saveManager.setBonusToDiscover(bonusToDiscover);
      screenOptions.bonusToDiscover = bonusToDiscover;
    } else {
      saveManager.unlockCount = this._unlockManager.unlockCount;
    }

    // Unlock drop zone?
    if (this._unlockManager.keyCount === 3) {
      saveManager.keyCount = 0;
      saveManager.unlockDropZone(
        unlock.getNextDropZoneToUnlock(
          saveManager.unlockedBonuses,
          saveManager.unlockedDropZones
        ) as collectable.BonusName
      );
    } else {
      saveManager.keyCount = this._unlockManager.keyCount;
    }

    // Record powerups in save
    {
      powerup.powerups.forEach((p) => {
        const count = this._powerupManager.getCount(p);
        saveManager.setCollectableCount(p, count);
      });
    }

    return gameOver.makeGameOverScreen(screenOptions);
  }

  get levelState() {
    return this._levelState;
  }

  get isPaused() {
    return this._isPaused;
  }

  // TODO: take away setters
  set isPaused(value: boolean) {
    this._isPaused = value;
  }

  private _onLootUpdated() {
    const playerInfo = this.chipContext.playerInfo as playerInfo.PlayerInfo;

    // These values start at true, and will be set to false if any objectives are not met
    let gatheredEnough = true;
    let recycledEnough = true;
    Object.entries(this._options.lootObjectives).forEach(
      ([lootName, targetAmount]) => {
        gatheredEnough =
          gatheredEnough &&
          playerInfo.loot[lootName as keyof LootValues] >= targetAmount;
        recycledEnough =
          recycledEnough &&
          playerInfo.recycledTrash[lootName as keyof LootValues] >=
            targetAmount;
      }
    );

    if (recycledEnough) {
      this._onGameOver("success");
    } else if (gatheredEnough) {
      this._hud.showBottomPopup({
        text: "You have enough trash, head back home to recycle it",
        icon: "objective-icons/objective-icon-lighthouse",
      });
    }
  }
}
