import shopDataJSON from "../../shop.json";

import * as PIXI from "pixi.js";

import * as tLoaders from "../../loaders/textureLoaders";

import * as context from "../../context";
import * as shopData from "../../shopData";
import * as button from "../components/button";

import * as shopItem from "./shopItem";

const adShopData = (shopDataJSON as shopData.ShopData).ad;

export class AdItem extends context.ContextualChip {
  protected _container!: PIXI.Container;
  protected _background!: PIXI.Container;
  protected _imageBackground!: PIXI.Container;
  protected _name!: PIXI.Text;

  protected _onActivate(): void {
    {
      this._container = new PIXI.Container();
      this.chipContext.container.addChild(this._container);
    }

    // Yellow background
    {
      this._background = new PIXI.Container();
      this._background.eventMode = "none";
      this._container.addChild(this._background);
    }

    {
      const background = tLoaders.UINineSlicePlaneAssets.getNineSlice(
        "List_Item_Background_Yellow",
        shopItem.backgroundSize
      );
      this._background.addChild(background);
    }

    // Image background
    {
      this._imageBackground = new PIXI.Container();
      this._imageBackground.position.set(shopItem.backgroundSize.y / 2);
      this._background.addChild(this._imageBackground);
    }

    // Item image
    {
      const itemImage = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture("icon-ad")
      );
      itemImage.anchor.set(0.5);

      // Fit the image within the background
      const choiceBackground = tLoaders.UITextureAssets.getTexture(
        "Choice_Background_Selected"
      );
      itemImage.scale.set(
        Math.min(
          choiceBackground.width / itemImage.width,
          choiceBackground.height / itemImage.height
        )
      );

      this._imageBackground.addChild(itemImage);
    }

    // Item name
    {
      this._name = new PIXI.Text(adShopData.name, {
        fontFamily: "Hvd Comic Serif Pro",
        fontSize: 30,
        fill: 0x000000,
      });

      this._name.position.x = shopItem.backgroundSize.y;
      this._name.position.y = 15;
      this._background.addChild(this._name);
    }

    {
      const btn = new button.Button({
        texture: "Button_Big_Red",
        text: `Earn ${adShopData.gain}`,
        width: 200,
        position: {
          x: shopItem.backgroundSize.x - 235,
          y: 70,
        },
        onClick: () => {
          // TODO watch ad and earn money
        },
      });
      this._activateChildChip(btn, {
        context: { container: this._container },
      });
    }

    // Description
    {
      const descriptionText = new PIXI.Text(adShopData.description, {
        fontFamily: "Hvd Comic Serif Pro",
        fontSize: 22,
        fill: 0x000000,
        wordWrap: true,
        wordWrapWidth: 650,
      });
      descriptionText.position.set(shopItem.backgroundSize.y, 70);
      this._background.addChild(descriptionText);
    }
  }

  protected _onTerminate(): void {
    this.chipContext.container.removeChild(this._container);
  }
}
