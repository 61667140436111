import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

import * as aLoaders from "../../loaders/audioLoaders";
import * as tLoaders from "../../loaders/textureLoaders";

import * as utils from "../../utils";

export class Button extends chip.Composite {
  readonly height = 50;

  public container!: PIXI.Container;

  public background!: PIXI.NineSlicePlane;
  public text!: PIXI.Text;

  constructor(
    private _options: {
      text: string;
      large?: boolean;
      texture: tLoaders.NineSlicePlaneName;
      position: PIXI.IPointData;
      width: number;
      onClick: (mode: "Mouse" | "Keyboard" | "Touch") => unknown;
      disabled?: boolean;
      shortcutKey?: string;
    }
  ) {
    super();
  }

  public get size() {
    return {
      width: this._options.width,
      height: this._options.large ? this.height * 2 : this.height,
    };
  }

  protected _onActivate() {
    this.container = new PIXI.Container();

    this.container.position.copyFrom(this._options.position);

    {
      this.background = tLoaders.UINineSlicePlaneAssets.getNineSlice(
        this._options.texture,
        {
          x: this.size.width,
          y: this.size.height,
        }
      );

      this.background.eventMode = this._options.disabled ? "none" : "dynamic";
      this.background.buttonMode = true;

      this._subscribe(this.background, "mousedown", () => {
        aLoaders.playFx("button");
        this._options.onClick("Mouse");
      });

      this._subscribe(this.background, "touchstart", () => {
        aLoaders.playFx("button");
        this._options.onClick("Touch");
      });

      if (this._options.shortcutKey) {
        this._subscribe(document, "keydown", (event: KeyboardEvent) => {
          if (event.key === this._options.shortcutKey)
            this._options.onClick("Keyboard");
        });
      }

      if (!this._options.disabled)
        utils.hoverEffect.bind(this)(this.background);

      this.container.addChild(this.background);
    }

    {
      this.text = new PIXI.Text(this._options.text, {
        fontFamily: "Hvd Comic Serif Pro",
        fontSize: this._options.large ? 48 : 24,
        fill: 0xffffff,
      });

      this.text.anchor.set(0.5, 0.65);
      this.text.position.set(this.size.width / 2, this.size.height / 2);

      this.background.addChild(this.text);
    }

    this.chipContext.container.addChild(this.container);
  }

  protected _onTerminate() {
    this.chipContext.container.removeChild(this.container);
  }
}
