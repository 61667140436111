// @ts-ignore
import boatFrag from "../public/shaders/boat.frag";
// @ts-ignore
import netVert from "../public/shaders/net.vert";
import shopDataJSON from "./shop.json";

import * as _ from "underscore";

import * as PIXI from "pixi.js";
import * as PLANCK from "planck-js";
import * as THREE from "three";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";

import * as chip from "booyah/dist/chip";
import * as tween from "booyah/dist/tween";
import * as booyahUtil from "booyah/dist/util";

import * as aLoaders from "./loaders/audioLoaders";
import * as mLoaders from "./loaders/modelLoaders";

import * as boat from "./boat";
import * as collectable from "./collectable";
import * as colliderVisualizer from "./colliderVisualizer";
import * as constants from "./constants";
import * as gameObject from "./gameObject";
import * as bubble from "./gui/components/bubble";
import * as gauge from "./gui/components/gauge";
import * as itemAnimation from "./itemAnimation";
import * as netFullFX from "./netFullFX";
import * as netUpgradeFX from "./netUpgradeFX";
import * as planckObject from "./planckObject";
import * as responsive from "./responsive";
import { NetStats, ShopData } from "./shopData";
import * as utils from "./utils";

export const invinciblityFrameDuration = 2000;

// export type WasteSizes = (typeof wasteSizes)[number];

// export const wasteSizes = [
//   "Small",
//   "SmallMedium",
//   "Medium",
//   "MediumLarge",
//   "Large",
//   "ExtraLarge",
// ] as const;

// export const wasteSizeAndWastes: Record<WasteSizes, collectable.TrashName> = {
//   Small: "BOTTLE_1",
//   SmallMedium: "BOTTLE_2",
//   Medium: "BOTTLE_3",
//   MediumLarge: "BOTTLE_4",
//   Large: "BARREL_1",
//   ExtraLarge: "BARREL_2",
// };

const shopData: ShopData = shopDataJSON as ShopData;
export const nets = shopData.nets;

export type NetName = (typeof nets)[number]["name"];

/**
 * Gives the ratio of the stat of the net (from 0 for low ... to 1 for high)
 * @param statName
 */
export function getNetStat(netName: NetName, statName: keyof NetStats): number {
  return nets.find((net) => net.name === netName)?.stats[statName] ?? 0;
}

export function getNetHp(netName: NetName) {
  return getNetStat(netName, "durability");
}

export function getStartingCapacity(netName: NetName) {
  return getNetStat(netName, "capacity");
}

export function canSelectedNetCollect(
  trashName: collectable.TrashName
): boolean {
  return true;

  // TODO: consider putting this back if we can find a better way to use the feature

  // const netMaxWasteSize = net.nets.find(
  //   (n) => n.name === save.read("inventory").selectedNet
  // )!.stats.wasteSize;

  // const collectedWasteSizeName = Object.entries(net.wasteSizeAndWastes).find(
  //   ([sizeName, _trashName]) => {
  //     return _trashName === trashName;
  //   }
  // )![0] as net.WasteSizes;

  // const collectedWasteSize = net.wasteSizes.indexOf(collectedWasteSizeName) + 1;

  // return collectedWasteSize <= netMaxWasteSize;
}

export function canSelect(boatName: boat.BoatName, netName: NetName): boolean {
  return true;

  // return boat.getBoatStat(boatName, "power") >= getNetStat(netName, "capacity");
}

/**
 * represents an item in the net,
 * has a collectable and a planck object.
 * the collectable is the visual representation of the item,
 * the planck object is used for physics and is in a different world than the main world.
 * @class NetCollected
 * @extends {chip.Composite}
 */
export class NetCollected extends chip.Composite {
  // visualizer used for debugging
  public _position: THREE.Vector2 = new THREE.Vector2();
  // private _forces: THREE.Vector2 = new THREE.Vector2();
  private _visualizer?: colliderVisualizer.ColliderVisualizer;

  private _model!: THREE.Group;

  public get model(): THREE.Group {
    return this._model;
  }

  private _planckObject!: planckObject.PlanckObject;

  constructor(
    private _netWorld: PLANCK.World,
    private _type: collectable.TrashName,
    private _netPos: PositionData
  ) {
    super();
  }

  protected _onActivate(): void {
    this._planckObject = new planckObject.PlanckObject(
      {
        bodyDef: {
          type: "dynamic",
          position: new PLANCK.Vec2(0, 0),
          angle: this._netPos.angle,
          allowSleep: false,

          linearDamping: 1,
          angularDamping: 2,
        },
        fixtures: [
          {
            shape: this.shape,
            density: 0.001,
            friction: 1,
            restitution: 0.5,
          },
        ],
      },
      this._netWorld
    );

    this._model = mLoaders.collectablesModelAssets
      .getModelInfo(this._type)
      .model!.scene.clone();
    this.chipContext.scene.add(this._model);

    if (this._visualizer) this._activateChildChip(this._visualizer);

    this._activateChildChip(this._planckObject);

    this._model.visible = false;
    // Show up after the item animation is done
    this._activateChildChip(
      new chip.Sequence([
        new chip.Wait(itemAnimation.collectedItemAnimationLength),
        new chip.Lambda(() => (this._model.visible = true)),
      ])
    );
  }

  protected _onTick(): void {
    // This only applies movement to the contents of the net (the collected trash)
    this._model.position.set(
      this._planckObject.getPosition().x + this._netPos.x,
      0,
      this._planckObject.getPosition().y + this._netPos.y
    );
    this._model.rotation.set(
      0,
      -this._planckObject.getAngle() + this._netPos.angle,
      0
    );
  }

  protected _onTerminate(): void {
    this.chipContext.scene.remove(this._model);
  }

  public getTrashName(): collectable.TrashName {
    return this._type;
  }

  public getPosition(): THREE.Vector2 {
    return new THREE.Vector2(
      this._planckObject.getPosition().x + +this._netPos.x,
      this._planckObject.getPosition().y + +this._netPos.y
    );
  }

  public getPositionRelativeToNet(): THREE.Vector2 {
    return this._planckObject.getPosition().clone();
  }

  /**
   * computes the area of the item if it is a box or a circle.
   * if it is a circle, it uses the formula for the area of a circle.
   * if it is a box, it calculates the area by getting the vertices on each side and calculating the distance between them.
   * otherwise it returns 0.
   * @returns {number} the area of the item
   */
  public getArea(): number {
    const shape = this.shape;
    if (shape instanceof PLANCK.Circle) {
      return Math.PI * shape.getRadius() ** 2;
    }
    if (shape instanceof PLANCK.Box) {
      // get the vertices of a corner of the box
      const v1 = new THREE.Vector2(shape.getVertex(0).x, shape.getVertex(0).y);
      const v2 = new THREE.Vector2(shape.getVertex(1).x, shape.getVertex(1).y);
      const v3 = new THREE.Vector2(shape.getVertex(2).x, shape.getVertex(2).y);

      const side1 = v1.distanceTo(v2);
      const side2 = v2.distanceTo(v3);

      return side1 * side2;
    }
    return 0;
  }

  get shape() {
    return collectable.collectablesInfo[this._type].shape;
  }
}

/**
 * a simple class to store position data
 * TODO: find a more appropriate name and maybe an actual solution instead of whatever this is
 */
export class PositionData {
  constructor(public x: number, public y: number, public angle: number) {}
}

// the size of the net at the start of the game or when it is empty
const startSize = 5;
const startDensity = 0.00000005;

/**
 * uniforms for the net shader
 */
export const netShaderUniforms = {
  uTime: { value: 0 },
  boatPos: { value: new THREE.Vector2(0, 0) },
  justTookDamage: { value: false },
  netSize: { value: startSize },
};

/**
 * the net class,
 * it is a game object that contains a number of netItems.
 */
export class Net extends responsive.ResponsiveChip {
  private _size: number = startSize;
  private _positionData = new PositionData(0, 0, 0);
  private _container!: PIXI.Container;
  private _gaugeBoatHp!: gauge.Gauge;
  private _gaugeNetHp!: gauge.Gauge;
  private _itemsCountBubble!: bubble.Bubble;

  public get itemsCountBubble() {
    return this._itemsCountBubble;
  }

  private _gameObject!: gameObject.GameObject;

  private _netCollected!: NetCollected[];

  private _fixtureDef!: PLANCK.FixtureDef;

  // the internal world for the net, all the netItems are in this world
  private _netWorld!: PLANCK.World;
  // the body of the net in the internal world, basically a hollow circle
  private _netBody!: PLANCK.Body;

  private _netUpgradeFX?: netUpgradeFX.NetUpgradeFX;
  private _netFullFX?: netFullFX.NetFullFX;

  private _helper = new THREE.Mesh(
    new THREE.BoxGeometry(1, 1, 1),
    new THREE.MeshBasicMaterial({ color: 0xff0000 })
  );

  constructor(private _netName: NetName) {
    super();
  }

  public getItemCount() {
    return this._netCollected.length;
  }

  public getSize() {
    return this._size;
  }

  /**
   * set the size of the net,
   * also updates the collider both in the main world and the net world
   * @param size the new size of the net
   */
  private setSize(size: number) {
    this._size = size;

    const shape: PLANCK.Circle = this.getBody()
      ?.getFixtureList()
      ?.getShape() as PLANCK.Circle;

    shape.m_radius = size;

    this._gameObject.colliderVisualizer?.setShapes([this._fixtureDef.shape]);

    if (this._gameObject.colliderVisualizer?.state === "active")
      this._gameObject.colliderVisualizer!.resetMeshes();

    this._netWorld.destroyBody(this._netBody);

    const verticesCount = 6;
    const radius = size;
    const vertices: PLANCK.Vec2[] = [];
    for (let i = 0; i < verticesCount; i++) {
      vertices.push(
        PLANCK.Vec2(
          radius * Math.cos((i / verticesCount) * 2 * Math.PI),
          radius * Math.sin((i / verticesCount) * 2 * Math.PI)
        )
      );
    }

    const netShapes: PLANCK.Shape[] = [];
    vertices.forEach((vertex, index) => {
      // create box shapes for each edge
      const nextIndex = (index + 1) % vertices.length;
      const nextVertex = vertices[nextIndex];
      // translate the vertices outwards radially
      const newVert1 = vertex.clone().mul(2);
      const newVert2 = nextVertex.clone().mul(2);

      const boxShape = new PLANCK.Polygon([
        vertex,
        nextVertex,
        newVert2,
        newVert1,
      ]);

      netShapes.push(boxShape);
    });

    const fixtureDefs: PLANCK.FixtureDef[] = netShapes.map((shape) => {
      return {
        shape,
        isSensor: false,
        friction: 10000.0,
        density: startDensity,
      };
    });

    const netBody = this._netWorld.createBody({
      type: "static",
      position: PLANCK.Vec2(0, 0),
      allowSleep: false,
      angularDamping: 2.5,
      linearDamping: 1.5,
    });

    fixtureDefs.forEach((fixtureDef) => {
      netBody.createFixture(fixtureDef);
    });

    // netShaderUniform.timeSinceLastDamaged.value = 0;

    this._netBody = netBody;

    this.emit("resize", size);

    // TODO New net capacity feedback
    // this._refreshNetHPGauge();
  }

  /**
   * reset the size of the net to the default size (startSize)
   */
  private resetSize() {
    this.setSize(startSize);
  }

  /**
   * update the size of the net based on the area of the children
   * (the net should be bigger if there are more children)
   */
  private updateSize() {
    let area = 0;
    this._netCollected.forEach((child) => {
      area += child.getArea();
    });

    const size = Math.sqrt(area / Math.PI);
    this.setSize(size * 1.5 < startSize ? startSize : size * 1.5);
  }

  private _refreshNetHPGauge() {
    const previousValue = this._gaugeNetHp.value;
    this._gaugeNetHp.value =
      this.chipContext.playerInfo.netHp / getNetHp(this._netName);
    // Blink if less than before
    if (this._gaugeNetHp.value < previousValue) this._gaugeNetHp.blink();
  }

  public refreshBoatHPGauge() {
    const boatName = this.chipContext.saveManager.selectedBoat;
    const previousValue = this._gaugeBoatHp.value;
    this._gaugeBoatHp.value =
      this.chipContext.playerInfo.hp / boat.getBoatHp(boatName);
    // Blink if less than before
    if (this._gaugeBoatHp.value < previousValue) this._gaugeBoatHp.blink();
  }

  protected _onActivate(): void {
    this._netCollected = [];

    const netCollider = new PLANCK.Circle(new PLANCK.Vec2(0, 0), startSize);
    this._fixtureDef = {
      shape: netCollider,
      isSensor: false,
      friction: 0.1,
      density: startDensity,
      filterCategoryBits: planckObject.GameObjectMask.Player,
      filterMaskBits:
        planckObject.GameObjectMask.Collectable_sensor |
        planckObject.GameObjectMask.Obstacle |
        planckObject.GameObjectMask.Player,
    };

    const data = nets.find(
      (net) => this.chipContext.saveManager.selectedNet === net.name
    )!;

    this._gameObject = new gameObject.GameObject(
      mLoaders.commonModelAssets.getModelInfo(`NET_${data.model}_PART_2`),
      this.chipContext.scene,
      {
        userData: this,
        bodyDef: {
          type: "dynamic",
          allowSleep: false,
          angularDamping: 2.5,
          linearDamping: 1.5,
        },
        fixtures: [this._fixtureDef],
      },
      this.chipContext.world
    );
    this._activateChildChip(this._gameObject);

    this._netWorld = PLANCK.World({
      gravity: PLANCK.Vec2(0, 0),
    });

    // create the net body
    // it is a hollow circle make by creating a bunch of box shapes
    // and using trigonometry to calculate their positions

    // get the vertices of the circle
    const verticesCount = 6;
    const radius = startSize;
    const vertices: PLANCK.Vec2[] = [];
    for (let i = 0; i < verticesCount; i++) {
      vertices.push(
        PLANCK.Vec2(
          radius * Math.cos((i / verticesCount) * 2 * Math.PI),
          radius * Math.sin((i / verticesCount) * 2 * Math.PI)
        )
      );
    }

    // create the box shapes
    const netShapes: PLANCK.Shape[] = [];
    vertices.forEach((vertex, index) => {
      // create a box shape for each edge
      const nextIndex = (index + 1) % vertices.length;
      const nextVertex = vertices[nextIndex];
      // translate the vertices outwards radially to help with collision detection
      const newVert1 = vertex.clone().mul(2);
      const newVert2 = nextVertex.clone().mul(2);

      const boxShape = new PLANCK.Polygon([
        vertex,
        nextVertex,
        newVert2,
        newVert1,
      ]);

      netShapes.push(boxShape);
    });

    // create the fixture defs for the box shapes
    const fixtureDefs = netShapes.map((shape) => {
      return {
        shape,
        isSensor: false,
        friction: 10000.0,
        density: startDensity,
      };
    });

    // create the net body
    const netBody = this._netWorld.createBody({
      type: "static",
      position: PLANCK.Vec2(0, 0),
      allowSleep: false,
      angularDamping: 2.5,
      linearDamping: 1.5,
    });

    // add the fixture defs to the net body
    fixtureDefs.forEach((fixtureDef) => {
      netBody.createFixture(fixtureDef);
    });

    this._netBody = netBody;

    // shader stuff
    const model = this._gameObject.getModel;
    if (model) {
      netShaderUniforms.uTime = utils.waterMaterialUniform.uTime;
      netShaderUniforms.boatPos = utils.waterMaterialUniform.boatPos;
      netShaderUniforms.netSize.value = startSize;

      model.traverse((element) => {
        if (element instanceof THREE.Mesh) {
          const material = new CustomShaderMaterial({
            silent: true,
            baseMaterial: element.material,
            fragmentShader: boatFrag,
            vertexShader:
              utils.earthCurvatureFunctions +
              utils.shaderWavesFunctions +
              netVert,
            uniforms: netShaderUniforms,
          });

          element.material = material;
        }
      });
    }

    this._canTakeDamage = true;

    this._container = new PIXI.Container();

    this.chipContext.container.addChild(this._container);

    this._gaugeBoatHp = new gauge.Gauge(
      200,
      "grey",
      "red",
      1,
      this.chipContext.playerInfo.hp
    );
    this._gaugeNetHp = new gauge.Gauge(
      200,
      "grey",
      "yellow",
      1,
      this.chipContext.playerInfo.netHp
    );
    this._itemsCountBubble = new bubble.Bubble();

    // Hide gauges, they will be shown after the starting countdown
    this._gaugeBoatHp.visible = false;
    this._gaugeNetHp.visible = false;

    this._activateChildChip(
      new chip.Parallel([
        this._gaugeBoatHp,
        this._gaugeNetHp,
        this._itemsCountBubble,
      ]),
      {
        context: {
          container: this._container,
        },
      }
    );

    this._gaugeBoatHp.bar.position.set(-100, -100);
    this._gaugeNetHp.bar.position.set(-100, -80);
    this._itemsCountBubble.container.position.set(-170, -80);

    this._refreshNetHPGauge();

    // Listen for net HP update
    this._subscribe(
      this.chipContext.playerInfo,
      "netHpUpdate",
      this._refreshNetHPGauge
    );

    // Listen for the end of the starting countdown
    this._subscribe(
      this.chipContext.level,
      "startCountdownOver",
      this._showGauges
    );

    this.resize();
  }

  protected _onTick(): void {
    super._onTick();
    this._gameObject.updateThreeObject(Math.PI);

    const pos =
      this._gameObject.getPlanckObject()?.getPosition() ?? new THREE.Vector2();

    this._positionData.x = pos.x;
    this._positionData.y = pos.y;
    this._positionData.angle = this._gameObject.getAngle();

    this._netWorld.step(this.chipContext.playerInfo.deltatime);

    netShaderUniforms.netSize.value = this._size;

    // set the gravity of the net world to the opposite of the boat's velocity
    // not sure the / 2 is necessary, needs testing
    this._netWorld.setGravity(
      PLANCK.Vec2(
        -(this.getBody()?.getLinearVelocity().x ?? 0) / 2,
        -(this.getBody()?.getLinearVelocity().y ?? 0) / 2
      )
    );

    // Find the middle point between boat and net
    const boatInstance = this.chipContext.level.boat as boat.Boat;
    const boatScreenPosition = boatInstance.getScreenPosition();
    const netScreenPosition = this._gameObject.getScreenPosition();
    const containerPosition = new THREE.Vector3(
      (boatScreenPosition.x + netScreenPosition.x) / 2,
      (boatScreenPosition.y + netScreenPosition.y) / 2,
      (boatScreenPosition.z + netScreenPosition.z) / 2
    );
    this._container.position.set(containerPosition.x, containerPosition.y);
  }

  protected _onTerminate() {
    super._onTerminate();

    this.chipContext.container.removeChild(this._container);
    this._container.destroy();
  }

  protected _onResize() {
    if (responsive.isMobile()) {
      this._container.scale.set(0.5);
    } else if (responsive.isTablet()) {
      this._container.scale.set(0.75);
    } else {
      this._container.scale.set(1);
    }
  }

  public getBody(): PLANCK.Body | undefined {
    return this._gameObject.getPlanckObject()?.body;
  }

  get gameObject(): gameObject.GameObject {
    return this._gameObject;
  }

  get position() {
    return this._gameObject.getPlanckObject()!.getPosition();
  }

  private _showGauges() {
    this._gaugeBoatHp.visible = true;
    this._gaugeNetHp.visible = true;
  }

  /**
   * pushes a new item into the net
   * @param item the item to push
   * @param initialPosition the initial position of the item (used for the animation)
   */
  public pushNetItem(
    item: collectable.Collectable,
    initialPosition: THREE.Vector2
  ) {
    // check if net can collect this waste size
    if (
      !canSelectedNetCollect(item.getType() as collectable.TrashName) ||
      this.isFull()
    ) {
      return;
    }

    // create a new net item
    const netItem = new NetCollected(
      this._netWorld,
      item.getType() as collectable.TrashName,
      this._positionData
    );

    // start the animation
    this._activateChildChip(
      new itemAnimation.CollectedItemAnimation(netItem, initialPosition)
    );

    // add the item to the net
    this._activateChildChip(netItem, {
      attribute: "_netCollected[]",
    });

    // update the size of the net
    this.updateSize();

    // Show full net fx if needed
    if (this.isFull()) {
      this.playFullNetFX(3);
      this.chipContext.playerInfo.emit("netFull");
    }

    // Show net items count and capacity
    this._itemsCountBubble.show(this.getItemCount());
  }

  public playFullNetFX(repeatCount = 1) {
    // Only do it if not already playing the animation
    if (this._netFullFX && this._netFullFX.state === "active") return;

    // Show full net animation
    if (!this._netFullFX)
      this._netFullFX = new netFullFX.NetFullFX(repeatCount);
    this._activateChildChip(this._netFullFX);

    // Stretch 3D model
    const stretchSequence = [];
    for (let i = 0; i < 3; i++) {
      stretchSequence.push(
        new tween.Tween({
          from: 1,
          to: 0,
          easing: utils.makeCustomEaseOutElastic(0.6, 5),
          duration: 850,
          onUpdate: (v: number) => {
            const netScale = booyahUtil.lerp(1, 1.3, v);
            const itemScale = booyahUtil.lerp(1, 1.7, v);
            this._gameObject
              .getThreeObject()
              .setScale(netScale, netScale, netScale);
            for (const item of this._netCollected) {
              item.model.scale.set(itemScale, itemScale, itemScale);
            }
          },
        })
      );
    }
    // Just making sure the scale is reset to 1 after tweening
    stretchSequence.push(
      new chip.Lambda(() => {
        this._gameObject.getThreeObject().setScale(1, 1, 1);
      })
    );
    this._activateChildChip(new chip.Sequence(stretchSequence));
  }

  public playNetUpgradeFX(extraCapacity: number) {
    // Only do it if not already playing the animation
    if (this._netUpgradeFX && this._netUpgradeFX.state === "active") return;

    // Show net upgrade animation
    if (!this._netUpgradeFX)
      this._netUpgradeFX = new netUpgradeFX.NetUpgradeFX();
    this._activateChildChip(this._netUpgradeFX, {
      context: {
        extraCapacity: extraCapacity,
      },
    });
  }

  public upgradeCapacityAndEmptyNet() {
    // If the net is full, upgrade
    if (this.isFull()) {
      // Give bonus points
      this.chipContext.playerInfo.score += constants.scoreOnFullNet;
      this._activateChildChip(
        this.chipContext.hud.makePointsFeedback(
          constants.scoreOnFullNet,
          utils.getScreenPosition(
            this._gameObject.getThreeObject().getPosition(),
            this.chipContext.camera.camera
          )
        )
      );

      // Calculate new net capacity
      const newNetCapacity = Math.ceil(
        this.chipContext.playerInfo.currentNetCapacity *
          (1 + constants.netCapacityGrowth)
      );

      // Show upgrade animation
      this.playNetUpgradeFX(
        newNetCapacity - this.chipContext.playerInfo.currentNetCapacity
      );

      // Wait before applying new net capacity and triggering popup
      this._activateChildChip(
        new chip.Sequence([
          // Wait for the animation to play
          new chip.Wait(2500),
          new chip.Lambda(() => {
            // Apply new net capacity. This will also trigger the bottom popup
            this.chipContext.playerInfo.currentNetCapacity = newNetCapacity;
          }),
        ])
      );
    }

    // Empty the net
    if (this._netCollected.length > 0) this.emptyNet();
  }

  /**
   * function to drop items from the net
   * @returns the dropped items
   */
  public dropItems(amount: number) {
    const droppedItems = _.take(_.shuffle(this._netCollected), amount);
    const droppedItemsInfo = droppedItems.map((item) => ({
      name: item.getTrashName(),
      worldPosition: item.getPosition(),
      relativePosition: item.getPositionRelativeToNet(),
    }));

    droppedItems.forEach((item) => item.terminate());

    const delay = new utils.DelayChip(1, () => {
      this.updateSize();
    });

    this._activateChildChip(delay);

    return droppedItemsInfo;
  }

  /**
   * function to empty the net, called when the net is full
   * emits a deposedItem event for each item in the net
   */
  public emptyNet() {
    // Because terminate() will removing elements from the array as we go over it, we go from back to front
    for (let i = this._netCollected.length - 1; i >= 0; i--) {
      const item = this._netCollected[i];

      this.emit("deposedItem", item.getTrashName(), i, item.getPosition());
      item.terminate();
    }

    aLoaders.playFx("full_net");

    this.resetSize();
    this.getBody()?.getFixtureList()?.setDensity(startDensity);
    this.getBody()?.resetMassData();
  }

  public getVelocity() {
    return this.getBody()?.getLinearVelocity().length() ?? 0;
  }

  private _canTakeDamage!: boolean;

  /**
   * Sets the boat's invincibility frames for a certain duration, during which the boat cannot take damage.
   * @param {boolean} colorShift - Whether to shift the boat's material color to indicate damage.
   *
   * @returns {Promise<void>}
   */
  public invincibilityFrames() {
    if (!this._canTakeDamage) return;

    netShaderUniforms.justTookDamage.value = true;
    this._canTakeDamage = false;

    this._activateChildChip(
      new chip.Sequence([
        new chip.Wait(invinciblityFrameDuration),
        new chip.Lambda(() => {
          netShaderUniforms.justTookDamage.value = false;
          this._canTakeDamage = true;
        }),
      ])
    );
  }

  public isFull() {
    return (
      this._netCollected.length >=
      this.chipContext.playerInfo.currentNetCapacity
    );
  }

  get canTakeDamage() {
    return this._canTakeDamage;
  }

  get netName() {
    return this._netName;
  }
}
