import * as THREE from "three";

import * as chip from "booyah/dist/chip";

import * as mLoaders from "./loaders/modelLoaders";

import * as collectable from "./collectable";
import * as environment from "./environment";
import * as net from "./net";
import * as utils from "./utils";

export const collectedItemAnimationLength = 750;

export class DeposedItemAnimation extends chip.Composite {
  private _timeDeposed!: number;
  private _model!: THREE.Group;

  constructor(
    private _collectableName: collectable.CollectableName,
    private _id: number,
    private _net: net.Net,
    private _initialPosition: THREE.Vector3
  ) {
    super();
  }

  protected _onActivate(): void {
    this._timeDeposed = environment.oceanTime.valueOf();

    this._model = mLoaders.collectablesModelAssets
      .getModelInfo(this._collectableName)
      .model!.scene.clone();

    this._model.position.copy(this._initialPosition);

    this.chipContext.scene.add(this._model);
  }

  protected _onTick(): void {
    const animationLength = 1; // 0.5;
    let animationTime =
      environment.oceanTime.valueOf() - this._timeDeposed - this._id * 0.05;
    if (animationTime < 0) animationTime = 0;
    const animationAdvancement = animationTime / animationLength;

    const screenDestination = new THREE.Vector3(0, 70, 0.5);

    const lookat = new THREE.Vector3(0, 0, -1).applyQuaternion(
      this.chipContext.camera.camera.quaternion
    );
    const destination = screenDestination
      .unproject(this.chipContext.camera.camera)
      .add(lookat.multiplyScalar(15));

    destination.y -= 5;

    const position = this._initialPosition
      .clone()
      .lerp(destination, animationAdvancement);

    this._model.position.copy(position);

    if (animationTime > animationLength) {
      this.terminate();
    }
  }

  protected _onTerminate(): void {
    this.chipContext.scene.remove(this._model);
  }
}

export class CollectedItemAnimation extends chip.Composite {
  private _timeCollected!: number;
  private _modelClone!: THREE.Group;

  constructor(
    private _netItem: net.NetCollected,
    private _initialPosition: THREE.Vector2
  ) {
    super();
  }

  protected _onActivate(): void {
    // console.log(this._netItem.getTrashName());

    this._timeCollected = environment.oceanTime.valueOf();
    this._modelClone =
      mLoaders.collectablesModelAssets
        .getModelInfo(this._netItem.getTrashName())
        .model?.scene.clone() ?? new THREE.Group();
    this.chipContext.scene.add(this._modelClone);

    {
      // const points = this.chipContext.playerInfo.getPointsForTrash(
      //   this._netItem.getTrashName()
      // );
      const points =
        collectable.collectablesInfo[this._netItem.getTrashName()].score;

      this.chipContext.playerInfo.score += points;

      this.chipContext.playerInfo.incrementTrashCounter(
        this._netItem.getTrashName()
      );

      const screenPos = utils.getScreenPosition(
        new THREE.Vector3(this._initialPosition.x, 0, this._initialPosition.y),
        this.chipContext.camera.camera
      );
      this._activateChildChip(
        this.chipContext.hud.makePointsFeedback(points, screenPos)
      );
    }
  }

  protected _onTick(): void {
    if (this._netItem.state == "inactive") {
      this.terminate();
      return;
    }

    const animationTime = environment.oceanTime.valueOf() - this._timeCollected;
    const animationAdvancement =
      animationTime / (collectedItemAnimationLength / 1000);

    const destination = this._netItem.getPosition().clone();

    const position = this._initialPosition
      .clone()
      .lerp(destination, animationAdvancement);

    this._modelClone.position.x = position.x;
    this._modelClone.position.z = position.y;

    this._modelClone.position.y =
      Math.cos(Math.PI * (animationAdvancement - 0.5)) * 15.0;

    if (animationTime > collectedItemAnimationLength / 1000) {
      this.terminate();
    }
  }

  protected _onTerminate(): void {
    this.chipContext.scene.remove(this._modelClone);
  }
}
