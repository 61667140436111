import * as PIXI from "pixi.js";
import * as THREEMath from "three/src/math/MathUtils";

import * as chip from "booyah/dist/chip";
import * as booyahUtil from "booyah/dist/util";

import * as tLoaders from "../../loaders/textureLoaders";

import * as utils from "../../utils";

export interface ScrollBoxOptions {
  width: number;
  height: number;
  position?: PIXI.IPointData;

  /**
   * From 0 (top) to 1 (bottom)
   */
  initialValue?: number;
  barRightMargin?: number;
}

export class ScrollBox extends chip.Composite {
  private _container!: PIXI.Container;
  private _fixContent!: PIXI.Container;
  private _content!: PIXI.Container;
  private _mask!: PIXI.Sprite;
  private _bar!: PIXI.NineSlicePlane;
  private _slider!: PIXI.Sprite;

  /**
   * From 0 (top) to 1 (bottom)
   */
  private _value!: number;

  private _draggingBar = false;
  private _draggingOffset = 0;
  private _draggingContent = false;
  private _dragOriginY = 0;
  private _hasMoved = false;

  // public debugName: string = "ScrollBox";

  private _isActive = false;

  get isActive() {
    return this._isActive;
  }

  set isActive(value: boolean) {
    // console.log(this.debugName, "set active:", value);

    this._isActive = value;
    if (this._isActive) this._subscribeEvents();
    else this._unsubscribeEvents();
  }

  constructor(private _options: ScrollBoxOptions) {
    super();
  }

  get content() {
    return this._content;
  }

  get width() {
    return this._options.width;
  }

  set width(value) {
    this._options.width = value;
    this._mask.width = value;
  }

  get height() {
    return this._options.height;
  }

  set height(value) {
    this._options.height = value;
    this._mask.height = value;
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this._update();
  }

  protected _onActivate() {
    this._container = new PIXI.Container();
    this._fixContent = new PIXI.Container();
    this._content = new PIXI.Container();

    this._value = this._options.initialValue ?? 0;

    this._draggingBar = false;
    this._draggingContent = false;
    this._draggingOffset = 0;
    this._dragOriginY = 0;

    if (this._options.position)
      this._container.position.copyFrom(this._options.position);

    this._mask = new PIXI.Sprite(PIXI.Texture.WHITE);
    this._mask.width = this._options.width;
    this._mask.height = this._options.height;

    this._content.mask = this._mask;

    this._fixContent.eventMode = "dynamic";
    this._fixContent.hitArea = new PIXI.Rectangle(
      0,
      0,
      this._options.width,
      this._options.height
    );

    this._container.addChild(this._mask, this._fixContent, this._content);

    this._bar = tLoaders.UINineSlicePlaneAssets.getNineSlice(
      "Loader_Scroll_White",
      {
        x: this._options.height,
        y: 20,
      }
    );

    this._bar.eventMode = "dynamic";
    this._bar.cursor = "pointer";
    this._bar.angle = 90;
    this._bar.position.x =
      this._options.width + (this._options.barRightMargin ?? 0);

    this._container.addChild(this._bar);

    this._slider = new PIXI.Sprite(
      tLoaders.UITextureAssets.getTexture("Scroll_Bar")
    );
    this._slider.position.y = 20;
    this._slider.angle = -90;

    this._bar.addChild(this._slider);

    this.chipContext.container.addChild(this._container);
  }

  private _subscribeEvents() {
    // Make sure we don't subscribe twice
    this._unsubscribeEvents();
    // On pointer down
    this._subscribe(
      this._fixContent,
      "pointerdown",
      this._onPointerDownContent
    );
    this._subscribe(this._bar, "pointerdown", this._onPointerDownBar);
    // On mouse wheel
    this._subscribe(window.document, "wheel", this._onMouseWheel);
  }

  private _unsubscribeEvents() {
    // On pointer down
    this._unsubscribe(this._fixContent, "pointerdown");
    this._unsubscribe(this._bar, "pointerdown");
    // On pointer up
    this._unsubscribe(window.document, "pointerup");
    this._unsubscribe(window.document, "pointerleave");
    this._unsubscribe(window.document, "pointerout");
    // On pointer move
    this._unsubscribe(window.document, "pointermove");
    // On mouse wheel
    this._unsubscribe(window.document, "wheel");
  }

  private _onPointerDownContent(e: PointerEvent) {
    // Stop if not active
    if (!this.isActive) return;

    this._draggingContent = true;
    this._hasMoved = false;

    // Subscribe on pointer up and on pointer move
    this._subscribe(window.document, "pointerup", this._onPointerUp);
    this._subscribe(window.document, "pointerleave", this._onPointerUp);
    this._subscribe(window.document, "pointerout", this._onPointerUp);
    this._subscribe(window.document, "pointermove", this._onPointerMove);
  }

  private _onPointerDownBar(e: PointerEvent) {
    // Stop if not active
    if (!this.isActive) return;

    this._draggingBar = true;
    this._dragOriginY = utils.getPointerPosition().y;

    // Check if pointer is on the slider itself
    const sliderHeight = this._slider.getBounds().height;
    const dof =
      utils.getPointerPosition().y -
      this._slider.getGlobalPosition().y -
      sliderHeight / 2;
    // If so, store the dragging offset to prevent slider from jumping under the pointer
    if (Math.abs(dof) < sliderHeight / 2) this._draggingOffset = dof;
    else this._draggingOffset = 0;

    this._update();

    // Subscribe on pointer up and on pointer move
    this._subscribe(window.document, "pointerup", this._onPointerUp);
    this._subscribe(window.document, "pointerleave", this._onPointerUp);
    this._subscribe(window.document, "pointerout", this._onPointerUp);
    this._subscribe(window.document, "pointermove", this._onPointerMove);
  }

  private _onPointerUp(e: PointerEvent) {
    // Stop if not active
    if (!this.isActive) return;

    // Stop dragging
    this._draggingBar = false;
    this._draggingContent = false;

    // Unsubscribe on pointer up and on pointer move
    this._unsubscribe(window.document, "pointerup");
    this._unsubscribe(window.document, "pointerleave");
    this._unsubscribe(window.document, "pointerout");
    this._unsubscribe(window.document, "pointermove");
  }

  private _onPointerMove(e: PointerEvent) {
    // Stop if not active or not dragging
    if (!this.isActive || (!this._draggingBar && !this._draggingContent))
      return;

    // Fix for touch devices: getPointerPosition() is only updated when a finger is touching the screen,
    // so we need to wait for a move detection to set _dragOriginY to the correct value.
    if (this._draggingContent && !this._hasMoved) {
      this._dragOriginY = utils.getPointerPosition().y;
      this._hasMoved = true;
    }

    this._update();
  }

  private _onMouseWheel(e: WheelEvent) {
    // Stop if not active or dragging or wheelink
    if (!this.isActive || this._draggingContent || this._draggingBar) return;

    const minValue = 0;
    const maxValue = this._content.height - this._options.height;
    /* const vv = THREEMath.inverseLerp(
      minValue,
      -maxValue,
      this._content.position.y - Math.sign(e.deltaY) * 50
    ); */
    this.value = THREEMath.inverseLerp(
      minValue,
      -maxValue,
      this._content.position.y - Math.sign(e.deltaY) * 50
    );
  }

  protected _onTerminate() {
    // Stop dragging
    this._draggingBar = false;
    this._draggingContent = false;
    // Remove container
    this.chipContext.container.removeChild(this._container);
  }

  private _update() {
    const minValue = 0;
    const maxValue = this._content.height - this._options.height;

    // If dragging the content
    if (this._draggingContent && this._hasMoved) {
      // Get distance from last update
      const diff = utils.getPointerPosition().y - this._dragOriginY;

      // Store current position for next update
      this._dragOriginY = utils.getPointerPosition().y;
      // Update value
      this._value = THREEMath.inverseLerp(
        minValue,
        -maxValue,
        this._content.position.y + diff * 2.5 // Speed up the scrolling by multiplying the diff by a chosen value
      );
    }
    // If dragging the bar
    else if (this._draggingBar) {
      // Get actual slider height from getBounds()
      const sliderHeight = this._slider.getBounds().height;
      // Clamp y position between "half of the slider height" and "bar height - half of the slider height"
      const posY = booyahUtil.clamp(
        utils.getPointerPosition().y -
          this._draggingOffset -
          this._bar.getGlobalPosition().y,
        sliderHeight / 2,
        this._bar.getBounds().height - sliderHeight / 2
      );
      // Inverse lerp y position (minus the slider height again) to get the ratio value
      this._value = THREEMath.inverseLerp(
        0,
        this._bar.getBounds().height - sliderHeight,
        posY - sliderHeight / 2
      );
    }

    // Make sure value stays between 0 and 1
    this._value = booyahUtil.clamp(this._value, 0, 1);

    // Position slider
    this._slider.position.x =
      this.value * (this._bar.width - this._slider.height);

    // Position content
    this._content.position.y = booyahUtil.lerp(
      -maxValue,
      minValue,
      1 - this.value
    );
  }
}
