import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

import * as aLoaders from "../../loaders/audioLoaders";
import * as tLoaders from "../../loaders/textureLoaders";

import * as responsive from "../../responsive";
import * as utils from "../../utils";
import * as inputs from "../components/inputs";
import * as popup from "../components/popup";

export class Menu extends responsive.ResponsiveChip {
  readonly width = 1000;
  readonly height = 550;
  readonly margin = 60;
  readonly rowHeight = 100;

  private _container!: PIXI.Container;
  private _darkScreen!: PIXI.Graphics;
  private _bg!: popup.PopupBackground;

  protected _onActivate() {
    this._container = new PIXI.Container();

    // Dark screen
    {
      this._darkScreen = new PIXI.Graphics()
        .beginFill(0x000000, 0.7)
        .drawRect(0, 0, 2, 2)
        .endFill();

      this._container.addChild(this._darkScreen);
    }

    // Popup background
    {
      this._bg = new popup.PopupBackground({
        width: this.width,
        height: this.height,
        horizontalMargin: 40,
        verticalMargin: 40,
      });

      this._activateChildChip(this._bg, {
        context: {
          container: this._container,
        },
      });
    }

    let rowY = this.margin;

    {
      const header = new PIXI.Text("Settings", {
        fontSize: this.rowHeight * 0.8,
        fill: 0x000000,
        fontFamily: "Hvd Comic Serif Pro",
      });

      rowY += this.rowHeight / 2;

      header.position.set(this.width / 2, rowY);
      header.anchor.set(0.5);

      this._bg.content.addChild(header);
    }

    rowY += this.rowHeight;

    this._activateChildChip(
      new inputs.InputRange({
        icon: "Hexagon_Music",
        icoff: "Hexagon_Music",
        initialValue: this.chipContext.saveManager.musicVolume,
        width: this.width * 0.8,
        position: { x: this.margin, y: rowY },
        onUpdate: (value) => {
          this.chipContext.saveManager.musicVolume = value;
          aLoaders.setMusicVolume(value);
        },
      }),
      {
        context: {
          container: this._bg.content,
        },
      }
    );

    rowY += this.rowHeight;

    this._activateChildChip(
      new inputs.InputRange({
        icon: "Hexagon_Sound",
        icoff: "Hexagon_Sound",
        initialValue: this.chipContext.saveManager.fxVolume,
        width: this.width * 0.8,
        position: { x: this.margin, y: rowY },
        onUpdate: (value) => {
          this.chipContext.saveManager.fxVolume = value;
          aLoaders.setFxVolume(value);
        },
      }),
      {
        context: {
          container: this._bg.content,
        },
      }
    );

    rowY += this.rowHeight;

    this._activateChildChip(
      new inputs.InputSwitch({
        icon: "Full_Screen",
        icoff: "Full_Screen",
        initialValue: !!document.fullscreenElement,
        width: this.width * 0.4,
        position: { x: this.margin + 0.25 * this.width, y: rowY },
        onUpdate: (value) => {
          if (value) {
            this.chipContext.canvas.requestFullscreen();
          } else if (document.fullscreenElement) document.exitFullscreen();
        },
      }),
      {
        context: {
          container: this._bg.content,
        },
      }
    );

    // this._activateChildChip(
    //   new inputs.InputSwitch({
    //     icon: "Vibrations",
    //     icoff: "Vibrations",
    //     initialValue: this.chipContext.saveManager.vibration,
    //     width: this.width * 0.4,
    //     position: { x: this.margin * 2 + this.width * 0.4, y: rowY },
    //     onUpdate: (value) => {
    //       this.chipContext.saveManager.vibration = value;
    //       // todo: set vibration on/off
    //     },
    //   }),
    //   {
    //     context: {
    //       container: this._bg.content,
    //     },
    //   }
    // );

    // Close button
    {
      const sprite = new PIXI.Sprite(
        tLoaders.UITextureAssets.getTexture("Button_Close")
      );

      sprite.anchor.set(0.5);
      sprite.position.set(this.width - 10, 10);
      sprite.eventMode = "dynamic";

      utils.hoverEffect.bind(this)(sprite);

      this._subscribeOnce(sprite, "pointertap", this._onClose);

      this._bg.content.addChild(sprite);
    }

    this.chipContext.overlayContainer.addChild(this._container);

    this.resize();

    // TODO: move this to an event?
    this.chipContext.level.isPaused = true;

    this._subscribe(document, "keydown", (event) => {
      if (event.key === "Escape") this._onClose();
    });
  }

  protected _onResize() {
    this._darkScreen.width = responsive.getScreenWidth();
    this._darkScreen.height = responsive.getScreenHeight();
  }

  protected _onTerminate() {
    this.chipContext.level.isPaused = false;

    this.chipContext.overlayContainer.removeChild(this._container);

    this._container.destroy();
  }

  private _onClose() {
    aLoaders.playFx("button");

    this.terminate(chip.makeSignal("menuClosed"));
  }
}
