import * as PIXI from "pixi.js";

import * as tLoaders from "../../loaders/textureLoaders";

import * as constants from "../../constants";
import * as net from "../../net";
import { NetStats } from "../../shopData";

import * as shopItem from "./shopItem";

export class NetItem extends shopItem.ShopItem {
  constructor(
    public readonly name: net.NetName,
    price: number,
    private _stats: NetStats,
    behavior: "change" | "equip"
  ) {
    super({
      price,
      name,
      image: `Net-${net.nets.find((net) => net.name === name)!.model}`,
      isNew: false,
      behavior,
    });
  }

  protected _isBought(): boolean {
    return this.chipContext.saveManager.isNetBought(this.name);
  }

  protected _isSelected(): boolean {
    return this.chipContext.saveManager.selectedNet === this.name;
  }

  public get selectable(): boolean {
    return this._canSelect();
  }

  protected _onBuy() {
    this.chipContext.saveManager.buyNet(this.name);
  }

  protected _onSelect() {
    this.chipContext.saveManager.selectNet(this.name);
  }

  /**
   * Check if the net capacity is lower than the current selected boat power
   * @protected
   */
  protected _canSelect(): boolean {
    return true;
    // return net.canSelect(this.chipContext.saveManager.selectedBoat, this.name);
  }

  protected _onUpgrade() {
    // TODO: implement this after the alpha release
  }

  protected _canUpgrade(): boolean {
    return false;
  }

  protected _onActivate(): void {
    super._onActivate();

    // Net stats (by icons)

    const statsContainer = new PIXI.Container();

    statsContainer.position.set(this._name.x, this._name.height + this._name.y);

    const health = new PIXI.Sprite(tLoaders.UITextureAssets.getTexture("Buoy"));

    health.anchor.set(0.5);
    health.position.set(health.width / 2, health.height / 2);

    statsContainer.addChild(health);

    const healthText = new PIXI.Text(net.getNetHp(this.name), {
      fontFamily: "Hvd Comic Serif Pro",
      fontSize: 28,
      fill: constants.principalColor,
    });

    healthText.anchor.set(0.5);

    health.addChild(healthText);

    const capacity = new PIXI.Sprite(
      // tLoaders.UITextureAssets.getTexture("Octogone")
      tLoaders.UITextureAssets.getTexture("trash/icon-trash-all")
    );

    capacity.anchor.set(0.5);
    capacity.position.set(health.width * 1.5, health.height / 2);
    capacity.scale.set(0.55);

    statsContainer.addChild(capacity);

    const capacityText = new PIXI.Text(
      `${net.getStartingCapacity(this.name)}+`,
      {
        fontFamily: "Hvd Comic Serif Pro",
        fontSize: 28 * (1 / capacity.scale.x), // Invert scale to restore text size
        fill: constants.almostBlack,
      }
    );

    capacityText.anchor.set(0, 0.5);
    capacityText.position.set(capacity.width + 3, 0);

    capacity.addChild(capacityText);

    // for (const wasteSize of net.wasteSizes.slice(0, this._stats.wasteSize)) {
    //   const waste = new PIXI.Sprite(
    //     tLoaders.UITextureAssets.getTexture(`WasteSize_${wasteSize}_Bought`)
    //   );

    //   waste.anchor.set(0.5);
    //   waste.position.set(
    //     health.width / 2 + health.width * net.wasteSizes.indexOf(wasteSize),
    //     health.height * 1.5
    //   );

    //   statsContainer.addChild(waste);
    // }

    this._background.addChild(statsContainer);
  }
}

export const netItems = net.nets.map(
  (options) => new NetItem(options.name, options.price, options.stats, "equip")
);
