import shopDataJSON from "./shop.json";

import * as _ from "underscore";

import * as chip from "booyah/dist/chip";

import type * as boat from "./boat";
import type * as collectable from "./collectable";
import * as constants from "./constants";
import type * as popup from "./gui/components/popup";
import type * as net from "./net";
import * as params from "./params";
import { ShopData } from "./shopData";

class Settings {
  fxVolume = 0.5;
  musicVolume = 0.5;
  // vibration = false;
}

interface BoatData {
  name: string;
}

interface NetData {
  name: string;
}

type CollectableInventory = Partial<
  Record<collectable.CollectableName, number>
>;

const shopData: ShopData = shopDataJSON as ShopData;

class Inventory {
  boats: BoatData[] = [
    {
      name: "Dinghy",
    },
  ];
  nets: NetData[] = [
    {
      name: shopData.nets[0].name,
    },
  ];
  unlockedBonuses: collectable.BonusName[] = [];
  unlockedDropZones: collectable.BonusName[] = [];
  bonusToDiscover?: collectable.BonusName;
  selectedBoat: boat.BoatName = "Dinghy";
  selectedNet: net.NetName = shopData.nets[0].name;
  money = 0;
  keyCount = 0;
  unlockCount = 0;
  collectables: CollectableInventory = {};
}

class SaveData {
  settings = new Settings();
  inventory = new Inventory();
  hasPlayedBefore = false;
  doNotDisplayAnymore: popup.HelpPopupName[] = [];
  currentLevel = 1;
}

export class SaveManager extends chip.Composite {
  private _cachedSaveData!: SaveData;

  protected _onActivate(): void {
    if (localStorage.getItem(constants.localStorageKey)) {
      try {
        this._cachedSaveData = JSON.parse(
          localStorage.getItem(constants.localStorageKey)!
        );
      } catch (e) {
        // The cached data will be created later
      }
    }

    if (typeof this._cachedSaveData === "undefined") {
      this._cachedSaveData = new SaveData();
    }
  }

  private _writeToStorage() {
    const json = JSON.stringify(this._cachedSaveData);
    localStorage.setItem(constants.localStorageKey, json);
  }

  get musicVolume() {
    return this._cachedSaveData.settings.musicVolume;
  }

  set musicVolume(value: number) {
    this._cachedSaveData.settings.musicVolume = value;
    this._writeToStorage();
  }

  get fxVolume() {
    return this._cachedSaveData.settings.fxVolume;
  }

  set fxVolume(value: number) {
    this._cachedSaveData.settings.fxVolume = value;
    this._writeToStorage();
  }

  // get vibration() {
  //   return this._cachedSaveData.settings.vibration;
  // }

  // set vibration(value: boolean) {
  //   this._cachedSaveData.settings.vibration = value;
  //   this._writeToStorage();
  // }

  earnMoney(amount: number) {
    const oldAmount = this._cachedSaveData.inventory.money;
    this._cachedSaveData.inventory.money += amount;
    this.emit("change:money", this._cachedSaveData.inventory.money, oldAmount);
    this._writeToStorage();
  }

  spendMoney(amount: number) {
    const oldAmount = this._cachedSaveData.inventory.money;
    this._cachedSaveData.inventory.money -= amount;
    this.emit("change:money", this._cachedSaveData.inventory.money, oldAmount);
    this._writeToStorage();
  }

  get money() {
    return this._cachedSaveData.inventory.money;
  }

  buyBoat(name: boat.BoatName) {
    if (this._cachedSaveData.inventory.boats.find((boat) => boat.name === name))
      throw new Error("Boat already bought");

    this._cachedSaveData.inventory.boats.push({
      name,
    });

    this._writeToStorage();
  }

  buyNet(name: net.NetName) {
    if (this._cachedSaveData.inventory.nets.find((net) => net.name === name))
      throw new Error("Net already bought");

    this._cachedSaveData.inventory.nets.push({
      name,
    });

    this._writeToStorage();
  }

  selectBoat(name: boat.BoatName) {
    if (this._cachedSaveData.inventory.selectedBoat === name) return;

    // Ignore bought check in demo mode
    if (
      params.isDemo() &&
      !this._cachedSaveData.inventory.boats.find((boat) => boat.name === name)
    )
      throw new Error("Boat not bought");

    this._cachedSaveData.inventory.selectedBoat = name;

    this._writeToStorage();

    this.emit("selectedBoat", name);
  }

  selectNet(name: net.NetName) {
    if (this._cachedSaveData.inventory.selectedNet === name) return;

    // Ignore bought check in demo mode
    if (
      !params.isDemo() &&
      !this._cachedSaveData.inventory.nets.find((net) => net.name === name)
    )
      throw new Error("Net not bought");

    this._cachedSaveData.inventory.selectedNet = name;

    this._writeToStorage();
    this.emit("selectedNet", name);
  }

  isBoatBought(name: boat.BoatName) {
    return this._cachedSaveData.inventory.boats.some(
      (boat) => boat.name === name
    );
  }

  isNetBought(name: net.NetName) {
    return this._cachedSaveData.inventory.nets.some((net) => net.name === name);
  }

  get selectedBoat() {
    return this._cachedSaveData.inventory.selectedBoat;
  }

  get selectedNet() {
    return this._cachedSaveData.inventory.selectedNet;
  }

  isDisplayedAgain(name: popup.HelpPopupName) {
    return !this._cachedSaveData.doNotDisplayAnymore.includes(name);
  }

  doNotDisplayAnymore(name: popup.HelpPopupName) {
    this._cachedSaveData.doNotDisplayAnymore.push(name);
    this._writeToStorage();
  }

  displayAgain(name: popup.HelpPopupName) {
    this._cachedSaveData.doNotDisplayAnymore =
      this._cachedSaveData.doNotDisplayAnymore.filter((d) => d !== name);
    this._writeToStorage();
  }

  get unlockedBonuses() {
    return this._cachedSaveData.inventory.unlockedBonuses;
  }

  unlockBonus(bonus: collectable.BonusName) {
    this._cachedSaveData.inventory.unlockedBonuses = _.union(
      this._cachedSaveData.inventory.unlockedBonuses,
      [bonus]
    );
    this._writeToStorage();
  }

  getBonusToDiscover() {
    return this._cachedSaveData.inventory.bonusToDiscover;
  }

  setBonusToDiscover(value: collectable.BonusName) {
    this._cachedSaveData.inventory.bonusToDiscover = value;
  }

  clearBonusToDiscover() {
    delete this._cachedSaveData.inventory.bonusToDiscover;
  }

  get unlockedDropZones() {
    return this._cachedSaveData.inventory.unlockedDropZones;
  }

  unlockDropZone(dropZone: collectable.BonusName) {
    this._cachedSaveData.inventory.unlockedDropZones = _.union(
      this._cachedSaveData.inventory.unlockedDropZones,
      [dropZone]
    );
    this._writeToStorage();
  }

  get currentLevel() {
    return this._cachedSaveData.currentLevel;
  }

  set currentLevel(value: number) {
    this._cachedSaveData.currentLevel = value;
    this._writeToStorage();
  }

  hasPlayedBefore() {
    return this._cachedSaveData.hasPlayedBefore;
  }

  markHasPlayedBefore() {
    this._cachedSaveData.hasPlayedBefore = true;
    this._writeToStorage();
  }

  get keyCount() {
    return this._cachedSaveData.inventory.keyCount;
  }

  set keyCount(value: number) {
    this._cachedSaveData.inventory.keyCount = value;
    this._writeToStorage();

    this.emit("changedKeyCount", value);
  }

  get unlockCount() {
    return this._cachedSaveData.inventory.unlockCount;
  }

  set unlockCount(value: number) {
    this._cachedSaveData.inventory.unlockCount = value;
    this._writeToStorage();

    this.emit("changedUnlockCount", value);
  }

  /** Used for bonuses and unlocks */
  getCollectableCount(name: collectable.CollectableName): number {
    return this._cachedSaveData.inventory.collectables[name] ?? 0;
  }

  /** Used for bonuses and unlocks */
  setCollectableCount(name: collectable.CollectableName, value: number): void {
    this._cachedSaveData.inventory.collectables[name] = value;
    this._writeToStorage();

    this.emit("changedCollectableCount", name, value);
  }
}
