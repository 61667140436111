{
  "boats": [
    {
      "name": "Dinghy",
      "price": 0,
      "hitBox": {
        "height": 2.5,
        "width": 2.9
      },
      "stats": {
        "speed": 0.35,
        "acceleration": 0.5,
        "durability": 0.333,
        "agility": 0.2
      }
    },
    {
      "name": "Fish",
      "price": 600,
      "hitBox": {
        "height": 4.0,
        "width": 3.75
      },
      "stats": {
        "speed": 0.5,
        "acceleration": 0.5,
        "durability": 0.25,
        "agility": 0.5
      }
    },
    {
      "name": "Speed",
      "price": 1800,
      "hitBox": {
        "height": 8.0,
        "width": 2.75
      },
      "stats": {
        "speed": 0.75,
        "acceleration": 1.1666,
        "durability": 0.333,
        "agility": 0.75
      },
      "boostedMaxSpeed": 120
    },
    {
      "name": "Tug",
      "price": 3200,
      "hitBox": {
        "height": 4.1,
        "width": 5
      },
      "stats": {
        "speed": 0.6,
        "acceleration": 0.6,
        "durability": 0.666,
        "agility": 0.75
      }
    },
    {
      "name": "NGO",
      "price": 7000,
      "hitBox": {
        "height": 4.5,
        "width": 4.5
      },
      "stats": {
        "speed": 0.75,
        "acceleration": 0.8333,
        "durability": 0.5,
        "agility": 0.75
      },
      "boostedMaxSpeed": 120
    }
  ],
  "nets": [
    {
      "name": "Small",
      "price": 0,
      "model": 1,
      "stats": {
        "durability": 1,
        "capacity": 5
      }
    },
    {
      "name": "Robust Small",
      "price": 150,
      "model": 1,
      "stats": {
        "durability": 2,
        "capacity": 5
      }
    },
    {
      "name": "Medium",
      "price": 300,
      "model": 2,
      "stats": {
        "durability": 1,
        "capacity": 10
      }
    },
    {
      "name": "Robust Medium",
      "price": 500,
      "model": 2,
      "stats": {
        "durability": 2,
        "capacity": 10
      }
    },
    {
      "name": "Reinforced Medium",
      "price": 700,
      "model": 2,
      "stats": {
        "durability": 3,
        "capacity": 10
      }
    },
    {
      "name": "Large",
      "price": 1000,
      "model": 3,
      "stats": {
        "durability": 1,
        "capacity": 15
      }
    },
    {
      "name": "Robust Large",
      "price": 1400,
      "model": 3,
      "stats": {
        "durability": 2,
        "capacity": 15
      }
    },
    {
      "name": "Reinforced Large",
      "price": 1800,
      "model": 3,
      "stats": {
        "durability": 3,
        "capacity": 15
      }
    },
    {
      "name": "Big",
      "price": 2400,
      "model": 4,
      "stats": {
        "durability": 1,
        "capacity": 20
      }
    },
    {
      "name": "Robust Big",
      "price": 3200,
      "model": 4,
      "stats": {
        "durability": 2,
        "capacity": 20
      }
    },
    {
      "name": "Reinforced Big",
      "price": 4000,
      "model": 4,
      "stats": {
        "durability": 3,
        "capacity": 20
      }
    },
    {
      "name": "Extra-large",
      "price": 5200,
      "model": 5,
      "stats": {
        "durability": 1,
        "capacity": 30
      }
    },
    {
      "name": "Robust Extra-large",
      "price": 6800,
      "model": 5,
      "stats": {
        "durability": 2,
        "capacity": 30
      }
    },
    {
      "name": "Reinforced Extra-large",
      "price": 8400,
      "model": 5,
      "stats": {
        "durability": 3,
        "capacity": 30
      }
    }
  ],
  "collectables": [
    {
      "name": "UNLOCK_KEY",
      "displayName": "Key",
      "price": 400,
      "description": "With 3 keys, unlock a new drop zone"
    },
    {
      "name": "UNLOCK_SPEED",
      "displayName": "Tiki",
      "price": 400,
      "description": "Get 3 to unlock the Tiki island"
    },
    {
      "name": "UNLOCK_SHIELD",
      "displayName": "Lifeguard",
      "price": 200,
      "description": "Get 3 to unlock the Lifeguard island"
    },
    {
      "name": "UNLOCK_TIME",
      "displayName": "Cabin",
      "price": 200,
      "description": "Get 3 to unlock the Cabin island"
    },
    {
      "name": "UNLOCK_REPAIR",
      "displayName": "Construction",
      "price": 200,
      "description": "Get 3 to unlock the Construction island"
    },
    {
      "name": "UNLOCK_CHEST",
      "displayName": "Treasure",
      "price": 200,
      "description": "Get 3 to unlock the Treasure island"
    },
    {
      "name": "BONUS_SPEED",
      "displayName": "Boost",
      "price": 200,
      "description": "Unleash the full power of your engine for 10 seconds"
    },
    {
      "name": "BONUS_SHIELD",
      "displayName": "Shield",
      "price": 200,
      "description": "Protect your boat for 10 seconds"
    },
    {
      "name": "BONUS_TIME",
      "displayName": "Extra Time",
      "price": 200,
      "description": "Gain an extra 15 seconds"
    },
    {
      "name": "BONUS_REPAIR",
      "displayName": "Repair",
      "price": 200,
      "description": "Repair your net back to full strength"
    },
    {
      "name": "BONUS_MAGNET",
      "displayName": "Magnet",
      "price": 200,
      "description": "Bring the trash to you for 10 seconds"
    },
    {
      "name": "BONUS_RECYCLE",
      "displayName": "Recycle",
      "price": 200,
      "description": "Instantly recycle your trash, without returning to a drop zone"
    }
  ],
  "ad": {
    "name": "Watch an ad",
    "description": "Earn some coin",
    "gain": 400
  }
}
